import React, {useMemo, useRef} from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import styles from './ModalDetailTree.module.css'
import Tree6 from "../../../../assets/tree/tree-06.png";
import Tree3 from "../../../../assets/tree/tree-03.png";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Thumbnail from "../Thumbnail";

export default ({isOpen, close, item}) => {

    const modal = useRef<HTMLIonModalElement>();

    const date_thai = useMemo(() => new Date(item?.createdAt || new Date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }), [item]);

    return (
        <div className={styles.container}>
            <IonModal
                id="modal-detail-tree"
                ref={modal}
                trigger="open-modal"
                isOpen={isOpen}
                initialBreakpoint={1}
                onDidDismiss={() => {
                    close()
                }}
            >
                <div style={{flex: 1}} onClick={close}/>
                <div className={styles.modalContent}>

                    <div className={styles.imageContent}>
                        <img className={styles.tree01} src={Tree6} alt={"tree-01"}/>
                        <img className={styles.tree02} src={Tree3} alt={"tree-01"}/>
                    </div>

                    <div className={styles.content}>
                        <div className={styles.date}>วันที่ {date_thai}</div>
                        <div className={styles.topic}>จำนวน <span className={styles.amount}>{item.amount}</span> ต้น
                        </div>
                        <div className={styles.detail}><span
                            className={styles.highlight}>{item.amount * 200}</span> KGO
                            Token
                        </div>
                    </div>

                    <div className={styles.modalContentScroll}>
                        {
                            item?.timeline?.length > 0 ?
                                <Thumbnail
                                    images={[
                                        ...item?.timeline.map((item) => `https://kgotoken-app-public-storage.s3.ap-southeast-1.amazonaws.com/tree/${item.src}`)
                                    ]}
                                />
                                :
                                <div className={styles.emptyThumbnail}>
                                    ยังไม่มีการอัปเดทจากเจ้าหน้าที่
                                </div>
                        }

                        <div className={styles.timelineTitle}>สถานะการปลูกต้นไม้</div>

                        <VerticalTimeline
                            className={styles.timelineContent}
                            lineColor={"#cecece"}
                        >
                            {
                                item?.timeline?.map((item)=>{
                                    return (
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            contentStyle={{background: 'yellowgreen', color: '#fff',paddingBottom:0}}
                                            contentArrowStyle={{borderRight: '7px solid  yellowgreen'}}
                                            date={item.created_at}
                                            iconStyle={{background: 'yellowgreen', color: '#fff'}}
                                        >
                                            <h4 className="vertical-timeline-element-title">รายละเอียด</h4>
                                            <p className={styles.timelineDetail}>{item.detail}</p>
                                        </VerticalTimelineElement>
                                    )
                                })
                            }
                        </VerticalTimeline>

                    </div>

                </div>
            </IonModal>
        </div>
    )
}
