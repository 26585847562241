import React, {useEffect} from 'react';
import styles from "./Thumbnail.module.css";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import MainPreviewImage from "../MainPreviewImage/MainPreviewImage";
import PreviewImage from "../PreviewImage/PreviewImage";
import Preview01 from "../../../../assets/tree/preview-01.jpeg";
import Preview02 from "../../../../assets/tree/preview-02.jpeg";
import Preview03 from "../../../../assets/tree/preview-03.png";
import Preview04 from "../../../../assets/tree/preview-04.jpeg";

export default ({images}) => {

    const main_ref = React.useRef<Splide>();
    const thumbs_ref = React.createRef<Splide>();

    useEffect(() => {
        if (main_ref.current && thumbs_ref.current && thumbs_ref.current.splide) {
            main_ref.current.sync(thumbs_ref.current.splide);
        }
    }, [main_ref, thumbs_ref])

    const renderImage = (type) => {
        return images.map((image, index) => {
            return (
                <SplideSlide>
                    <PreviewImage src={image} height={type ? 220 : 80} rounder/>
                </SplideSlide>
            )
        })
    }

    return (
        <div className={styles.imageContent}>

            <Splide
                aria-label="My Favorite Images"
                options={{
                    rewind: true,
                    pagination: false,
                    arrows: false,
                }}
                ref={main_ref}
            >
                {renderImage("main")}
            </Splide>

            <div className={styles.break}/>

            <Splide
                aria-label="My Favorite Images"
                ref={thumbs_ref}
                options={{
                    rewind: true,
                    perPage: 4,
                    // type: 'loop',
                    isNavigation: true,
                    gap: 10,
                    focus: 'center',
                    pagination: false,
                    cover: true,
                }}
            >
                {renderImage("")}
            </Splide>

        </div>
    )
}
