import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
import { updateIKGO, fetchIKGO } from "../services/ikgo";
import AlertModal from "../components/Modal/AlertModal";
const ExchangeiKGO: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [walletAddress, setWalletAddress] = useState();
  const [kgoBalance, setKgoBalance] = useState(0);
  const [iKGOBalance, setIKGOBalance] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [rewardFacebook, setRewardFacebook] = useState(false);
  const [rewardLine, setRewardLine] = useState(false);
  const [rewardTwitter, setRewardTwitter] = useState(false);
  const [rewardEmail, setRewardEmail] = useState(false);
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const exchangeOptions = [
    {
      id: 1,
      price: 100,
      amount: 1,
      spin: 1,
    },
    {
      id: 2,
      price: 500,
      amount: 5,
      spin: 5,
    },
    {
      id: 3,
      price: 1000,
      amount: 10,
      spin: 10,
    },
    {
      id: 4,
      price: 5000,
      amount: 50,
      spin: 50,
    },
  ];

  const handleCloseModal = () => {
    setIsShowConfirmModal(false);
  };
  const handleSelect = (item) => {
    setSelectedItem(item);
    setIsShowConfirmModal(true);
  };

  const handleConfirmExchange = async () => {
    // call api
    const payload = {
      amount: selectedItem?.price,
      serviceType: "buy-ikgo",
    };
    const res = await updateIKGO(payload);
    console.log("res", res);
    setIsShowConfirmModal(false);
    // @ts-ignore
    toast.success(`แลกเหรียญจำนวน​ ${selectedItem?.amount} iKGO สำเร็จ`, {
      position: "bottom-center",
    });
    // console.log("res", res);
  };

  const getIKGO = async () => {
    const data = await fetchIKGO();
    console.log("data", data);
    setIKGOBalance(data);
  };

  useEffect(() => {
    getIKGO();
    getProfile();
    if (appContext?.profile) {
      // @ts-ignore
      setProfile(appContext?.profile);
    }
  }, []);
  useEffect(() => {
    // @ts-ignore
    setIsLoading(true);
    if (
      appContext?.profile.status != null &&
      appContext?.profile.status !== "active"
    ) {
      window.location.href = "/ban";
    }
    if (appContext?.profile.phoneNumber != null) {
      // @ts-ignore
      setWalletAddress(appContext.profile.walletAddress);
      if (appContext.profile.balance.length > 0) {
        for (const val of appContext.profile.balance) {
          if (val.symbol == "KGO") {
            // @ts-ignore
            setKgoBalance(val.balance);
            // @ts-ignore
            setIsLoading(false);
          }
        }
      }
    }
    // @ts-ignore
    setIsLoading(false);
  }, [appContext?.profile]);
  async function getProfile() {
    // console.log('getBalance');

    if (appContext.apiService) {
      appContext.getProfileBalance();
    } else {
      console.log("false");
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/luckydraw"
            />
          </IonButtons>
          <IonTitle>แลกเปลี่ยนเหรียญ iKGO </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"bg-secondary"}>
        <AlertModal
          data={{
            // @ts-ignore
            title: `ยืนยันที่จะแลก ${selectedItem?.amount}`,
            description: "หากกดยืนยันแล้วจะไม่สามารถย้อนคืนเหรียญ KGO ได้",
          }}
          isOpen={isShowConfirmModal}
          setIsOpen={handleCloseModal}
          onClickHandle={handleConfirmExchange}
        />
        <div className="app-container">
          <div
            style={{
              padding: "16px 16px 0 16px ",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 14,
                color: "#FFFFFF",
                background: "linear-gradient(#FF6E07, #FFA319)",
                borderRadius: 24,
                padding: 8,
              }}
            >
              <p style={{ margin: 0 }}>เหรียญ KGO: {kgoBalance}</p>
            </div>

            <p style={{ margin: 0 }}>เหรียญ iKGO: {iKGOBalance}</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              padding: 16,
            }}
          >
            {/* cards */}
            {exchangeOptions.map((forex) => (
              <div
                style={{
                  display: "flex",
                  padding: 16,
                  width: "100%",
                  justifyContent: "space-between",
                  height: 72,
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: 8,
                }}
              >
                <div>
                  <p style={{ margin: 0 }}>{forex.amount} iKGO</p>
                  <p style={{ margin: 0, color: "gray", fontSize: 14 }}>
                    สามารถสุ่มลุ้นรางวัลได้ {forex.spin} ครั้ง
                  </p>
                </div>
                <button
                  disabled={kgoBalance < forex.price}
                  style={{
                    backgroundColor:
                      kgoBalance < forex.price ? "gray" : "orange",
                    borderRadius: 8,
                    color: "white",
                  }}
                  onClick={() => handleSelect(forex)}
                >
                  {forex.price} KGO
                </button>
              </div>
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ExchangeiKGO;
