import { createContext, useState } from "react";
import { ApiService } from "../services/api-service";
import { ApiTest } from "../services/api-test";
import { UserProfile } from "../models/Data";

export interface AppContextInterface {
  apiService: ApiService;
  apiTest: ApiTest;
  otpRefData: object | null;
  setOtpRefData: Function;
  phoneNumber: string;
  setPhoneNumber: Function;
  walletAddress: string;
  setWalletAddress: Function;
  profile: UserProfile;
  setProfile: Function;
  registerStore: UserProfile;
  setRegisterStore: Function;
  notify: Array<object>;
  getProfileBalance: Function;
}

// @ts-ignore
export const AppContext = createContext<AppContextInterface>();
