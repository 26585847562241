import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonIcon,
  IonLoading,
  useIonAlert,
  IonProgressBar,
  IonCard,
  IonCardContent,
  IonGrid,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { arrowBackOutline, syncCircleOutline } from "ionicons/icons";
import { useHistory, useLocation } from "react-router-dom";
import { userOtp } from "../models/Data";

export default function LoginByPass() {
  const { search } = useLocation();
  const history = useHistory();
  const appContext = useContext(AppContext);

  const [disableBtn, setDisableBtn] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [countdown, setCountdown] = useState("(30)");
  const [present] = useIonAlert();

  async function checkAccessToken(accessToken: string) {
    setShowLoading(true);
    localStorage.setItem("accessToken", accessToken);
    await appContext.apiService
      .checkAccessToken(accessToken)
      .then((response: any) => {
        console.log("response", response);
        if (response?.success) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("phoneNumber", appContext.phoneNumber);
          // appContext.setPhoneNumber(response?.data.user.phoneNumber);
          window.location.href = "/";
        } else {
          present({
            cssClass: "ionic-alert",
            header: "ล้มเหลว",
            message: response.message,
            buttons: ["Ok"],
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setShowLoading(false);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const accessToken = searchParams.get("accessToken");
    if (accessToken != null) {
      checkAccessToken(accessToken).then().catch();
    }
    startTimer();
  }, [search]);

  function startTimer() {
    let seconds = 30;
    const tempTimer = setInterval(() => {
      seconds = seconds - 1;
      setCountdown(`( ${seconds} )`);
      if (seconds == 0) {
        clearInterval(tempTimer);
        setCountdown("");
        setDisableBtn(false);
      }
    }, 1000);
  }

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/"
            />
          </IonButtons>
          <IonTitle>Fast Login</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container"></div>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
}
