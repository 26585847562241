const API = "https://kgoapi.kgotoken.com/";
const accessToken = localStorage.getItem("accessToken");
export const fetchPrize = async () => {
  try {
    const response = await fetch(`${API}prizes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return response.status;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

export const spinSlotAPI = async () => {
  try {
    const response = await fetch(`${API}spinWheel/spin`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return response.status;
    }
    const data = await response.json();
    return { data: data, response: response };
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
