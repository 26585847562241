import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonCardContent,
    IonCard,
    IonRow,
    IonCol,
    IonIcon,
    IonLoading,
    IonItem,
    IonInput,
    IonText,
    IonInfiniteScroll, IonVirtualScroll, IonFab, IonFabButton,
} from '@ionic/react';
import React, {useContext, useEffect, useRef, useState} from "react";
import {
    arrowBackOutline,
    arrowDown,
    arrowForwardCircle,
    arrowUp,
    arrowUpCircle,
    search,
    thumbsUpSharp
} from "ionicons/icons";
import {AppContext} from "../context/state";
import bizPromotion from "../assets/biz-promotion.svg";
import star from "../assets/star.svg";
import {RouteComponentProps} from "react-router";
import tempMainBiz from "../assets/tempBiz.png";
import catagoryAll from "../assets/catagoryAll.png";
import fire from "../assets/fire.svg";
import cafe from "../assets/cafe.png";
import lifestyle from "../assets/lifestyle.png";
import restaurant from "../assets/restaurant.png";
import travel from "../assets/travel.png";
import other from "../assets/other.png";
import service from "../assets/service.png";
import styles from "../theme/Main.module.css";
import rayongBanner from "../assets/rayong-banner.png";

const BizList: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext);

    const [searchInput, setSearch] = useState(null || '');
    const [dropDown, setDropDown] = useState('all');
    const [skip, setSkip] = useState(0)
    const [bizList, setBizList] = useState<Array<any>>([])
    const [filterType, setFilterType] = useState('All')
    const [bannerImage, setBannerImage] = useState('')
    const [bannerTitle, setBannerTitle] = useState('')
    const [bannersubTitle, setBannerSubTitle] = useState('')
    const [bannerLink, setBannerLink] = useState('')
    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop= () => {
        contentRef.current && contentRef.current.scrollToTop(500);
    };
    useEffect(() => {
        console.log('get Biz')
        getBizList().catch(console.error);
        getBannerRecommend().catch(console.error);
    }, [searchInput, dropDown])

    async function getBizList(_skip = 0) {
        if (appContext.apiService) {
            appContext.apiService.getBizList(searchInput, dropDown, _skip).then((response: any) => {
                if (response?.success) {
                    // console.log(response);
                    let data = response.listing;
                    if (_skip > 0) {
                        data = bizList.concat(data);
                    }
                    // console.log('data', data);
                    if (dropDown == 'last') {
                        setBizList(data.reverse());
                    } else {
                        setBizList(data);
                    }
                }
            })
        }
    }

    async function getBannerRecommend() {
        if (appContext.apiService) {
            appContext.apiService.getBannerRecommend().then((response: any) => {

                if (response?.success) {
                    // console.log('banner',response.data);
                    setBannerImage(response.data.image);
                    setBannerTitle(response.data.title);
                    setBannerSubTitle(response.data.subTitle);
                    setBannerLink(response.data.link);
                }
            })
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>โปรโมชั่น</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent ref={contentRef} className={'bg-secondary'}>
                <IonFab onClick={() => {
                    scrollToTop();
                }} vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color={'light'} >
                        <IonIcon color={'dark'} icon={arrowUpCircle} />
                    </IonFabButton>
                </IonFab>

                <div className="app-container">
                    <div style={{marginTop: 3, paddingLeft: 12, paddingRight: 12}}>
                        <IonRow>

                            <IonCol size={"8"}>
                                <IonItem className={"kgo-input-light form"} style={{borderRadius: 8}}>
                                    <IonInput inputmode="text" style={{fontSize: 16}}
                                              placeholder="ค้นหา"
                                              value={searchInput}

                                              onIonChange={e => {
                                                  setSearch(e.detail.value!)

                                              }}/>
                                    <IonIcon size={"small"} slot="end" icon={search}/>
                                </IonItem>
                            </IonCol>
                            <IonCol size={"4"}>
                                <select style={{
                                    height: 48,
                                    width: '100%',
                                    borderRadius: 8,
                                    border: '1px solid rgba(0, 0, 0, 0.36)',
                                    color: "#848484"
                                }}
                                        value={dropDown}
                                        onChange={(e) => setDropDown(e.target.value)}>
                                    <option value={'all'}>ทั้งหมด</option>
                                    <option value={'recommend'}>ร้านแนะนำ</option>
                                </select>
                            </IonCol>
                            <IonCol size={'12'}>
                                <IonText className={'ion-no-margin'} style={{fontSize: 20, fontWeight: 700}}>โปรโมชั่นร้านแนะนำ</IonText>
                            </IonCol>
                            <img
                                src={rayongBanner}
                            />
                            <IonCol size={'12'}>
                                <IonCard className={"kgo-card kgo-card-tran"} style={{height: 'auto', margin:0}}>
                                    <IonCardContent  style={{minHeight: "calc(84vh)", padding:'0 6px 0 6px'}}>
                                        {/*<div onScroll={(e) => {*/}
                                        {/*    if ((e.currentTarget.scrollTop + e.currentTarget.clientHeight) > e.currentTarget.scrollHeight) {*/}
                                        {/*        const tempSkip = skip + 10;*/}
                                        {/*        setSkip(tempSkip)*/}
                                        {/*        // getBizList(tempSkip).catch()*/}
                                        {/*    }*/}
                                        {/*}} style={{height: "calc(84vh)", overflowY: "auto"*/}
                                        {/*}}>*/}
                                            {bizList.filter(e => filterType != 'All' ? (e.profile?.businessType == filterType && e.profile.provinceName?.trim() === "ระยอง") : e.profile.provinceName?.trim() === "ระยอง" ).length == 0 ? (
                                                    <>
                                                        <h1 className='ion-text-center' style={{marginTop: 42, color: "#464646"}}>ยังไม่พบข้อมูลร้านค้า...</h1>
                                                        <h1 className='ion-text-center'>เรากำลังค้นหา</h1>
                                                    </>

                                                )
                                                : bizList.filter(e => filterType != 'All' ? (e.profile?.businessType == filterType && e.profile.provinceName?.trim() === "ระยอง") : e.profile.provinceName?.trim() === "ระยอง").map((r: any, i: number) => (
                                                    <div key={i} className={"kgo-row-tran"}
                                                         onClick={(e: any) => {
                                                             // console.log('r', r);
                                                             history.push("/biz-detail/" + r._id)
                                                         }}
                                                         style={{width: '100%',display:'flex',border: "1pt solid rgba(0, 0, 0, 0.1)",borderRadius: 12}}>
                                                        <div style={{display:'inline-block'}}>
                                                            <img src={r?.listingProfile && r.listingProfile.images[1] ? r.listingProfile.images[1] + '?v=' + new Date().getTime() : bizPromotion}
                                                                 style={{height:80,minWidth:90,marginLeft:5,marginTop:12,marginBottom:5,objectFit:'cover' ,borderRadius: 12}} />
                                                        </div>
                                                        <div style={{display:'inline-block',height:'100%',flexGrow:3,marginLeft:5,lineHeight:1}}>
                                                            <div>
                                                                <div style={{display:'inline-block',paddingTop:8}}>
                                                                    <IonText style={{fontWeight: 600, fontSize: 16,marginLeft:3}}>
                                                                        {r.profile.businessName}
                                                                    </IonText>
                                                                </div>
                                                                {(r.recommend > 0) ?
                                                                    (
                                                                        <div  style={{display:'inline-block',marginTop:'-2px',float:'right'}}>
                                                                            {(r.rank > 0 && r.rank <= 10) ?
                                                                                (
                                                                                    <div className={'recomment-text'}>
                                                                                        <img style={{marginRight: 3}} src={fire}/>
                                                                                        <span>ร้านใหม่มาแรง</span>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className={'recomment-text'}>
                                                                                        <IonIcon
                                                                                            style={{marginRight: 3, color: '#2570c3'}}
                                                                                            icon={thumbsUpSharp}/>
                                                                                        <span>ร้านค้าแนะนำ</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                            {/*{(r.listingProfile != null) ? (*/}
                                                            {/*    <>*/}
                                                            {/*        <div style={{fontSize: 12}}>*/}
                                                            {/*            {r.listingProfile.subTitle}*/}
                                                            {/*        </div>*/}
                                                            {/*    </>*/}
                                                            {/*) : null}*/}
                                                            {r.profile?.businessType != '' ? (
                                                                <div>
                                                        <span className={'kgo-badge'} style={{background: "#737373",color: "#ffff",fontWeight:300}}>
                                                            {r.profile.businessType == 'อื่นๆ' ? r.profile.businessOtherType ? r.profile.businessOtherType :'อื่นๆ' : r.profile.businessType}
                                                        </span>
                                                                </div>
                                                            ) : null}
                                                            <div style={{marginLeft:3,marginRight:3}}>
                                                                <IonText style={{fontSize: 10,color: '#3c3c3c',fontWeight:300,lineHeight: 0.8}}>
                                                                    {r.profile.address} ต.{r.profile.districtName} อ.{r.profile.amphurName} จ.{r.profile.provinceName} โทร.{r.phoneNumber}
                                                                </IonText>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        {/*</div>*/}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default BizList;
