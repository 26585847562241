import {
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal,
    IonPage, IonRefresher, IonRefresherContent,
    IonRow, IonSkeletonText,
    IonText, IonThumbnail,
    IonTitle,
    IonToolbar, isPlatform, RefresherEventDetail, useIonAlert
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {
    arrowBackOutline,
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import '@splidejs/react-splide/css';
import Gallery from "../components/Gallery";
import MenuTree from "../components/MenuTree";
import Tree3 from "../../../assets/tree/tree-03.png";
import HistorySearch from "../components/HistorySearch";
import TreeItem from "../components/TreeItem";
import ModalDetailTree from "../components/ModalDetailTree";
import {ApiService} from "../../../services/api-service";

const Mission: React.FC<RouteComponentProps> = ({history}) => {
    const [presentAlert] = useIonAlert();
    const [modal_detail, set_modal_detail] = useState(false)
    const [list_tree, set_list_tree] = useState([])
    const [temp_list_tree, set_temp_list_tree] = useState([])
    const [tree_selected, set_tree_selected] = useState([])
    const [loaded, set_loaded] = useState(false)


    const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
        await fetchData()
        event.detail.complete();
    }

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            set_loaded(false)
            const tempApiService = new ApiService();
            let result = await tempApiService.getATree()
            set_list_tree(result)
            set_temp_list_tree(result)
            set_loaded(true)
            return true
        } catch (e) {
            set_loaded(true)
            presentAlert({
                header: 'แจ้งเตือน',
                subHeader: 'เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง',
                buttons: [
                    {
                        text: 'ตกลง',
                        role: 'confirm',
                    }
                ]
            })
            return false
        }
    }


    return (
        <IonPage>

            {/*Header*/}
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>
                        รายการปลูกต้นไม้
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            {/*Content*/}
            <IonContent className={'bg-secondary'}>

                <div className="app-container">
                    <IonRefresher slot="fixed" className={"bg-secondary"} onIonRefresh={handleRefresh}>
                        <IonRefresherContent style={{background: "rgba(255,255,255,0)"}}></IonRefresherContent>
                    </IonRefresher>

                    <HistorySearch
                        onSearch={(value) => {

                            let start_date = new Date(value[0]).setHours(0, 0, 0, 0)
                            let end_date = new Date(value[1]).setHours(0, 0, 0, 0)
                            let result = []

                            if (start_date && end_date) {
                                result = temp_list_tree.filter((item) => {
                                    let createdAt = new Date(item?.createdAt).setHours(0, 0, 0, 0)
                                    return (start_date <= createdAt && end_date >= createdAt)
                                })
                                set_list_tree(result)
                            } else if (start_date) {
                                result = temp_list_tree.filter((item) => {
                                    let createdAt = new Date(item?.createdAt).setHours(0, 0, 0, 0)
                                    return createdAt === start_date
                                })
                                set_list_tree(result)
                            } else {
                                set_list_tree(temp_list_tree)
                            }
                        }}
                        onClear={() => {
                            set_list_tree(list_tree)
                        }}
                    />

                    {!loaded ?
                        [1, 1, 1, 1, 1].map((item, index) => {
                            return (
                                <IonList key={`${index}-${Math.random()}`} style={{margin: 20}}>
                                    <IonListHeader>
                                        <IonSkeletonText animated={true} style={{'width': '80px'}}></IonSkeletonText>
                                    </IonListHeader>
                                    <IonItem>
                                        <IonThumbnail slot="start">
                                            <IonSkeletonText animated={true}></IonSkeletonText>
                                        </IonThumbnail>
                                        <IonLabel>
                                            <h3>
                                                <IonSkeletonText animated={true}
                                                                 style={{'width': '80%'}}></IonSkeletonText>
                                            </h3>
                                            <p>
                                                <IonSkeletonText animated={true}
                                                                 style={{'width': '60%'}}></IonSkeletonText>
                                            </p>
                                            <p>
                                                <IonSkeletonText animated={true}
                                                                 style={{'width': '30%'}}></IonSkeletonText>
                                            </p>
                                        </IonLabel>
                                    </IonItem>
                                </IonList>
                            )
                        })
                        :
                        list_tree?.map((item, index) => {
                            return (
                                <TreeItem
                                    item={item}
                                    key={`${index}-${Math.random()}`}
                                    onClick={() => {
                                        set_tree_selected(item)
                                        set_modal_detail(true)
                                    }}
                                />
                            )
                        })
                    }

                    <ModalDetailTree
                        item={tree_selected}
                        isOpen={modal_detail}
                        close={() => set_modal_detail(false)}
                    />
                </div>

            </IonContent>

        </IonPage>
    );
};

export default Mission;
