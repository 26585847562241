import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
  IonIcon,
  IonCard,
  IonCardContent,
  IonButton,
  IonText,
  IonFabButton,
  IonFab,
} from "@ionic/react";
import {
  person,
  arrowDown,
  arrowUp,
  scanSharp,
  refresh,
  arrowUpCircle,
} from "ionicons/icons";
import { AppContext } from "../context/state";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RouteComponentProps } from "react-router";
import TransactionList from "../components/TransactionList";
import kgoLogoWhite from "../assets/kgo-white.svg";
import kgoIconScan from "../assets/kgo-icon-scan.svg";
import bizPromotion from "../assets/biz-promotion.svg";
import depaxkgo from "../assets/depaxkgo.png";
import missionImg from "../assets/kgo-mission.svg";
// import MenuTree from '../pages/Tree/components/Menu'
// import MenuEsan from '../pages/Esan/components/Menu'
import Banner from "../components/Banner/Banner";

const Main: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [walletAddress, setWalletAddress] = useState("");
  const [currentBanner, setCurrentBanner] = useState(0);
  const [kgoBalance, setKgoBalance] = useState("");
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop(500);
  };
  const getProfile = useCallback(() => {
    if (appContext.apiService) {
      appContext.getProfileBalance();
      console.log(appContext.profile.status);
    } else {
      console.log("getProfile false");
    }
  }, []);

  useEffect(() => {
    getProfile();
  }, [getProfile, appContext.notify]);

  // appContext.notify,
  useEffect(() => {
    if (
      appContext?.profile.status != null &&
      appContext?.profile.status !== "active"
    ) {
      window.location.href = "/ban";
    }
    if (appContext?.profile.phoneNumber != null) {
      setWalletAddress(appContext.profile.walletAddress);
      if (appContext.profile.balance.length > 0) {
        for (const val of appContext.profile.balance) {
          if (val.symbol == "KGO") {
            setKgoBalance(val.balance);
          }
        }
      }
    }
  }, [appContext?.profile]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start" style={{ fontSize: 20, marginLeft: 10 }}>
            <IonText>KGO Wallet </IonText>
          </IonButtons>
          <IonButtons
            slot="end"
            onClick={(e: any) => {
              history.push("/profile");
            }}
            style={{ marginRight: 10, fontSize: 20 }}
          >
            <IonIcon icon={person} />
          </IonButtons>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent ref={contentRef}>
        <IonFab
          onClick={() => {
            scrollToTop();
          }}
          vertical="bottom"
          horizontal="end"
          slot="fixed"
        >
          <IonFabButton color={"light"}>
            <IonIcon color={"dark"} icon={arrowUpCircle} />
          </IonFabButton>
        </IonFab>

        <div className="app-container">
          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard
                className={"kgo-card kgo-card-img"}
                style={{ borderRadius: "12px 12px 0 0" }}
              >
                <IonCardContent style={{ paddingRight: 0 }}>
                  <IonRow style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={kgoLogoWhite}
                      style={{ width: 16.8, marginRight: 5 }}
                    />
                    <IonText
                      style={{
                        fontSize: 16,
                        color: "#FFFFFF",
                      }}
                    >
                      {" "}
                      {appContext.profile.profile?.firstName
                        ? appContext.profile.profile.firstName +
                          " " +
                          (appContext.profile.profile.lastName &&
                            appContext.profile.profile.lastName)
                        : appContext.profile.phoneNumber}
                    </IonText>

                    <IonText style={{ fontSize: 12, marginLeft: "auto" }}>
                      <div
                        style={{
                          background: "rgba(255, 255, 255, 0.42)",
                          borderRadius: 24,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div style={{ color: "#414143" }}>
                          {appContext?.profile.walletAddress
                            ? appContext?.profile.walletAddress.substr(0, 4) +
                              "..." +
                              appContext?.profile.walletAddress.substr(-4)
                            : ""}
                        </div>
                      </div>
                    </IonText>
                    <IonButton
                      onClick={() => {
                        window.location.reload();
                      }}
                      fill="clear"
                    >
                      <IonIcon
                        style={{ fontSize: 24, color: "white" }}
                        slot="icon-only"
                        icon={refresh}
                      />
                    </IonButton>
                  </IonRow>

                  <IonRow
                    className="ion-text-center"
                    style={{ margin: "0 0 12px" }}
                  >
                    <IonCol size="12">
                      <IonText
                        color={"light"}
                        style={{
                          fontSize: 36,
                          fontWeight: 600,
                        }}
                      >
                        {kgoBalance
                          ? numberWithCommas(parseFloat(kgoBalance).toFixed(2))
                          : "0"}
                        <IonText
                          style={{
                            marginLeft: 6,
                            fontSize: 16,
                            fontWeight: 400,
                            verticalAlign: "middle",
                          }}
                          color={"light"}
                        >
                          {" "}
                          KGO
                        </IonText>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
              <IonRow>
                <IonCol size={"6"}>
                  <IonButton
                    className={"kgo-btn-light"}
                    routerLink={"/receive"}
                    style={{ fontSize: "1.0em", height: 34 }}
                    expand={"block"}
                    size={"small"}
                  >
                    รับเหรียญ <IonIcon slot="end" icon={arrowDown} />
                  </IonButton>
                </IonCol>
                <IonCol size={"6"}>
                  <IonButton className={"kgo-btn-scan"} routerLink={"/scan"}>
                    <IonIcon icon={scanSharp} />
                    <img src={kgoIconScan} style={{ width: 25 }} />
                  </IonButton>
                  <IonButton
                    className={"kgo-btn-light"}
                    style={{ fontSize: "1.0em", height: 34 }}
                    expand={"block"}
                    size={"small"}
                    routerLink={"/send"}
                  >
                    ส่งเหรียญ <IonIcon slot="end" icon={arrowUp} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol size={"12"} className={"ion-text-center"}>
              <IonCard>
                <IonCardContent>
                  <a
                    href={"https://forms.gle/k12qBnP1Kwa2yPa36"}
                    target={"_blank"}
                  >
                    <img src={depaxkgo} />
                  </a>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow> */}

          <div></div>

          {/*<IonRow className={"kgo-row"}>*/}
          {/*    <IonCol>*/}
          {/*        <IonCard className={"kgo-card kgo-light card-link"} onClick={(e) => {*/}
          {/*            // history.push('/mission')*/}
          {/*        }}>*/}
          {/*            <IonCardContent style={{paddingBottom: 0}}>*/}
          {/*                <IonRow>*/}
          {/*                    <IonCol size={"8"}>*/}
          {/*                        <IonText color={"dark"} style={{fontSize: 14, fontWeight: 'bold'}}>*/}
          {/*                            ทำ Mission <div>เพื่อรับ KGO Token เพิ่ม </div>*/}
          {/*                            <div>เร็วๆ นี้</div>*/}
          {/*                        </IonText>*/}
          {/*                    </IonCol>*/}
          {/*                    <IonCol size={"4"}>*/}
          {/*                        <img src={kgoLogoMission} style={{width: 154}}/>*/}
          {/*                    </IonCol>*/}
          {/*                </IonRow>*/}
          {/*            </IonCardContent>*/}
          {/*        </IonCard>*/}
          {/*    </IonCol>*/}
          {/*</IonRow>*/}
          <div style={{ padding: 16 }}>
            <Banner />
          </div>
          {/* <div style={{ padding: 16 }}>
            <div
              style={{
                backgroundColor: "white",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                padding: 8,
                borderRadius: 8,
                // color:"wh"
              }}
            >
              <p>เล่นเกมสุ่มลุ้นรางวัลสุดพิเศษ!</p>
              <img src={missionImg} style={{ width: 115, marginTop: 5 }} />
            </div>
          </div> */}
          <IonRow className={"kgo-row"} style={{ marginTop: 22 }}>
            <IonCol size={"6"}>
              <IonCard
                className={"kgo-card kgo-light card-link"}
                style={{ height: 120 }}
                onClick={(e) => {
                  history.push("/luckydraw");
                }}
              >
                <IonCardContent
                  className={"ion-no-padding"}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <IonRow>
                    <IonCol size={"12"}>
                      <div
                        className={"card-promotion"}
                        style={{ margin: 3, marginTop: 5 }}
                      >
                        <p style={{ fontWeight: 600 }}>เล่นเกมสุ่มด้วย iKGO</p>
                        <p style={{ fontWeight: 600 }}>
                          เพื่อลุ้นรับรางวัลสุดพิเศษ!
                        </p>
                      </div>
                    </IonCol>
                    <IonCol size={"12"} className={"ion-text-center"}>
                      <img
                        src={missionImg}
                        style={{ width: 115, marginTop: 5 }}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            {/* <IonCol size={"6"}>
              <IonCard
                className={"kgo-card kgo-light card-link"}
                style={{ height: 120 }}
                onClick={(e) => {
                  history.push("/mission");
                }}
              >
                <IonCardContent
                  className={"ion-no-padding"}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <IonRow>
                    <IonCol size={"12"}>
                      <div
                        className={"card-promotion"}
                        style={{ margin: 3, marginTop: 5 }}
                      >
                        <p style={{ fontWeight: 600 }}>ทำ Mission</p>
                        <p style={{ fontWeight: 600 }}>
                          เพื่อรับ KGO Token เพิ่ม
                        </p>
                      </div>
                    </IonCol>
                    <IonCol size={"12"} className={"ion-text-center"}>
                      <img
                        src={missionImg}
                        style={{ width: 115, marginTop: 5 }}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol> */}
            <IonCol size={"6"}>
              <IonCard
                className={"kgo-card kgo-light card-link"}
                style={{ height: 120 }}
                onClick={(e) => {
                  history.push("/biz-list");
                }}
              >
                <IonCardContent className={"ion-no-padding"}>
                  <IonRow>
                    <IonCol size={"12"}>
                      <div
                        className={"card-promotion ion-text-start"}
                        style={{ margin: 0 }}
                      >
                        <IonText>โปรโมชั่น</IonText>
                      </div>
                    </IonCol>
                    <IonCol size={"12"}>
                      <div
                        className={"card-promotion"}
                        style={{ marginLeft: 0, marginTop: 0, marginRight: 0 }}
                      >
                        <p style={{ fontWeight: 600 }}>ร้านเด็ด ร้านดัง</p>
                        {/*<p style={{fontWeight: 600}}>ร้านดัง</p>*/}
                        {/*<p style={{fontWeight: 600}}>คลิ้กเลย</p>*/}
                      </div>
                    </IonCol>
                    <IonCol size={"12"}>
                      <img src={bizPromotion} style={{ width: 85 }} />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            {/*<IonCol size={'12'}>*/}
            {/*    <MenuEsan onClick={()=> history.push('/buy-random-box')} />*/}
            {/*</IonCol>*/}
          </IonRow>
          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard
                className={"kgo-card kgo-card-tran"}
                style={{ height: "auto" }}
              >
                <IonCardContent style={{ padding: "0 6px 0 6px" }}>
                  {walletAddress && <TransactionList mode={"component"} />}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Main;
