import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline, cart } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
// @ts-ignore
import missionImg from "../assets/kgo-mission.svg";
// @ts-ignore
import gameBanner from "../assets/game/Pralia.jpg";

import Slot from "../components/SlotKgo/Slot";
import { fetchIKGO } from "../services/ikgo";
import { fetchPrize, spinSlotAPI } from "../services/slot";
import AlertModal from "../components/Modal/AlertModal";
import EventOnlyModal from "../components/Modal/LimitEventModal";
const LuckyDraw: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [iKGOBalance, setIKGOBalance] = useState(0);
  const [rewards, setRewards] = useState();
  const [isEventOnly, setIsEventOnly] = useState(true);
  const [singleBtn, setSingleBtn] = useState(false);
  const [isWinPrize, setIsWinPrize] = useState(false);
  const [modalData, setModalData] = useState({
    title: null,
    description: null,
    confirmBtn: null,
  });
  const onCloseWonPrizeModal = () => {
    setIsWinPrize(false);
  };
  const handleConfirmPrizeModal = () => {
    setIsWinPrize(false);
    history.push("/myprize");
  };

  const getAllPrize = async () => {
    const data = await fetchPrize();
    setRewards(data.prizes);
    // console.log("data", data);
  };
  const spinAPI = async (data: any) => {
    // const data = await spinSlotAPI();
    console.log("data", typeof data);
    if (typeof data !== "number") {
      setTimeout(() => {
        if (data.data.prize) {
          setTimeout(() => {
            setModalData({
              title: data?.data?.prize?.name,
              description: data?.data.prize.description,
              confirmBtn: "ตรวจสอบรางวัล",
            });
            setIsWinPrize(true);
          }, 1000);
        }
      }, 2000);
    } else {
      setModalData({
        title: "การสุ่มรางวัลไม่สำเร็จ",
        description: `กรุณาติดต่อแอดมิน (Error code:${data})`,
        confirmBtn: null,
      });
      setSingleBtn(true);
      setIsWinPrize(true);
    }
  };
  const spinSlot = async () => {
    if (iKGOBalance < 1) return;
    // const payload = {
    //   type: "spend",
    //   // @ts-ignore
    //   points: 100,
    // };
    const data = await spinSlotAPI();
    spinAPI(data);
    getIKGO();
    return data;

    // const res = await updateIKGO(payload);
  };
  const getStoreProfile = () => {
    // console.log("getStoreProfile");
    if (appContext.apiService) {
      appContext.getProfileBalance();
    } else {
      console.error("apiService not init!");
    }
  };
  const getIKGO = async () => {
    const data = await fetchIKGO();
    setIKGOBalance(data);
  };

  const handleIsEventAccept = () => {
    setIsEventOnly(false);
    localStorage.setItem("accept-event", true);
  };

  useEffect(() => {
    if (localStorage.getItem("accept-event")) {
      setIsEventOnly(false);
    }
    getIKGO();
    getAllPrize();
  }, []);
  useEffect(() => {
    getStoreProfile();
  }, [appContext.notify]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <AlertModal
        singleBtn={singleBtn}
        data={modalData}
        isOpen={isWinPrize}
        setIsOpen={onCloseWonPrizeModal}
        onClickHandle={handleConfirmPrizeModal}
      />
      <EventOnlyModal isOpen={isEventOnly} setIsOpen={handleIsEventAccept} />
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonTitle>Luck Spin ลุ้นของรางวัลพิเศษ</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            height: "100%",
          }}
          className="app-container"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              backgroundColor: "orange",
              // padding: 16,
            }}
          >
            {rewards && (
              <Slot spin={spinSlot} balance={iKGOBalance} rewards={rewards} />
            )}
          </div>
          <div
            style={{
              padding: "8px 16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              onClick={(e) => {
                history.push("/exchangeikgo");
              }}
              style={{
                backgroundColor: "transparent",
                color: "orange",
                fontWeight: "bold",
              }}
            >
              แลก iKGO เพิ่มตรงนี้
            </button>
            <div
              style={{
                fontSize: 14,
                color: "#FFFFFF",
                background: "linear-gradient(#FF6E07, #FFA319)",
                borderRadius: 24,
                padding: 8,
                cursor: "pointer",
              }}
              onClick={(e) => {
                history.push("/exchangeikgo");
              }}
            >
              {/* <img src={dollar} className={"coin-dollar"} /> */}
              iKGO คงเหลือ:{" "}
              <span style={{ color: "white" }}>{iKGOBalance}</span>
            </div>
          </div>
          <div style={{ padding: 16, cursor: "pointer" }}>
            <IonCard
              className={"kgo-card kgo-light card-link"}
              style={{ height: 128, padding: 0, margin: 0 }}
              onClick={(e) => {
                history.push("/gameredemption");
              }}
            >
              <img
                src={gameBanner}
                alt="Pralai Code Redeem Game Banner"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </IonCard>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: 16,
            }}
          >
            <IonCard
              className={"kgo-card kgo-light card-link"}
              style={{ height: 128 }}
              onClick={(e) => {
                history.push("/mission");
              }}
            >
              <IonCardContent
                className={"ion-no-padding"}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <IonRow>
                  <IonCol size={"12"}>
                    <div
                      className={"card-promotion"}
                      style={{ margin: 3, marginTop: 5 }}
                    >
                      <p style={{ fontWeight: 600 }}>ทำ Mission</p>
                      <p style={{ fontWeight: 600 }}>
                        เพื่อรับ KGO Token เพิ่ม
                      </p>
                    </div>
                  </IonCol>
                  <IonCol size={"12"} className={"ion-text-center"}>
                    <img
                      src={missionImg}
                      style={{ width: 115, marginTop: 5 }}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
            <IonCard
              className={"kgo-card kgo-light card-link"}
              style={{ height: 128 }}
              onClick={(e) => {
                history.push("/myprize");
              }}
            >
              <IonCardContent
                className={"ion-no-padding"}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <IonRow>
                  <IonCol size={"12"}>
                    <div
                      className={"card-promotion"}
                      style={{ margin: 3, marginTop: 5 }}
                    >
                      <p style={{ fontWeight: 600 }}>ตรวจสอบและรับรางวัล</p>
                      <p style={{ fontWeight: 600 }}>ที่หน้า My Voucher</p>
                    </div>
                  </IonCol>
                  <IonCol size={"12"} className={"ion-text-center"}>
                    <img
                      src={missionImg}
                      style={{ width: 115, marginTop: 5 }}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </div>

          {/* <div style={{ padding: 16 }}>
            <p>ประวัติการลุ้นรางวัล</p>
            <div
              style={{
                padding: 16,
                backgroundColor: "lightgray",
                borderRadius: 16,
              }}
            ></div>
          </div> */}
          {/* <img src={titleImg} /> */}
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LuckyDraw;
