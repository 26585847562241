import React, { useState } from "react";
import { useHistory } from "react-router";
const RewardCards = ({
  itemId,
  meta,
  data,
  kgo,
  isClaimed,
  source,
  isOpen,
  setIsOpen,
  setSelectVoucher,
}: {
  itemId: string;
  source?: string;
  data: any;
  meta?: any;
  isClaimed?: boolean;
  kgo?: any;
  isOpen?: boolean;
  setIsOpen: () => void;
  setSelectVoucher: () => {};
}) => {
  const history = useHistory();
  const handleConfirmBtn = () => {
    if (!isClaimed) {
      setSelectVoucher(
        // @ts-ignore
        kgo >= data?.price
          ? {
              title: data?.name,
              description: data?.description,
              isCheckbox: data?.isDeliver,
            }
          : {
              title: "KGO ไม่เพียงพอ",
              description: "ไม่สามารถแลกรางวัลนี้ได้เนื่องจาก KGO ไม่เพียงพอ",
              isCheckbox: false,
            }
      );
    } else {
      history.push(`prize/${itemId}`);
    }
  };
  return (
    <div
      style={{
        borderWidth: 2,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderStyle: "solid",
        borderRadius: 16,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <img
        src={data?.imgUrl}
        style={{
          width: "100%",
          height: 146,
          objectFit: "cover",
          borderTopRightRadius: 16,
          borderTopLeftRadius: 16,
        }}
        alt="voucher preview"
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 8,
          backgroundColor: "rgba(255, 145, 0,0.9)",
          borderBottomRightRadius: 16,
          borderBottomLeftRadius: 16,
        }}
      >
        <div style={{ fontFamily: "NotoSansThai-Regular" }}>
          <p
            style={{
              fontSize: 16,
              color: "white",
              fontWeight: "bold",
              margin: 0,
            }}
          >
            {data?.name}
            {source === "history" && (
              <span
                // @ts-ignore
                onClick={() => setIsOpen(meta)}
                style={{
                  fontFamily: "NotoSansThai-Regular",
                  cursor: "pointer",
                }}
              >
                {" "}
                {data?.isDeliver && "ตรวจสอบสถานะ"}
              </span>
            )}
          </p>
          <p
            style={{
              fontFamily: "NotoSansThai-Regular",
              fontSize: 14,
              // marginTop: 4,
              color: "rgba(0, 0, 0, 0.5)",
              margin: 0,
            }}
          >
            {data?.description}
          </p>
          <p
            style={{
              fontFamily: "NotoSansThai-Regular",
              fontSize: 14,
              // marginTop: ,
              color: "rgba(0, 0, 0, 0.5)",
              margin: 0,
            }}
          >
            {data?.startDate}
          </p>
        </div>
        {source !== "history" && (
          <button
            // disabled={balance <= data?.price}
            onClick={handleConfirmBtn}
            style={{
              // @ts-ignore
              backgroundColor: kgo < data?.price ? "gray" : "#FF6E07",
              padding: 8,
              borderRadius: 8,
              height: 36,
              color: "white",
            }}
          >
            {isClaimed ? "รับสิทธิ์" : `${data?.price} KGO`}
          </button>
        )}
      </div>
    </div>
  );
};

export default RewardCards;
