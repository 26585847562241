import {
    IonButtons,
    IonContent,
    IonHeader, IonItem, IonLabel,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";

const Setting: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)

    async function goToLogOut() {
        appContext.apiService.signOut();
    }
    useEffect(() => {
        console.log('setting');
    }, [])
    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar >
                        <IonButtons slot="start">
                            <IonBackButton defaultHref='/main' />
                        </IonButtons>
                        <IonTitle>ตั้งค่า</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonList>
                        <IonItem>
                            <IonLabel>e-KYC</IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonLabel>แก้ไขข้อมูล</IonLabel>
                        </IonItem>
                        <IonItem style={{cursor:'pointer'}} onClick={(e: any) => {
                            goToLogOut();
                        }} >
                            <IonLabel>LogOut</IonLabel>
                        </IonItem>
                    </IonList>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Setting;
