import {
  IonButton,
  IonCol,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import bankPng1 from "../../assets/payment-kgo.png";

const FullForm = ({
  appContext,
  checkEdited,
  handleSubmitFullForm,
}: {
  appContext: any;
  handleSubmitFullForm: () => {};
  checkEdited: () => {};
}) => {
  const [fileImage, setFileImage] = useState();
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [provinceID, setProvinceID] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [amphurID, setAmphurID] = useState("");
  const [amphurName, setAmphurName] = useState("");
  const [tambonID, setTambonID] = useState("");
  const [tambonName, setTambonName] = useState("");
  const [dropdownProvince, setDropdownProvince] = useState([]);
  const [dropdownAmphur, setDropdownAmphur] = useState([]);
  const [dropdownTambon, setDropdownTambon] = useState([]);
  const [isShowQR, setIsShowQR] = useState(true);

  useEffect(() => {
    loadProvince();
  }, [appContext.profile]);

  async function loadProvince() {
    if (appContext?.apiService) {
      appContext.apiService.getProvince().then((response: any) => {
        // console.log("getProvince", response);
        setDropdownProvince(response);
      });
    }
  }

  async function loadAmphur(province: string) {
    console.log("pro", province);
    if (appContext?.apiService) {
      appContext.apiService.getAmphur(province).then((response: any) => {
        // console.log('getAmphur', response)
        setDropdownAmphur(response);
      });
    }
  }

  async function loadTambon(amphur: string) {
    if (appContext?.apiService) {
      appContext.apiService.getTambon(amphur).then((response: any) => {
        // console.log('getTambon', response)
        setDropdownTambon(response);
      });
    }
  }

  async function loadZipcode(tambon: string) {
    if (appContext?.apiService) {
      appContext.apiService.getZipcode(tambon).then((response: any) => {
        setZipcode(response[0].zipcode);
      });
    }
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = new FormData();
    if (fileImage) {
      data.append("slipImg", fileImage);
    }
    data.append("address", address ?? "");
    data.append("provinceId", provinceID ?? "");
    data.append("provinceName", provinceName ?? "");
    data.append("amphurId", amphurID ?? "");
    data.append("amphurName", amphurName ?? "");
    data.append("districtId", tambonID ?? "");
    data.append("districtName", tambonName ?? "");
    data.append("zipcode", zipcode ?? "");
    data.append("deliverStatus", "pending");

    updateForm(data);
  };

  const updateForm = (data: any) => {
    // @ts-ignore
    handleSubmitFullForm(data);
  };

  useEffect(() => {
    if (
      address !== "" ||
      provinceID !== "" ||
      provinceName !== "" ||
      amphurID !== "" ||
      amphurName !== "" ||
      tambonID !== "" ||
      tambonName !== "" ||
      zipcode
    ) {
      // @ts-ignore
      checkEdited(true);
    } else {
      // @ts-ignore

      checkEdited(false);
    }
  }, [
    address,
    provinceID,
    provinceName,
    amphurID,
    amphurName,
    tambonID,
    tambonName,
    zipcode,
  ]);

  return (
    <form onSubmit={handleSubmit} className="app-container">
      <div style={{ margin: "16px 0" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          จำเป็นต้องชำระเงินค่าจัดส่ง{" "}
          <button
            type="button"
            style={{ backgroundColor: "transparent", color: "gray" }}
            onClick={() => setIsShowQR(!isShowQR)}
          >
            {isShowQR ? "ซ่อนรายละเอียด" : "แสดงรายละเอียด"}
          </button>
        </div>
        {isShowQR && (
          <>
            <div
              style={{ display: "flex", justifyContent: "center", padding: 16 }}
            >
              <p style={{ margin: 0 }}>ชำระค่าจัดส่งจำนวนเป็นจำนวนเงิน 100 บาท</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={bankPng1} alt={"Bank account number for payment slip"} />
              <p style={{ margin: 0 }}>เลขที่บัญชี</p>
              <p style={{ margin: 0 }}>ธนาคารกสิกรไทย</p>
              <p style={{ margin: 0 }}>138-1-57532-9</p>
              <p style={{ margin: 0 }}>ชื่อบัญชี บจก.อินฟินิทแลนด์ โทเคน</p>
            </div>
          </>
        )}
      </div>
      <div style={{ height: 1, width: "100%", backgroundColor: "lightgray" }} />
      <IonList
        style={{ marginLeft: -8, marginTop: 8, fontSize: 14, fontWeight: 600 }}
      >
        <IonItem>
          <IonLabel position="stacked" className={""}>
            หลักฐานการโอนเงิน<span style={{ color: "red" }}>*</span>
          </IonLabel>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input
              type="file"
              // @ts-ignore
              onChange={(e) => setFileImage(e.target.files[0])}
            />
          </div>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked" className={""}>
            ที่อยู่<span style={{ color: "red" }}>*</span>
          </IonLabel>
          <IonInput
            required
            type={"text"}
            value={address}
            placeholder="ระบุที่อยู่"
            style={{ fontSize: 14 }}
            onIonChange={(e) => {
              setAddress(e.detail.value!);
            }}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className={""}>
            จังหวัด<span style={{ color: "red" }}>*</span>
          </IonLabel>
          <select
            onChange={(e) => {
              if (e.target.value != "") {
                const name = dropdownProvince.find(
                  (r: any) => r.provinceID == e.target.value
                );
                setProvinceID(e.target.value);
                // @ts-ignore
                setProvinceName(name ? name.provinceName : "");
                loadAmphur(e.target.value).then();
              } else {
                setProvinceID("");
                setProvinceName("");
              }
              setAmphurID("");
              setAmphurName("");
              setTambonID("");
              setTambonName("");
              setZipcode("");
            }}
            style={{
              width: "100%",
              border: "0",
              backgroundColor: "transparent",
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            <option>เลือกจังหวัด</option>
            {dropdownProvince.map((province) => (
              // @ts-ignore
              <option style={{ width: "100%" }} value={province?.provinceID}>
                {/* @ts-ignore */}
                {province?.provinceName}
              </option>
            ))}
          </select>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className={""}>
            อำเภอ<span style={{ color: "red" }}>*</span>
          </IonLabel>
          <select
            style={{
              width: "100%",
              border: "0",
              backgroundColor: "transparent",
              fontSize: 14,
              fontWeight: 500,
            }}
            onChange={(e) => {
              if (e.target.value != "") {
                const name = dropdownAmphur.find(
                  (r: any) => r.amphurID == e.target.value
                );
                // @ts-ignore
                setAmphurName(name ? name.amphurName : "");
                setAmphurID(e.target.value);
                loadTambon(e.target.value).then();
              } else {
                setAmphurID("");
                setAmphurName("");
              }
              setTambonID("");
              setTambonName("");
              setZipcode("");
            }}
          >
            <option>เลือกอำเภอ</option>
            {dropdownAmphur.map((amphur) => (
              // @ts-ignore
              <option style={{ width: "100%" }} value={amphur?.amphurID}>
                {/* @ts-ignore */}
                {amphur.amphurName}
              </option>
            ))}
          </select>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className={""}>
            ตำบล<span style={{ color: "red" }}>*</span>
          </IonLabel>
          <select
            style={{
              width: "100%",
              border: "0",
              backgroundColor: "transparent",
              fontSize: 14,
              fontWeight: 500,
            }}
            onChange={(e) => {
              const name = dropdownTambon.find(
                (r: any) => r.districtID == e.target.value
              );
              setTambonID(e.target.value);
              // @ts-ignore
              setTambonName(name ? name.districtName : "");
              loadZipcode(e.target.value).then();
              setZipcode("");
            }}
          >
            <option>เลือกตำบล</option>
            {dropdownTambon.map((tambon) => (
              // @ts-ignore
              <option value={tambon.districtID}>{tambon.districtName}</option>
            ))}
          </select>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked" className={""}>
            รหัสไปรษณีย์<span style={{ color: "red" }}>*</span>
          </IonLabel>
          <IonInput
            type={"text"}
            required
            maxlength={10}
            style={{ fontSize: 14 }}
            readonly
            value={zipcode}
            placeholder="กรอกรหัสไปรษณีย์"
            onIonChange={(e) => {
              setZipcode(e.detail.value!);
            }}
          />
        </IonItem>
      </IonList>
      <IonRow className={"kgo-row ion-text-center kgo-m"}>
        <IonCol>
          <IonButton className={"kgo-btn"} expand={"block"} type={"submit"}>
            บันทึก
          </IonButton>
        </IonCol>
      </IonRow>
    </form>
  );
};

export default FullForm;
