import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {
  arrowBackOutline,
  backspace,
  checkmark,
  checkmarkCircle,
  checkmarkSharp,
  ribbonOutline,
  thumbsUpSharp,
} from "ionicons/icons";
import mission1 from "../assets/icon-identity-mission.svg";
import mission2 from "../assets/facebook-preview.png";
import mission3 from "../assets/icon-twitter-mission.png";
import mission4 from "../assets/icon-line-mission.png";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
import dollar from "../assets/coin_kgo1.svg";

const Mission: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [rewardFacebook, setRewardFacebook] = useState(false);
  const [rewardLine, setRewardLine] = useState(false);
  const [rewardTwitter, setRewardTwitter] = useState(false);
  const [rewardEmail, setRewardEmail] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  function getProfile() {
    // console.log('getBalance');
    if (appContext.apiService) {
      appContext.getProfileBalance();
    } else {
      console.log("false");
    }
  }

  useEffect(() => {
    if (appContext.profile && appContext.profile.rewards) {
      // console.log('reward', appContext.profile.rewards.facebook);
      if (appContext.profile.rewards.email) {
        // console.log('email', appContext.profile.rewards.email.rewardAt);
        setRewardEmail(true);
      }
      if (appContext.profile.rewards.line) {
        // console.log('line', appContext.profile.rewards.line.rewardAt);
        setRewardLine(true);
      }

      if (appContext.profile?.rewards.facebook) {
        // console.log('facebook', appContext.profile.rewards.facebook.rewardAt);
        setRewardFacebook(true);
      }

      if (appContext.profile?.rewards.twitter) {
        // console.log('twitter', appContext.profile.rewards.twitter.rewardAt);
        setRewardTwitter(true);
      }
    }
  }, [appContext?.profile]);

  async function likeFacebook() {
    if (rewardFacebook) {
      // TODO ANIMATION BOOM
      // return false;
    }
    await setApplyReward("facebook").then((response: any) => {
      // if (response.success) {
      if (isPlatform("ios") && isPlatform("mobile")) {
        window.open("fb://profile?id=105196832059252");
      } else if (isPlatform("android") && isPlatform("mobile")) {
        window.open("fb://page/105196832059252");
      } else {
        window.open("https://www.facebook.com/KGOtoken", "_blank");
      }
      // }
    });
  }

  async function AddLine() {
    if (rewardLine) {
      // TODO ANIMATION BOOM
      // return false;
    }
    await setApplyReward("line").then((response: any) => {
      window.open("https://line.me/R/ti/p/@920qmmzo", "_blank");
    });
  }

  async function likeTwitter() {
    if (rewardTwitter) {
      return false;
    }
    await setApplyReward("twitter").then((response: any) => {
      if (response.success) {
        window.open("https://twitter.com/MetaverseSiam", "_blank");
      }
    });
  }

  async function setApplyReward(type: string) {
    setShowLoading(true);
    let res = null;
    await appContext.apiService.applyReward(type).then((response: any) => {
      // console.log('response', response)
      res = response;
      appContext.getProfileBalance().then((res: any) => {});
      setShowLoading(false);
    });
    return res;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonTitle>ทำ Mission</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"bg-secondary"}>
        <div className="app-container">
          {/*<h3 style={{marginTop: 42, marginLeft: 32}}>*/}
          {/*    Mission ง่ายๆ รับ KGO เพิ่มไปเลย*/}
          {/*</h3>*/}
          <IonRow>
            <IonCol size={"12"}>
              <IonCard
                className={
                  rewardEmail
                    ? "kgo-card2 kgo-card-color-disabled"
                    : "kgo-card2 kgo-card-color2"
                }
                routerLink={"/edit-profile"}
              >
                <IonCardContent className={"mission-card-content"}>
                  {rewardEmail ? (
                    <span
                      className={"mission-success"}
                      style={rewardEmail ? { opacity: 0.5 } : {}}
                    >
                      <IonIcon icon={checkmarkSharp} />
                    </span>
                  ) : null}
                  <IonRow>
                    <IonCol size={"8"}>
                      <div
                        className={"mission-text"}
                        style={{ marginTop: 9, marginBottom: 9 }}
                      >
                        <IonText style={{ fontWeight: 600 }}>
                          ยันยืนตัวตน
                        </IonText>
                        <div style={{ fontSize: 16, fontWeight: 600 }}>
                          รับไปเลย{" "}
                          <img src={dollar} className={"coin-dollar"} />{" "}
                          <IonText
                            style={{ color: "#FFA412", fontWeight: 400 }}
                          >
                            +100{" "}
                          </IonText>{" "}
                          KGO
                        </div>
                        {rewardEmail ? (
                          <div style={{ marginTop: 8 }}>
                            <span
                              style={{
                                backgroundColor: "#CDCCCC",
                                borderRadius: 6,
                                color: "#FFFFFF",
                                display: "inline-flex",
                                fontSize: 14,
                                padding: "4px 10px 4px 10px",
                                alignItems: "end",
                              }}
                            >
                              <span style={{}}>สำเร็จแล้ว</span>
                            </span>
                          </div>
                        ) : (
                          <div style={{ marginTop: 8 }}>
                            <span
                              style={{
                                backgroundColor: "#FFA412",
                                borderRadius: 6,
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: 14,
                                padding: "4px 10px 4px 10px",
                              }}
                            >
                              <span style={{}}>ทำภารกิจ</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </IonCol>
                    <IonCol
                      style={{
                        textAlign: "center",
                        backgroundColor: "white",
                        borderRadius: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                      size={"4"}
                    >
                      <img
                        src={mission1}
                        style={{
                          width: "100%",
                          position: "relative",
                          bottom: 0,
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size={"12"}>
              <IonCard
                className={
                  rewardFacebook
                    ? "kgo-card2 kgo-card-color-disabled"
                    : "kgo-card2 kgo-card-color2"
                }
                onClick={(e: any) => {
                  likeFacebook();
                }}
              >
                <IonCardContent className={"mission-card-content"}>
                  {rewardFacebook ? (
                    <span
                      className={"mission-success"}
                      style={rewardFacebook ? { opacity: 0.5 } : {}}
                    >
                      <IonIcon icon={checkmarkSharp} />
                    </span>
                  ) : null}
                  <IonRow>
                    <IonCol size={"8"}>
                      <div
                        className={"mission-text"}
                        style={{ marginTop: 9, marginBottom: 9 }}
                      >
                        <IonText style={{ fontWeight: 600, fontSize: 16 }}>
                          กด{" "}
                          <IonText style={{ color: "#3D6AD6" }}>LIKE</IonText>{" "}
                          Fanpage Facebook{" "}
                        </IonText>
                        <div style={{ fontSize: 16, fontWeight: 600 }}>
                          KGO Token รับไปเลย{" "}
                          <img src={dollar} className={"coin-dollar"} />{" "}
                          <IonText
                            style={{ color: "#FFA412", fontWeight: 400 }}
                          >
                            +100{" "}
                          </IonText>{" "}
                          KGO
                        </div>
                        {rewardFacebook ? (
                          <div style={{ marginTop: 8 }}>
                            <span
                              style={{
                                backgroundColor: "#CDCCCC",
                                borderRadius: 6,
                                color: "#FFFFFF",
                                display: "inline-flex",
                                fontSize: 14,
                                padding: "4px 10px 4px 10px",
                                alignItems: "end",
                              }}
                            >
                              <span style={{}}>สำเร็จแล้ว</span>
                            </span>
                          </div>
                        ) : (
                          <div style={{ marginTop: 8 }}>
                            <span
                              style={{
                                backgroundColor: "#FFA412",
                                borderRadius: 6,
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: 14,
                                padding: "4px 10px 4px 10px",
                              }}
                            >
                              <span style={{}}>ทำภารกิจ</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </IonCol>
                    <IonCol
                      style={{
                        textAlign: "center",
                        backgroundColor: "white",
                        borderRadius: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                      size={"4"}
                    >
                      <img
                        src={mission2}
                        style={{
                          width: "100%",
                          position: "relative",
                          bottom: 0,
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>

            {/*<IonCol size={"12"} style={{cursor: 'pointer'}}>*/}
            {/*    <IonCard*/}
            {/*        className={rewardTwitter ? 'kgo-card kgo-card-color-disabled' : 'kgo-card kgo-card-color'}*/}
            {/*        onClick={(e: any) => {*/}
            {/*            likeTwitter()*/}
            {/*        }}>*/}
            {/*        <IonCardContent className={"mission-card-content"}>*/}
            {/*            <IonRow>*/}
            {/*                <IonCol size={"8"}>*/}
            {/*                    <div className={"mission-text"}>*/}
            {/*                        เยี่ยมชม และ กด Follow Twitter*/}
            {/*                        <div>MetaverseSiam</div>*/}
            {/*                        <div>รับไปเลย +100 KGO</div>*/}
            {/*                    </div>*/}
            {/*                </IonCol>*/}
            {/*                <IonCol  style={{*/}
            {/*                    textAlign:'center',*/}
            {/*                    backgroundColor: 'white',*/}
            {/*                    borderRadius: 12*/}
            {/*                }} size={"4"}>*/}
            {/*                    <img src={mission3} style={{width: 90}}/>*/}
            {/*                </IonCol>*/}
            {/*            </IonRow>*/}
            {/*        </IonCardContent>*/}
            {/*    </IonCard>*/}
            {/*</IonCol>*/}
            <IonCol size={"12"}>
              <IonCard
                className={
                  rewardLine
                    ? "kgo-card2 kgo-card-color-disabled"
                    : "kgo-card2 kgo-card-color2"
                }
                onClick={(e: any) => {
                  AddLine();
                }}
              >
                <IonCardContent className={"mission-card-content"}>
                  {rewardLine ? (
                    <span
                      className={"mission-success"}
                      style={rewardFacebook ? { opacity: 0.5 } : {}}
                    >
                      <IonIcon icon={checkmarkSharp} />
                    </span>
                  ) : null}
                  <IonRow>
                    <IonCol size={"9"}>
                      <div
                        className={"mission-text"}
                        style={{ marginTop: 9, marginBottom: 9 }}
                      >
                        <IonText style={{ fontWeight: 600, fontSize: 16 }}>
                          Add Line Official{" "}
                        </IonText>
                        <div style={{ fontSize: 16, fontWeight: 600 }}>
                          KGO Token รับไปเลย{" "}
                          <img src={dollar} className={"coin-dollar"} />{" "}
                          <IonText
                            style={{ color: "#FFA412", fontWeight: 400 }}
                          >
                            +100{" "}
                          </IonText>{" "}
                          KGO
                        </div>
                        {rewardLine ? (
                          <div style={{ marginTop: 8 }}>
                            <span
                              style={{
                                backgroundColor: "#CDCCCC",
                                borderRadius: 6,
                                color: "#FFFFFF",
                                display: "inline-flex",
                                fontSize: 14,
                                padding: "4px 10px 4px 10px",
                                alignItems: "end",
                              }}
                            >
                              <span>สำเร็จแล้ว</span>
                            </span>
                          </div>
                        ) : (
                          <div style={{ marginTop: 8 }}>
                            <span
                              style={{
                                backgroundColor: "#FFA412",
                                borderRadius: 6,
                                color: "#ffffff",
                                display: "inline-flex",
                                fontSize: 14,
                                padding: "4px 10px 4px 10px",
                              }}
                            >
                              <span>ทำภารกิจ</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </IonCol>
                    <IonCol
                      style={{
                        textAlign: "center",
                        backgroundColor: "white",
                        borderRadius: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                      size={"3"}
                    >
                      <img
                        src={mission4}
                        style={{
                          width: 120,
                          position: "relative",
                          bottom: 0,
                          transform: "rotate(15deg)",
                        }}
                      />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Mission;
