import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonCardContent,
  IonCard,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonLabel,
  IonLoading,
  IonModal,
  isPlatform,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {
  arrowBackOutline,
  arrowDown,
  arrowUp,
  imagesOutline,
} from "ionicons/icons";
import { AppContext } from "../context/state";
import { useParams } from "react-router";
import dollar from "../assets/coin_kgo1.svg";
import LogoKgo from "../assets/kgo-nft.png";
import fire from "../assets/fire.svg";
import TmpImage from "../assets/bi_image-alt.png";
import BizDetailGallery from "../components/BizDetailGallery";
import tempMainBiz from "../assets/tempBiz.png";

interface ParamTypes {
  bizId: string;
}

const BizDetail: React.FC = () => {
  const appContext = useContext(AppContext);
  const { bizId } = useParams<ParamTypes>();
  const [biz, setBiz] = useState<any>({});
  const [mainImages, setMainImages] = useState("");
  const [images, setImages] = useState<Array<any>>([]);
  const [voucherList, setVoucherList] = useState<Array<any>>([]);
  const [filterVoucher, setFilterVoucher] = useState("send");
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    console.log("get Biz");
    getBizList().catch(console.error);
  }, []);

  async function getBizList() {
    if (appContext.apiService) {
      appContext.apiService.getBizDetail(bizId).then((response: any) => {
        if (response?.success && response.listing.length > 0) {
          if (response.listing[0]?.listingProfile?.images) {
            const tempImage = [];
            let tempMainImage = "";
            for (
              let i = 0;
              i < response.listing[0]?.listingProfile?.images.length;
              i++
            ) {
              if (response.listing[0].listingProfile.images[i]) {
                if (i == 0) {
                  console.log(
                    "MainImages",
                    response.listing[0].listingProfile.images[i]
                  );
                  tempMainImage = response.listing[0].listingProfile.images[i];
                } else {
                  tempImage.push({
                    original:
                      response.listing[0].listingProfile.images[i] +
                      "?v=" +
                      new Date().getTime(),
                    thumbnail:
                      response.listing[0].listingProfile.images[i] +
                      "?v=" +
                      new Date().getTime(),
                  });
                }
              }
            }
            setMainImages(tempMainImage);
            console.log("tempImage");
            setImages(tempImage);
          }
          console.log("biz", response.listing[0]);
          setBiz(response.listing[0]);

          if (response.listing[0].vouchers.length > 0) {
            setVoucherList(response.listing[0].vouchers.reverse());
          }
        }
      });
    }
  }

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/biz-list"
            />
          </IonButtons>
          <IonTitle>โปรโมชั่น</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"bg-secondary"}>
        <div className="app-container">
          {mainImages == "" ? (
            <div
              className={"cover-biz-image card-shadow"}
              style={{
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              <img
                className={"cover-biz-image-inner "}
                src={TmpImage}
                style={{ marginTop: 40 }}
              />
            </div>
          ) : null}
          {mainImages != "" ? (
            <div
              className={"cover-biz-image "}
              style={{
                backgroundPosition: "center",
                cursor: "pointer",
              }}
            >
              <img className={"cover-biz-image-inner "} src={mainImages} />
            </div>
          ) : null}

          <IonRow style={{ marginTop: "-70px" }}>
            <IonCol>
              <IonCard>
                <IonCardContent style={{ padding: 0 }}>
                  {biz?.profile ? (
                    <IonRow style={{ margin: "8px 12px 20px" }}>
                      <IonCol size="12">
                        <div style={{ display: "inline-block", width: "80%" }}>
                          <IonText
                            style={{
                              fontSize: 20,
                              fontWeight: 600,
                              color: "#000000",
                            }}
                          >
                            {biz.profile.businessName}
                          </IonText>
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            width: "20%",
                            textAlign: "right",
                          }}
                        >
                          <IonIcon
                            style={{ color: "#4c8ce7", fontSize: 18 }}
                            icon={imagesOutline}
                            onClick={(e: any) => {
                              setIsOpenModal(true);
                            }}
                          />
                        </div>

                        <div style={{ fontSize: 12, color: "#989898" }}>
                          {biz.profile.address} ต.{biz.profile.districtName} อ.
                          {biz.profile.amphurName} จ.{biz.profile.provinceName}{" "}
                        </div>
                        {biz.listingProfile?.payType &&
                        biz.listingProfile.payType == "bank" ? (
                          <span
                            className={"kgo-badge"}
                            style={{
                              background: "#C4C4C4",
                              color: "#222222",
                              fontWeight: 600,
                            }}
                          >
                            โอนผ่านธนาคาร
                          </span>
                        ) : null}
                        {biz.listingProfile?.payType &&
                        biz.listingProfile.payType == "promptpay" ? (
                          <span
                            className={"kgo-badge"}
                            style={{
                              background: "#C4C4C4",
                              color: "#222222",
                              fontWeight: 600,
                            }}
                          >
                            พร้อมเพย์
                          </span>
                        ) : null}

                        <span
                          className={"kgo-badge"}
                          style={{
                            background: "#C4C4C4",
                            color: "#222222",
                            fontWeight: 600,
                          }}
                        >
                          {biz.profile.businessType == "อื่นๆ"
                            ? biz.profile.businessOtherType
                            : biz.profile.businessType}
                          {biz.profile.businessType == "อื่นๆ"
                            ? biz.profile.businessOtherType
                              ? biz.profile.businessOtherType
                              : "อื่นๆ"
                            : biz.profile.businessType}
                        </span>

                        {biz.recommend > 0 ? (
                          <span>
                            {biz.rank > 0 && biz.rank <= 10 ? (
                              <span
                                className={"kgo-badge"}
                                style={{
                                  background: "#FD9E18",
                                  color: "#FFFFFF",
                                  fontWeight: 600,
                                }}
                              >
                                <img
                                  className={"icon-inner"}
                                  style={{ marginRight: 3 }}
                                  src={fire}
                                />
                                ร้านใหม่มาแรง
                              </span>
                            ) : (
                              <span
                                className={"kgo-badge"}
                                style={{
                                  background: "#FD9E18",
                                  color: "#FFFFFF",
                                  fontWeight: 600,
                                }}
                              >
                                ร้านค้าแนะนำ
                              </span>
                            )}
                          </span>
                        ) : null}

                        <hr
                          style={{
                            border: "0.2px solid #F4F4F4",
                            width: "100%",
                            backgroundColor: "#F4F4F4",
                          }}
                        />
                        <IonText style={{ display: "grid", fontSize: 13 }}>
                          <IonText
                            style={{ fontWeight: 600, color: "#242424" }}
                          >
                            {" "}
                            เกี่ยวกับร้านค้า{" "}
                          </IonText>
                          <IonText
                            style={{ fontWeight: 600, color: "#242424" }}
                          >
                            {" "}
                            {biz.listingProfile?.subTitle}{" "}
                          </IonText>
                          <IonText
                            style={{ fontWeight: 600, color: "#242424" }}
                          >
                            {" "}
                            {biz.listingProfile?.detail}{" "}
                          </IonText>
                          <IonText
                            style={{ fontWeight: 600, color: "#242424" }}
                          >
                            โทร:{" "}
                            <a href={"tel:" + biz.phoneNumber}>
                              {biz.phoneNumber}
                            </a>
                          </IonText>
                          {biz.listingProfile?.facebook &&
                          biz.listingProfile.facebook != "" ? (
                            <IonText
                              style={{ fontWeight: 600, color: "#242424" }}
                            >
                              Facebook:{" "}
                              <a
                                target="_blank"
                                href={biz.listingProfile?.facebook}
                              >
                                Link
                              </a>
                            </IonText>
                          ) : null}
                          {biz.listingProfile?.lineID &&
                          biz.listingProfile.lineID != "" ? (
                            <IonText
                              style={{ fontWeight: 600, color: "#242424" }}
                            >
                              LineID:{" "}
                              <a
                                target="_blank"
                                href={biz.listingProfile?.lineID}
                              >
                                Link
                              </a>
                            </IonText>
                          ) : null}
                          {biz.listingProfile?.googleMap &&
                          biz.listingProfile.googleMap != "" ? (
                            <IonText
                              style={{ fontWeight: 600, color: "#242424" }}
                            >
                              Google Map:{" "}
                              <a
                                target="_blank"
                                href={biz.listingProfile.googleMap}
                              >
                                link
                              </a>
                            </IonText>
                          ) : null}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  ) : (
                    <div>
                      <IonLoading isOpen={true} />
                    </div>
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <div
            style={{
              backgroundColor: "#FFFFFF",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              textAlign: "center",
            }}
            className={"ion-no-padding"}
          >
            <div
              className={
                filterVoucher == "receive"
                  ? "select-voucher active-voucher"
                  : "select-voucher"
              }
              style={{ borderRight: "1px solid #D8D8D8" }}
              onClick={(e: any) => {
                setFilterVoucher("receive");
              }}
            >
              <IonText>ลูกค้าใช้</IonText>
            </div>
            <div
              className={
                filterVoucher == "send"
                  ? "select-voucher active-voucher"
                  : "select-voucher"
              }
              onClick={(e: any) => {
                setFilterVoucher("send");
              }}
            >
              <IonText>ร้านส่งให้</IonText>
            </div>
          </div>
          <div className={"kgo-row"}>
            {voucherList.filter(
              (e) => e.isDeleted == false && e.type == filterVoucher
            ).length == 0 ? (
              <h4 className="ion-text-center">
                <IonLabel>ยังไม่มีรายการ Voucher...</IonLabel>
              </h4>
            ) : (
              voucherList
                .filter((e) => e.isDeleted == false && e.type == filterVoucher)
                .map((r: any, i: number) => (
                  <div
                    key={i}
                    className={"kgo-row-tran"}
                    style={{
                      width: "100%",
                      display: "flex",
                      border: "1pt solid rgba(0, 0, 0, 0.1)",
                      borderRadius: 12,
                      filter: "drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.16))",
                    }}
                  >
                    <div
                      style={{
                        width: 20,
                        minHeight: "100%",
                        borderRadius: "12px 0 0 12px",
                        display: "inline-block",
                        marginRight: 5,
                      }}
                      className={
                        r.type == "send" ? "send-color" : "receive-color"
                      }
                    />
                    <div style={{ display: "inline-block" }}>
                      <img
                        src={r.image && r.image != "" ? r.image : LogoKgo}
                        style={{
                          height: 80,
                          width: 90,
                          marginTop: 12,
                          marginBottom: 5,
                          objectFit: "cover",
                          borderRadius: 12,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexGrow: 3,
                        marginLeft: 5,
                        marginTop: 5,
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <IonText style={{ fontWeight: 500, fontSize: 20 }}>
                          {r.name}
                        </IonText>
                        {r.isPublic ? (
                          <IonText style={{ float: "right" }}>
                            <span
                              style={{
                                background: "#FFA412",
                                fontSize: 9,
                                borderRadius: 24,
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginRight: 5,
                              }}
                            >
                              <span style={{ color: "#414143" }}>Public</span>
                            </span>
                          </IonText>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ marginTop: "auto", marginBottom: 8 }}>
                        <IonText
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            marginRight: 10,
                          }}
                        >
                          {r.type == "send" ? (
                            <span
                              style={{
                                background: "#45B865",
                                fontSize: 13,
                                borderRadius: 12,
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingLeft: 5,
                                paddingRight: 5,
                                float: "right",
                                marginRight: 5,
                              }}
                            >
                              <IonIcon
                                style={{
                                  color: "#FFFFFF",
                                  verticalAlign: "middle",
                                  fontSize: 18,
                                }}
                                icon={arrowDown}
                              />
                              <span style={{ color: "#FFFFFF" }}>
                                {" "}
                                ร้านส่งให้
                              </span>
                            </span>
                          ) : (
                            <span
                              style={{
                                background: "#F85555",
                                fontSize: 13,
                                borderRadius: 12,
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingLeft: 5,
                                paddingRight: 5,
                                float: "right",
                                marginRight: 5,
                              }}
                            >
                              <IonIcon
                                style={{
                                  color: "#FFFFFF",
                                  verticalAlign: "middle",
                                  fontSize: 18,
                                }}
                                icon={arrowUp}
                              />
                              <span style={{ color: "#FFFFFF" }}>
                                {" "}
                                ลูกค้าใช้&nbsp;
                              </span>
                            </span>
                          )}
                          <img
                            src={dollar}
                            style={{ width: 16, verticalAlign: "sub" }}
                          />{" "}
                          <IonText
                            style={{
                              fontSize: 20,
                              fontWeight: 500,
                              color: "#FF8A19",
                            }}
                          >
                            {numberWithCommas(r.amount)}{" "}
                            <IonText style={{ fontSize: 12, color: "#242424" }}>
                              KGO
                            </IonText>
                          </IonText>
                        </IonText>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
        <IonModal
          isOpen={isOpenModal}
          // backdropDismiss={true}
          initialBreakpoint={0.7}
          // breakpoints={[1, 1, 1]}
          onDidDismiss={() => {
            setIsOpenModal(false);
          }}
        >
          <BizDetailGallery
            closeModal={setIsOpenModal}
            isOpen={isOpenModal}
            data={images}
          />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default BizDetail;
