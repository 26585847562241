import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
  useIonAlert,
  IonImg,
  IonFooter,
  IonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline, filter } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../context/state";
// @ts-ignore
import kgoNFT from "../assets/kgo-nft.png";
// @ts-ignore
import keyImg from "../assets/key.png";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import dollar2 from "../assets/dollar.png";
import RewardCards from "../../components/Cards/RewardCards";
import {
  fetchUserPrizeItem,
  fetchUserPrizeItemHistory,
  fetchUserVoucher,
} from "../../services/voucher/index";
import { redeemVoucherData } from "../../utils/MockData";
import { fetchProfile } from "../../services/user";
import StatusModal from "../../components/Modal/StatusModal";

const MyPrizeHistory: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [present] = useIonAlert();
  const [showLoading, setShowLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [confirmClaim, setConfirmClaim] = useState(false);
  const [confirmCancelClaim, setConfirmCancelClaim] = useState(false);
  const [myPrize, setMyPrize] = useState();
  const [limit, setLimit] = useState(5);
  const [userId, setUserId] = useState();
  const [totalItems, setTotalItems] = useState(0);
  const [selectedItems, setSelectedItems] = useState();

  const handleSelect = (data) => {
    setConfirmCancelClaim(!confirmCancelClaim);
    setSelectedItems(data);
  };

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const fetchTheProfile = async (phone) => {
    const profile = await fetchProfile(phone);
    setUserId(profile.data.id);
  };

  const getUserVoucher = async () => {
    const data = await fetchUserPrizeItemHistory(userId);
    // to filter out isUsed status = true
    setMyPrize(data);
    setTotalItems(data.length);
  };

  useEffect(() => {
    // fetchTheProfile();
    if (appContext.profile) {
      // @ts-ignore
      setPhoneNumber(appContext.profile.phoneNumber);
    }
  }, [appContext]);

  useEffect(() => {
    fetchTheProfile(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (userId) {
      getUserVoucher();
    }
  }, [userId]);

  return (
    <IonPage>
      <StatusModal
        data={selectedItems}
        isOpen={confirmCancelClaim}
        setIsOpen={() => setConfirmCancelClaim(!confirmCancelClaim)}
      />
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/myprize"
            />
          </IonButtons>
          <IonTitle>รายการของรางวัลของฉัน</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: 8,
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <IonText style={{ fontSize: 14 }}>
              {/* @ts-ignore */}
              ทั้งหมด: {myPrize?.length ?? 0}
            </IonText>
          </div>
          <p style={{ padding: 16, margin: 0, color: "gray" }}>
            ของรางวัลที่หมดอายุหรือใช้ไปแล้ว
          </p>
          <div
            style={{
              overflowY: "scroll",
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {/* @ts-ignore */}
            {myPrize?.length > 0 ? (
              // @ts-ignore
              myPrize
                .filter((d, i) => i < limit)
                .map((prize) => (
                  <>
                    <RewardCards
                      source={"history"}
                      itemId={prize._id}
                      data={prize.prizeDetails}
                      isClaimed
                      meta={prize}
                      isOpen={confirmCancelClaim}
                      // @ts-ignore
                      setIsOpen={handleSelect}
                    />
                  </>
                ))
            ) : (
              <p style={{ marginTop: 42, fontSize: 26, alignSelf: "center" }}>
                ไม่พบข้อมูลของรางวัล...
              </p>
            )}
            {/* @ts-ignore */}
            {myPrize && limit < totalItems && (
              <button
                style={{
                  padding: 16,
                  borderRadius: 8,
                  border: "solid 1px lightgray",
                  backgroundColor: "transparent",
                  color: "gray",
                }}
                onClick={() => setLimit(limit + 5)}
              >
                {" "}
                โหลดข้อมูลเพิ่มเติม{" "}
              </button>
            )}
          </div>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyPrizeHistory;
