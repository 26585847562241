import {
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonLoading, IonModal,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar, isPlatform, useIonAlert
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {
    arrowBackOutline,
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import '@splidejs/react-splide/css';
import Gallery from "../components/Gallery";
import MenuTree from "../components/MenuTree";
import Tree3 from "../../../assets/tree/tree-03.png";

const Mission: React.FC<RouteComponentProps> = ({history}) => {

    return (
        <IonPage>

            {/*Header*/}
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>
                        กิจกรรมปลูกต้นไม้
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            {/*Content*/}
            <IonContent className={'bg-secondary'}>

                <div className="app-container">
                    <Gallery/>

                    <MenuTree
                        topic={"ปลูกต้นไม้"}
                        detail={<div>นำ <span style={{color: "#A65408"}}>KGO Token</span> มาแลกเป็นต้นไม้</div>}
                        onClick={() => {
                            history.push('/select-have-a-tree')
                        }}
                    />

                    <MenuTree
                        topic={"ติดตามการปลูกต้นไม้"}
                        detail={"ติดตามการเจริญเติมโตของต้นไม้"}
                        image1={Tree3}
                        image2={Tree3}
                        onClick={() => {
                            history.push('/have-a-tree-history')
                        }}
                    />
                </div>

            </IonContent>

        </IonPage>
    );
};

export default Mission;
