import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonCardContent,
  IonCard,
} from "@ionic/react";
import React, { useRef, useState } from "react";
import TransactionList from "../components/TransactionList";
import { arrowBackOutline } from "ionicons/icons";

const Transaction: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonTitle>Transaction</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonCard className={"kgo-card kgo-card-tran kgo-card-tran-page"}>
            <IonCardContent>
              <TransactionList mode={"page"} />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Transaction;
