import React from 'react';
import styles from "./Gallery.module.css";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import MainPreviewImage from "../MainPreviewImage/MainPreviewImage";
import PreviewImage from "../PreviewImage/PreviewImage";
import Preview01 from "../../../../assets/tree/preview-01.jpeg";
import Preview02 from "../../../../assets/tree/preview-02.jpeg";

export default () => {
    return (
        <div className={styles.imageContent}>
            <Splide
                aria-label="My Favorite Images"
                options={{
                    rewind: true,
                    autoplay: true,
                }}
            >
                <SplideSlide>
                    <MainPreviewImage/>
                </SplideSlide>
                <SplideSlide>
                    <PreviewImage src={Preview01}/>
                </SplideSlide>
                <SplideSlide>
                    <PreviewImage src={Preview02}/>
                </SplideSlide>
            </Splide>
        </div>
    )
}
