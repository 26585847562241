import {
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonLoading, IonModal,
    IonPage,
    IonRow, IonSelect, IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar, isPlatform, useIonAlert, useIonLoading
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {
    arrowBackOutline, arrowUpCircle, addCircleOutline, removeCircleOutline
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import '@splidejs/react-splide/css';
import Gallery from "../../components/Gallery";
import Tree3 from "../../../../assets/tree/tree-03.png";
import styles from "./SelectHaveATreeScreen.module.css"
import {UserProfile} from "../../../../models/Data";
import {ApiService} from "../../../../services/api-service";
import {AppContext} from "../../../../context/state";
import ModalAddress from "../../components/ModalAddress";
import ModalSuccess from "../../components/ModalSuccess";

const Mission: React.FC<RouteComponentProps> = ({history}) => {
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();
    const [amount, set_amount] = useState(1)
    const [type, set_type] = useState("")
    const [error_message, set_error_message] = useState("")
    const [modal_address, set_modal_address] = useState(false)
    const [modal_success, set_modal_success] = useState(false)
    const [data_address, set_data_address] = useState("")
    const appContext = useContext(AppContext)

    useEffect(() => {
        getProfileBalance()
    }, [])

    const checkBalance = () => {
        let {balance} = appContext.profile.balance.find(b => b.symbol === "KGO")
        return parseFloat(balance || "0") > (amount * 200);
    }

    const checkAddress = () => {
        if (type == "2") {
            return !!data_address ? "" : "กรุณาเลือกที่อยู่ของท่าน"
        } else if (type == "") {
            return "กรุณาเลือกประเภทการปลูกต้นไม้"
        } else {
            return ""
        }
    }

    const getProfileBalance = async (type = 'user') => {

        const tempApiService = new ApiService();

        return tempApiService.getBalance(type).then((response: any) => {
            // console.log('res', response);
            if (response?.success) {
                const user = response?.data.user;
                let nProfile = null;
                if (user.profile) {
                    nProfile = Object.assign(user.profile);
                }
                const nUser = new UserProfile({
                    phoneNumber: user.phoneNumber,
                    balance: user.balance,
                    QrCodeUrl: user.qrcode,
                    profile: nProfile,
                    statusStore: user.statusStore,
                    status: user.status,
                    type: user.type,
                    voucher: user.voucher,
                    walletAddress: user.walletAddress,
                    rewards: user.rewards,
                    kgoItems: user.kgoItems,
                });
                appContext.setProfile(nUser);
                return true;
            }
        }).catch((e) => {
            return false;
        });
    }

    const minus = () => {
        let _amount = amount - 1
        if (_amount > 0) {
            set_amount(_amount)
        }
    }

    const plus = () => {
        let _amount = amount + 1
        if (_amount <= 10) {
            set_amount(_amount)
        }
    }

    const onChangeType = (e) => {
        set_type(e.target.value)
        if (!!error_message) {
            set_error_message("")
        }
    }

    const submit = () => {
        let status_balance = checkBalance()

        if (status_balance) {

            // Check validate type and address
            let status_address = checkAddress()
            if (!!status_address) {
                set_error_message(status_address)
            } else {
                presentAlert({
                    header: 'แจ้งเตือน',
                    subHeader: 'ยืนยันการปลูกต้นไม้',
                    buttons: [
                        {
                            text: 'ยกเลิก',
                            role: 'cancel',
                        },
                        {
                            text: 'ตกลง',
                            role: 'confirm',
                            handler: () => {

                                let data = {
                                    "amount": amount,
                                    "serviceType": type == "2" ? "self_service" : "admin_service",
                                    "shippingAddress": type == "2" ? data_address : {}
                                }

                                present({
                                    message: 'กำลังดำเนินรายการ',
                                })

                                const tempApiService = new ApiService();
                                tempApiService.haveATree(data).then(() => {
                                    dismiss()
                                    set_modal_success(true)
                                }).catch(() => {
                                    dismiss()
                                    presentAlert({
                                        header: 'แจ้งเตือน',
                                        subHeader: 'เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง',
                                        buttons: [
                                            {
                                                text: 'ตกลง',
                                                role: 'confirm',
                                            }
                                        ]
                                    })
                                })

                            },
                        },
                    ],
                })
            }

        } else {
            presentAlert({
                header: 'แจ้งเตือน',
                subHeader: 'จำนวน KGO Token ไม่เพียงพอ',
                buttons: ['ตกลง'],
            })
        }
    }

    return (
        <IonPage>

            {/*Header*/}
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>
                        เลือกต้นไม้
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            {/*Content*/}

            <IonContent className={'bg-secondary'}>
            <div className={styles.container}>


                <div className="app-container">
                    <div className={styles.contentGallery}>
                        <Gallery/>
                    </div>

                    <div className={styles.selectContent}>

                        <div className={styles.treeBackground}>
                            <img src={Tree3} alt={"tree background"}/>
                            <img src={Tree3} alt={"tree background"}/>
                            <img src={Tree3} alt={"tree background"}/>
                        </div>

                        <div className={styles.content}>

                            <div className={styles.topic}>จำนวนต้นไม้</div>

                            <div className={styles.amountContent}>

                                <IonIcon
                                    className={styles.remove}
                                    icon={removeCircleOutline}
                                    onClick={minus}
                                />

                                <div className={styles.amount}>
                                    {amount}
                                </div>

                                <IonIcon
                                    className={styles.add}
                                    icon={addCircleOutline}
                                    onClick={plus}
                                />

                            </div>

                            <div className={styles.detail}>KGO ที่ใช้</div>

                            <div className={styles.allPrice}>
                                {(amount * 200).toFixed(2)}
                                <div className={styles.unit}>KGO</div>
                            </div>

                            <IonSelect
                                id="have-a-tree-type"
                                interface="action-sheet"
                                placeholder="ประเภทการปลูก"
                                cancelText="ยกเลิก"
                                onIonChange={onChangeType}
                            >
                                <IonSelectOption value="1">ให้ทีมงานปลูกให้</IonSelectOption>
                                <IonSelectOption value="2">ปลูกด้วยตัวเอง</IonSelectOption>
                            </IonSelect>

                            {
                                type == "2" &&
                                <div className={styles.addAddress}
                                     onClick={() => set_modal_address(true)}>ที่อยู่ที่จัดส่ง</div>
                            }

                            {
                                !!error_message &&
                                <div className={styles.errorMessage}>{error_message}</div>
                            }

                            <div className={styles.breakPoint}/>

                            {/*<ion-item id={"receive-nft-content"}>*/}
                            {/*    <ion-checkbox id={"receive-nft"} slot="start"></ion-checkbox>*/}
                            {/*    <div>รับต้นไม้ NFT</div>*/}
                            {/*</ion-item>*/}

                            <button
                                className={styles.buttonSubmit}
                                disabled={false}
                                onClick={submit}
                            >ปลูกต้นไม้
                            </button>

                        </div>

                    </div>
                </div>

                <ModalAddress
                    isOpen={modal_address}
                    close={() => {
                        set_modal_address(false)
                    }}
                    data={data_address}
                    submitData={(data) => {
                        set_data_address(data)
                        set_modal_address(false)
                        set_error_message("")
                    }}
                />

                <ModalSuccess
                    isOpen={modal_success}
                    onConfirm={() => {
                        set_modal_success(false)
                    }}
                    onClose={() => {
                        history.goBack()
                    }}
                />

            </div>
            </IonContent>

        </IonPage>
    );
};

export default Mission;
