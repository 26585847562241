const API = "https://kgoapi.kgotoken.com/";
const accessToken = localStorage.getItem("accessToken");

// "/wallet/check-profile/" + phoneNumber
export const fetchProfile = async (phoneNumber: number) => {
  try {
    const response = await fetch(`${API}wallet/check-profile/${phoneNumber}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Optional: Add any additional headers if needed
      },
    });
    if (!response.ok) {
      return new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const checkPasswordCreated = async (phoneNumber: number) => {
  try {
    const response = await fetch(`${API}users/${phoneNumber}/check-password`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const loginWithPassword = async (payload: any) => {
  try {
    const response = await fetch(`${API}login/password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    //@ts-ignore
    if (response.headers.get("Content-Type").includes("application/json")) {
      const responseAPI = await response.json();
      return responseAPI;
      // Handle JSON response
    } else {
      // Handle non-JSON response (e.g., display HTML content)
      const accessToken = await response.text();
      return accessToken;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

export const updatePassword = async (phoneNumber: string, payload: any) => {
  console.log("accessToken", accessToken);
  if (!accessToken) throw new Error("Cannot get access token");
  try {
    const response = await fetch(`${API}users/${phoneNumber}/password`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
