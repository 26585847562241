import {
    IonContent,
    IonPage,
    IonRow, IonCol, IonText, IonRouterLink, IonIcon
} from '@ionic/react';
import React from "react";
import {arrowForwardOutline} from "ionicons/icons";

interface Props {
    closeModal: Function;
    isOpen: boolean;
    data: any;
}

export default function TransactionDetail(props: Props) {
    // const appContext = useContext(AppContext);

    // useEffect(() => {
    //     console.log('props.isOpen', props.isOpen);
    //     if (props.isOpen) {
    //         console.log(props.data);
    //     }
    // }, [props]);

    function closeModel() {
        props.closeModal(false);
    }
    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const convertStatusTh = (text: string) => {
        switch (text) {
            case 'pending':
                return (<IonText color={"primary"}>กำลังดำเนินการ</IonText>);
            case 'success':
                return (<IonText color={"success"}>สำเร็จ</IonText>);
            case 'error':
                return (<IonText color={"danger"}>ไม่สำเร็จ</IonText>);
            case '':
                return (<IonText color={"success"}>สำเร็จ</IonText>);
        }

        return text;
    }
    return (
        <IonPage>
            <IonContent>
                <IonRow className={"kgo-tran-detail"}>
                    <IonCol style={{textAlign: "center"}}> {props.data.name}</IonCol>
                </IonRow>
                <IonRow className={"kgo-tran-detail"}>
                    <IonCol size={"6"}>
                        สถานะ
                    </IonCol>
                    <IonCol size={"6"} style={{textAlign: "right", fontSize: 14}}>
                        <IonRouterLink target="_blank" href={`https://jfinscan.com/tx/` + props.data.hash}>
                            {`ดูข้อมูลการ${props.data.name}ใน Chain`}
                        </IonRouterLink>
                    </IonCol>
                </IonRow>
                <IonRow className={"kgo-tran-detail"}>
                    <IonCol size={"6"}>
                        {convertStatusTh(props.data?.transactionStatus)}
                    </IonCol>
                    <IonCol size={"6"} style={{textAlign: "right", fontSize: 14}}>
                        <IonText style={{cursor: "pointer"}} color={"primary"} onClick={(e: any) => {
                            navigator.clipboard.writeText(props.data.hash)
                        }}>คักลอก Transaction ID</IonText>
                    </IonCol>
                </IonRow>
                <IonRow className={"kgo-tran-detail"}>
                    <IonCol size={"6"}>
                        จาก
                    </IonCol>
                    <IonCol size={"6"} style={{textAlign: "right", fontSize: 14}}>
                        ถึง
                    </IonCol>
                </IonRow>
                <IonRow className={"kgo-tran-detail"} style={{paddingTop: 0}}>
                    <IonCol size={"5"} style={{display: "grid"}}>
                        <IonText>
                            {(props.data.profile && props.data.status == 'OUT' ? props.data.profile?.fromName : '')}
                            {(props.data.profile && props.data.status == 'IN' ? props.data.profile?.fromName : '')}

                        </IonText>
                        <IonText style={{color: "rgb(0,0,0,0.5)", marginLeft: 15, fontSize: 10, fontWeight: 400}}>
                            {props.data.address && props.data.status == 'OUT' ? props.data.address.substr(0, 4) + '...' + props.data.address.substr(-4) : ''}
                            {props.data.address && props.data.status == 'IN' ? props.data.targetAddress.substr(0, 4) + '...' + props.data.targetAddress.substr(-4) : ''}
                        </IonText>
                    </IonCol>
                    <IonCol size={"2"} style={{textAlign: "center", fontSize: 25}}>
                        <IonIcon icon={arrowForwardOutline}/></IonCol>
                    <IonCol size={"5"} style={{display: "grid", textAlign: "right"}}>
                        <IonText>
                            {(props.data.profile  && props.data.status == 'IN' ? props.data.profile?.toName : '')}
                            {(props.data.profile  && props.data.status == 'OUT'  ? props.data.profile?.toName : '')}
                        </IonText>
                        <IonText style={{color: "rgb(0,0,0,0.5)", marginRight: 15, fontSize: 10, fontWeight: 400}}>
                            {props.data.targetAddress  && props.data.status == 'OUT' ? props.data.targetAddress.substr(0, 4) + '...' + props.data.targetAddress.substr(-4) : ''}
                            {props.data.targetAddress  && props.data.status == 'IN' ? props.data.address.substr(0, 4) + '...' + props.data.targetAddress.substr(-4) : ''}
                            {/*{props.data.address ? props.data.address.substr(0, 4) + '...' + props.data.address.substr(-4) : ''}*/}
                        </IonText>
                    </IonCol>
                </IonRow>
                <IonRow className={"kgo-tran-detail"} style={{borderTop: "1pt solid rgba(0, 0, 0, 0.1)"}}>
                    <IonCol size={"6"}><IonText style={{color: "rgba(0,0,0,0.81)", fontSize: 22}}>จำนวน</IonText></IonCol>
                    <IonCol size={"6"} style={{textAlign: "right"}}>
                        <IonText style={{ fontSize: 24}}>{(props.data.amount) ? numberWithCommas(parseFloat(props.data.amount).toString()) : '0'}</IonText>
                        <IonText style={{color: " #848484"}}> KGO</IonText>
                    </IonCol>
                </IonRow>

                {(props.data.voucher) && (
                    <IonRow className={"kgo-tran-detail"} style={{borderTop: "1pt solid rgba(0, 0, 0, 0.1)"}}>
                        <IonCol size={"12"}>
                            รายละเอียด
                        </IonCol>
                        <IonCol size={"12"}>
                            Voucher {props.data.voucher.name} จำนวน {props.data.voucher.amount} KGO
                        </IonCol>
                        <IonCol size={"12"}>
                            จากร้าน {(props.data.status == 'IN') ? props.data.profile?.fromName : props.data.profile?.toName}
                        </IonCol>
                    </IonRow>
                )}
            </IonContent>
        </IonPage>
    );
};
