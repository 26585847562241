import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { toast } from "react-toastify";
import { arrowBackOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { redeemGame } from "../services/ikgo";
import AlertModal from "../components/Modal/AlertModal";
// @ts-ignore
import gameBanner from "../assets/game/game-code-button.jpg";

const GameRedeem = () => {
  const history = useHistory();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [code, setCode] = useState();
  const [modalData, setModalData] = useState({
    title: "เรียบร้อย",
    description: "ท่านได้รับสิทธิจำนวน 15 iKGO เรียบร้อยแล้ว กดเพื่อเริ่ม Spin",
    confirmBtn: () => history.push("/luckydraw"),
  });
  const handleSubmit = async () => {
    const payload = { code: code };
    setIsConfirmModal(true);
    // alert("ท่านได้รับสิทธิจำนวน 15 iKGO เรียบร้อยแล้ว กดเพื่อเริ่ม Spin");
    // call api
    // const res = await redeemGame(payload);
    // console.log("res", res);
    // toast.success(`บันทึกรหัสจากเกมสำเร็จ!`, {
    //   position: "bottom-center",
    // });
    // setTimeout(() => {
    //   history.push("/luckydraw");
    // }, 1500);
  };
  return (
    <IonPage>
      <AlertModal
        data={modalData}
        isOpen={isConfirmModal}
        setIsOpen={() => setIsConfirmModal(false)}
        onClickHandle={() => history.push("/luckydraw")}
      />
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/luckydraw"
            />
          </IonButtons>
          <IonTitle>แลกโค้ดจากเกม</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <img
            src={gameBanner}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <div
            style={{
              padding: 16,
              width: "100%",
              display: "column",
            }}
          >
            <p style={{ margin: 0 }}>กรอกรหัสจากเกมเพื่อรับเหรียญ iKGO</p>
            <div style={{ padding: "16px 0" }}>
              <p style={{ margin: 0 }}>รหัสจากเกม</p>
              <input
                defaultValue={code}
                style={{
                  backgroundColor: "transparent",
                  width: "100%",
                  border: 0,
                  borderBottomWidth: 0.5,
                  borderStyle: "solid",
                  borderColor: "lightgray",
                }}
                required
                placeholder="กรุณากรอกรหัส"
                type="text"
                // @ts-ignore
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <button
              style={{
                backgroundColor:
                  // @ts-ignore
                  code?.length > 8 ? "orange" : "rgba(0,0,0,0.25)",
                padding: "12px 0",
                borderRadius: 8,
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 16,
              }}
              type="submit"
              onClick={handleSubmit}
            >
              บักทึก
            </button>
          </div>
          <div style={{ padding: 16 }}>
            <p style={{ margin: 0 }}>เงื่อนไขการกรอกโค้ดจากเกมเพื่อรับรางวัล</p>
            <p style={{ margin: 0, color: "gray" }}>
              โค้ดจากเกมสามารถแลกได้แค่ 1 Code / 1 ผู้ใช้งานเท่านั้น
            </p>

            {/* <p style={{ margin: 0, color: "gray" }}>
              ทีมงาน KGO ขอสงวนสิทธิ์ในการเปลี่ยนแปลงของรางวัลโดยไม่แจ้งให้ทราบล่วงหน้า
            </p> */}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GameRedeem;
