import { useParams } from "react-router-dom";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
  useIonAlert,
  IonImg,
  IonFooter,
  IonAlert,
  IonItem,
  IonList,
  IonInput,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../context/state";
// @ts-ignore
import kgoNFT from "../../assets/kgo-nft.png";
// @ts-ignore
import keyImg from "../../assets/key.png";
// @ts-ignore
import dollar from "../../assets/coin_kgo1.svg";
// @ts-ignore
import dollar2 from "../../assets/dollar.png";
import FullForm from "../../components/Delivery/FullForm";
import { fetchPrizeById, patchPrizeItemById } from "../../services/voucher";

const PrizeSingle: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const param = useParams();
  const [present] = useIonAlert();
  const [showLoading, setShowLoading] = useState(false);
  const [prize, setPrize] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [modeOfDeliver, setModeOfDeliver] = useState();
  // @ts-ignore
  const prizeId = param?.prizeId;
  //   information states
  const [phoneNumber, setPhoneNumber] = useState();
  const [timeContact, setTimeContact] = useState();

  const handleSelectMode = (mode: any) => {
    setModeOfDeliver(mode);
  };
  const getPrizeById = async () => {
    // @ts-ignore
    const prize = await fetchPrizeById(prizeId);
    setPrize(prize);
  };
  const setIsEditHandle = (value: boolean) => {
    setIsEdit(value);
  };
  const updatePrizeStatus = async (payload: any, shortForm?: boolean) => {
    const res = await patchPrizeItemById(prizeId, payload, shortForm);
    if (res?._id) {
      alert(
        "ได้รับข้อมูลของท่านเรียบร้อย กรุณารอเจ้าหน้าที่ติดต่อกลับในเวลาทำการภายใน 7 วัน"
      );
      history.push("/myprize");
    } else {
      alert(
        "error! มีบางอย่างผิดพลาด กรุณาติดต่อ admin (Error Status:" +
          res?.statusCode +
          ")"
      );
      // history.push("/myvoucher");
    }
  };
  const handleSubmitPickUp = async (e: any) => {
    e.preventDefault();
    const shortForm = true;
    const formData = new FormData();

    // Append each key-value pair from payload to the FormData object
    // @ts-ignore
    formData.append("phoneNumber", phoneNumber);
    // @ts-ignore
    formData.append("timeForContact", timeContact);
    formData.append("isPickUp", "true");

    await updatePrizeStatus(formData, shortForm);
  };
  const handleSubmitFullForm = async (payload: any) => {
    await updatePrizeStatus(payload);
  };

  useEffect(() => {
    // @ts-ignore
    if (prizeId) {
      getPrizeById();
    }
  }, []);
  useEffect(() => {
    // @ts-ignore
    if (phoneNumber?.length === 10 && timeContact) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [phoneNumber, timeContact]);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // {}
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/myprize"
            />
          </IonButtons>
          <IonTitle>ยืนยันการรับของรางวัล</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div style={{ marginTop: 8, paddingLeft: 12, paddingRight: 12 }}>
            <IonRow style={{ paddingTop: 0, marginTop: 10 }}>
              <IonCol size={"12"}>
                <IonText style={{ fontWeight: 700, fontSize: 16 }}>
                  {/* @ts-ignore */}
                  กรอกข้อมูลเพื่อรับของรางวัล {prize?.name}
                </IonText>
              </IonCol>
            </IonRow>
            {/* for deliver */}
            <div>
              <p>เลือกรูปแบบการจัดส่ง</p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  style={{
                    backgroundColor:
                      modeOfDeliver == "pick-up"
                        ? "orange"
                        : "rgba(0,0,0,0.25)",
                    padding: "8px 0",
                    borderRadius: 8,
                    width: 188,
                    flexDirection: "row",
                    justifyContent: "center",
                    color: "white",
                  }}
                  onClick={() => handleSelectMode("pick-up")}
                >
                  มารับเอง
                </button>
                <button
                  style={{
                    backgroundColor:
                      modeOfDeliver == "deliver"
                        ? "orange"
                        : "rgba(0,0,0,0.25)",
                    padding: "8px 0",
                    borderRadius: 8,
                    width: 188,
                    flexDirection: "row",
                    justifyContent: "center",
                    color: "white",
                  }}
                  onClick={() => handleSelectMode("deliver")}
                >
                  จัดส่ง
                </button>
              </div>
              {modeOfDeliver == "pick-up" ? (
                <div>
                  <p>กรุณากรอกข้อมูลการติดต่อเพื่อตกลงเวลากับทางเจ้าหน้าที่</p>
                  <form
                    onSubmit={handleSubmitPickUp}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 16,
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        เบอร์โทรศัพท์ <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        style={{
                          backgroundColor: "transparent",
                          border: 0,
                          borderBottomWidth: 0.5,
                          borderStyle: "solid",
                          borderColor: "lightgray",
                        }}
                        required
                        placeholder="กรุณาระบุเบอร์โทรศัพท์"
                        type="tel"
                        // @ts-ignore
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>ช่วงเวลาที่สะดวกให้ติดต่อกลับ</label>
                      <select
                        style={{
                          backgroundColor: "transparent",
                          border: 0,
                          borderBottomWidth: 0.5,
                          borderStyle: "solid",
                          borderColor: "lightgray",
                        }}
                        // @ts-ignore
                        onChange={(e) => setTimeContact(e.target.value)}
                      >
                        <option>เลือกช่วงเวลาที่สะดวกให้ Admin ติดต่อ</option>
                        <option value={"09:00-10:00"}>09:00-10:00</option>
                        <option value={"10:00-11:00"}>10:00-11:00</option>
                        <option value={"11:00-12:00"}>11:00-12:00</option>
                        <option value={"13:00-14:00"}>13:00-14:00</option>
                        <option value={"14:00-15:00"}>14:00-15:00</option>
                        <option value={"15:00-16:00"}>15:00-16:00</option>
                        <option value={"16:00-17:00"}>16:00-17:00</option>
                        <option value={"17:00-18:00"}>17:00-18:00</option>
                      </select>
                    </div>
                    <button
                      style={{
                        backgroundColor: isReady
                          ? "orange"
                          : "rgba(0,0,0,0.25)",
                        padding: "12px 0",
                        borderRadius: 8,
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 16,
                      }}
                      type="submit"
                    >
                      บักทึก
                    </button>
                  </form>
                </div>
              ) : (
                modeOfDeliver == "deliver" && (
                  <div>
                    <FullForm
                      // @ts-ignore
                      handleSubmitFullForm={handleSubmitFullForm}
                      // @ts-ignore
                      checkEdited={setIsEditHandle}
                      appContext={appContext}
                    />
                  </div>
                )
              )}
            </div>
          </div>

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PrizeSingle;
