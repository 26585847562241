import React, {useMemo} from 'react';
import Tree5 from "../../../../assets/tree/tree-05.png";
import Tree4 from "../../../../assets/tree/tree-04.png";
import styles from "./TreeItem.module.css"

export default ({topic, detail, onClick, item}) => {

    const date_thai = useMemo(() => new Date(item?.createdAt || new Date).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }), [item]);

    return (
        <div className={styles.container} onClick={onClick}>

            <div className={styles.contentImage}>
                <img className={styles.tree01} src={Tree5} alt={"tree-01"}/>
                <img className={styles.tree02} src={Tree4} alt={"tree-02"}/>
            </div>

            <div className={styles.content}>
                <div className={styles.date}>วันที่ {date_thai}</div>
                <div className={styles.topic}>จำนวน <span className={styles.amount}>{item.amount}</span> ต้น</div>
                <div className={styles.detail}><span className={styles.highlight}>{item.amount * 200}</span> KGO Token
                </div>
            </div>

        </div>
    )
}
