import {
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonGrid,
  IonProgressBar,
  useIonAlert,
  IonCol,
  IonText,
  IonRow,
  IonIcon,
  IonLabel,
  IonAlert,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { arrowForwardOutline } from "ionicons/icons";
import {
  checkPasswordCreated,
  loginWithPassword,
  updatePassword,
} from "../services/user";

const PasswordCreate: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const inputRef = useRef<any>(null);
  const [referral, setReferral] = useState("");
  const [showReferral, setShowReferral] = useState(false);
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [runningProcess, setRunningProcess] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const accessToken = localStorage.getItem("accessToken");
  const phoneNumberLocal = localStorage.getItem("phoneNumber");
  //   console.log("appContext", appContext?.profile?.phoneNumber);

  const createPassword = async () => {
    const payload = { password: confirmPassword };
    const phoneNumber = phoneNumberLocal;
    const res = await updatePassword(phoneNumber, payload);
    if (res.status < 299) {
      history.push("/main");
    } else {
      alert("เกิดข้อผิดพลาด" + res.status);
    }
  };

  const checkPassword = async () => {
    const res = await checkPasswordCreated(phoneNumberLocal);
    if (res) {
      return history.push("/main");
    }
  };

  useEffect(() => {
    if (phoneNumberLocal) {
      checkPassword();
    }
  }, []);
  useEffect(() => {
    if (!accessToken) return history.push("/login");
    if (phoneNumberLocal && confirmPassword && password !== confirmPassword) {
      setErrorPassword(true);
      setDisableBtn(true);
    } else {
      setErrorPassword(false);
      setDisableBtn(false);
    }
  }, [password, confirmPassword]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={"app-container"}>
          <IonGrid>
            <IonRow className="ion-text-center">
              <IonCol size={"12"}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/icon/KGO-Logo.png"}
                  style={{ maxHeight: 230, maxWidth: 94, marginTop: 80 }}
                />
              </IonCol>

              <IonCol
                size={"12"}
                style={{ marginTop: 27, fontSize: 18, lineHeight: "27px" }}
              >
                <IonText>ตั้งรหัสผ่านสำหรับเข้าสู่ระบบ</IonText>
              </IonCol>
            </IonRow>
            <IonRow className="ion-text-center" style={{ marginTop: 28 }}>
              <IonCol sizeMd={"6"} offsetMd={"3"} sizeXs={"10"} offsetXs={"1"}>
                <IonLabel
                  position="stacked"
                  style={{ marginBottom: "10px", textAlign: "center" }}
                >
                  ตั้งรหัสผ่านของคุณ{" "}
                </IonLabel>
                <IonInput
                  ref={(ref) => (inputRef.current = ref)}
                  type="password"
                  placeholder="กรอกรหัสผ่าน"
                  style={{ fontSize: "1em" }}
                  className={"kgo-input-light"}
                  value={password}
                  maxlength={50}
                  onIonChange={(e) => {
                    setPassword(e.detail.value!);
                  }}
                />
                <IonLabel
                  position="stacked"
                  style={{ marginBottom: "10px", textAlign: "center" }}
                >
                  ยืนยันรหัสผ่าน{" "}
                </IonLabel>
                <IonInput
                  ref={(ref) => (inputRef.current = ref)}
                  type="password"
                  placeholder="กรอกรหัสผ่านอีกครั้ง"
                  style={{ fontSize: "1em" }}
                  className={"kgo-input-light"}
                  value={confirmPassword}
                  maxlength={50}
                  onIonChange={(e) => {
                    setConfirmPassword(e.detail.value!);
                  }}
                />
                {errorPassword && (
                  <p
                    style={{ color: "red", paddingBottom: 0, marginBottom: 0 }}
                  >
                    รหัสผ่านไม่ตรงกัน
                  </p>
                )}
                <button
                  onClick={() => history.push("/main")}
                  style={{ marginTop: 16 }}
                >
                  ข้ามการตั้งค่ารหัสผ่าน
                </button>
                <IonRow>
                  <IonCol style={{ padding: "25px 0" }}>
                    <IonButton
                      className={"kgo-btn"}
                      expand={"block"}
                      strong={true}
                      disabled={disableBtn}
                      onClick={createPassword}
                    >
                      ถัดไป <IonIcon slot="end" icon={arrowForwardOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
        </div>
      </IonContent>
      <IonAlert
        isOpen={showReferral}
        onDidDismiss={() => setShowReferral(false)}
        cssClass="my-custom-class"
        header={"ระบุผู้แนะนำ"}
        // header={'Prompt!'}
        inputs={[
          {
            name: "refCode",
            id: "refCode",
            type: "tel",
            placeholder: "เบอร์โทรผู้แนะนำ",
            value: referral,
            max: 10,
          },
        ]}
        buttons={[
          {
            text: "ปิด",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "ยืนยัน",
            handler: (data) => {
              console.log("Confirm Ok", data);
              if (data?.refCode) {
                setReferral(data.refCode);
                localStorage.setItem("referral", data.refCode);
              } else {
                setReferral("");
                localStorage.setItem("referral", "");
              }
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default PasswordCreate;
