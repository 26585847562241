import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonText,
  IonToolbar,
  useIonAlert,
  IonImg,
  IonFooter,
  IonAlert,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { arrowBackOutline, filter } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
// @ts-ignore
import kgoNFT from "../assets/kgo-nft.png";
// @ts-ignore
import keyImg from "../assets/key.png";
// @ts-ignore
import dollar from "../assets/coin_kgo1.svg";
// @ts-ignore
import dollar2 from "../assets/dollar.png";
import RewardCards from "../components/Cards/RewardCards";
import {
  fetchUserPrizeItem,
  fetchUserVoucher,
  fetchUserVoucherById,
} from "../services/voucher/index";
import { redeemVoucherData } from "../utils/MockData";
import { fetchProfile } from "../services/user";

const MyPrize: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [present] = useIonAlert();
  const [showLoading, setShowLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [confirmClaim, setConfirmClaim] = useState(false);
  const [confirmCancelClaim, setConfirmCancelClaim] = useState(false);
  const [myPrize, setMyPrize] = useState([]);
  const [limit, setLimit] = useState(5);
  const [userId, setUserId] = useState();
  const [totalItems, setTotalItems] = useState(0);

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const fetchTheProfile = async (phone) => {
    const profile = await fetchProfile(phone);
    setUserId(profile.data.id);
  };

  const getUserVoucher = async () => {
    setShowLoading(true);
    const data = await fetchUserPrizeItem(userId, 1, 999);
    setShowLoading(false);
    setMyPrize(data);
    setTotalItems(data.length);
  };

  useEffect(() => {
    // fetchTheProfile();
    if (appContext.profile) {
      // @ts-ignore
      setPhoneNumber(appContext.profile.phoneNumber);
    }
  }, [appContext]);

  useEffect(() => {
    fetchTheProfile(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (userId) {
      getUserVoucher();
    }
  }, [userId]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/luckydraw"
            />
          </IonButtons>
          <IonTitle>รายการของรางวัลของฉัน</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: 8,
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <IonText style={{ fontSize: 14 }}>
              {/* @ts-ignore */}
              ทั้งหมด: {myPrize?.length ?? 0}
            </IonText>
            <IonText
              onClick={() => history.push("/prize/history")}
              style={{ fontSize: 14, color: "gray" }}
            >
              ประวัติการรับของรางวัล
            </IonText>
          </div>
          <p style={{ padding: 16, margin: 0, color: "gray" }}>
            ยืนยันการรับสิทธิ์ภายใน 14 วัน นับจากวันที่ได้รับรางวัล
          </p>
          {myPrize && (
            <div
              style={{
                overflowY: "scroll",
                padding: 16,
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {myPrize?.length > 0 ? (
                myPrize
                  .filter((d, i) => i < limit)
                  .map((voucher) => (
                    // @ts-ignore
                    <>
                      <RewardCards
                        itemId={voucher._id}
                        data={voucher.prizeDetails}
                        isClaimed
                      />
                    </>
                  ))
              ) : (
                <p style={{ marginTop: 42, fontSize: 26, alignSelf: "center" }}>
                  ไม่พบข้อมูลของรางวัล...
                </p>
              )}
              {myPrize && limit < totalItems && (
                <button
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    border: "solid 1px lightgray",
                    backgroundColor: "transparent",
                    color: "gray",
                  }}
                  onClick={() => setLimit(limit + 5)}
                >
                  {" "}
                  โหลดข้อมูลเพิ่มเติม{" "}
                </button>
              )}
            </div>
          )}

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyPrize;
