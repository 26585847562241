import {
    IonButton, IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    IonBackButton, IonIcon, IonLoading, useIonAlert, IonProgressBar, IonCard, IonCardContent, IonGrid, IonText, IonInput
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useState} from "react";
import '../theme/pin.css'
import {useHistory} from "react-router-dom";

export default function PinCode() {
    const appContext = useContext(AppContext)
    const history = useHistory();
    const [textPin, setTextPin] = useState({textBar: "กำหนดรหัส PIN",text : "กรุณากำหนดรหัส PIN", color: "dark"})
    const [pinInput, setPinInput] = useState("")
    const [pin, setPin] = useState("")
    const [pinMatch, setPinMatch] = useState("")

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if(pin.length == 6 && pinMatch.length == 0){
            setTextPin((prev) => ({
                color: "dark",
                text: "ยืนยันรหัส PIN อีกครั้ง",
                textBar: "กำหนดรหัส PIN อีกครั้ง"
            }))
            setPinInput("");
        }

        if(pin.length == 6 && pinMatch.length == 6){
            //match
            if(pin === pinMatch){
                localStorage.setItem('pinCode', pinMatch);
                history.push('/main');
            }else{
                setTextPin({
                    text: "รหัส PIN ไม่ตรงกัน",
                    color: "danger",
                    textBar: "กำหนดรหัส PIN อีกครั้ง"
                });

                setPin("");
                setPinMatch("");
                setPinInput("");
            }
        }

        console.log('length',pin.length, pin, pinMatch.length, pinMatch)
    }, [pin, pinMatch]);

    function addKey(key: string){
        if(pin.length !== 6){
            let tempPin = pin;
            if(key == 'del'){
                tempPin = tempPin.slice(0, -1);
            }else if(tempPin.length <= 6){
                tempPin = tempPin + key;
            }

            setPin(tempPin);
            setPinInput(tempPin);
        }else if(pin.length == 6 && pinMatch.length !== 6){
            let tempPin = pinMatch;
            if(key == 'del'){
                tempPin = tempPin.slice(0, -1);
            }else if(tempPin.length <= 6){
                tempPin = tempPin + key;
            }

            setPinMatch(tempPin);
            setPinInput(tempPin);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar"}>
                        <IonTitle>{textPin.textBar}</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonCard>
                        <IonCardContent>
                            <IonGrid style={{marginTop:"auto"}}>
                                <IonRow className={"ion-text-center"}>
                                    <IonCol size="12">
                                        <img src={process.env.PUBLIC_URL + '/assets/icon/KGO-Logo.png'} style={{maxHeight:115}} />
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonText style={{fontSize:16}} color={textPin.color}>{textPin.text}</IonText>
                                    </IonCol>
                                </IonRow>

                                <IonRow className={"ion-text-center"}>
                                    <IonCol size="12">
                                        <IonInput type="password" value={pinInput} maxlength={6} disabled className={"pin-text"}/>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("1") }}>
                                        1
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("2") }}>
                                        2
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("3") }}>
                                        3
                                    </IonButton>
                                </IonRow>
                                <IonRow className={"pin-row"}>
                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("4") }}>
                                        4
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("5") }}>
                                        5
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("6") }}>
                                        6
                                    </IonButton>
                                </IonRow>
                                <IonRow className={"pin-row"}>
                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("7") }}>
                                        7
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("8") }}>
                                        8
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("9") }}>
                                        9
                                    </IonButton>
                                </IonRow>
                                <IonRow className={"pin-row"}>
                                    <IonButton className={"pin-num-pad-blank"}>

                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("0") }}>
                                        0
                                    </IonButton>

                                    <IonButton className={"pin-num-pad"} onClick={()=>{ addKey("del") }}>
                                        {"<"}
                                    </IonButton>
                                </IonRow>
                            </IonGrid>
                        </IonCardContent>
                    </IonCard>
                </div>
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Loading...'}
                />
            </IonContent>
        </IonPage>
    );
};

// export default TermService;
