import React, {useState} from 'react';
import styles from './HistorySearch.module.css'
import DatePicker from "react-multi-date-picker";
import DatepickerConfig from "../Config/DatepickerConfig";
import {IonIcon} from "@ionic/react";
import {calendar, close, search} from "ionicons/icons";
import {DateTime} from "luxon";

export default ({onSearch, onClear}) => {
    const [value_date, setValue] = useState<any>([]);

    return (
        <div className={styles.container}>

            <DatePicker
                style={{flex: 1}}
                range
                value={value_date}
                onChange={setValue}
                calendar={DatepickerConfig.calendar}
                locale={DatepickerConfig.locale}
                render={(value, openCalendar) => {

                    let [_start, _end] = value
                    let date_start, date_end

                    if (_start) {
                        let [year, month, day] = _start.split('/')
                        date_start = {year, month, day}
                    }

                    if (_end) {
                        let [year, month, day] = _end.split('/')
                        date_end = {year, month, day}
                    }


                    return (
                        <div className={styles.contentSearch} onClick={openCalendar}>
                            <IonIcon icon={calendar} className={styles.icon}/>
                            {value.length > 0 ?
                                <>
                                    {
                                        date_start &&
                                        `${parseInt(date_start?.day)} ${date_start?.month && DatepickerConfig.locale.months[parseInt(date_start?.month) - 1][1]} ${date_start?.year.slice(-2)}`
                                    }

                                    {
                                        date_end &&
                                        ` - ${parseInt(date_end?.day)} ${date_end?.month && DatepickerConfig.locale.months[parseInt(date_end?.month) - 1][1]} ${date_end?.year.slice(-2)}`
                                    }
                                </>
                                :
                                "ค้นหา..."
                            }

                            {
                                value.length > 0 &&
                                <IonIcon
                                    icon={close}
                                    className={styles.closeIcon}
                                    onClick={() => {
                                        setValue([])
                                        onClear()
                                    }}
                                />
                            }

                        </div>
                    )
                }}
            />

            <div className={styles.searchButton} onClick={() => {
                onSearch(value_date)
            }}>
                <IonIcon icon={search} className={styles.searchIcon}/>
            </div>

        </div>
    )
}
