const API = "https://kgoapi.kgotoken.com/";
// http://159.89.211.110
// const accessToken =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NjFiOWM3NDhhYjYxZjAwMjA3MWMxOTMiLCJ0eXBlIjoidXNlciIsImxvZ2luQXQiOjE3MTM0NDc4MDYyMzIsImlhdCI6MTcxMzQ0NzgwNiwiZXhwIjoxNzE2MDM5ODA2fQ.hslN6Zd4RpbIgWruHaXZIOhAs9dMNAThDgv0KLPXTvY";
const accessToken = localStorage.getItem("accessToken");

export const fetchUserVoucher = async () => {
  try {
    const response = await fetch(`${API}user-vouchers`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Optional: Add any additional headers if needed
      },
    });
    if (!response.ok) {
      return new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const fetchUserPrizeItem = async (
  userId: any,
  page: number,
  limit: number
) => {
  try {
    const response = await fetch(
      `${API}prizes/items/user/${userId}?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const fetchUserPrizeItemHistory = async (userId: any) => {
  try {
    const response = await fetch(
      `${API}prizes/items/history/user/${userId}?page=1&limit=9999`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const fetchPrizeById = async (prizeId: any) => {
  try {
    const response = await fetch(`${API}prizes/${prizeId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Optional: Add any additional headers if needed
      },
    });
    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};
export const patchPrizeItemById = async (
  prizeId: any,
  payload: any,
  shortForm: boolean
) => {
  try {
    const response = await fetch(`${API}prizes/items/${prizeId}`, {
      method: "PATCH", // Specify PATCH method
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: payload, // Include the data to be updated
    });
    if (!response.ok) {
      return response.json();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

// get voucher by userId
// 661b9c748ab61f002071c193
