import React from 'react';
import Tree1 from "../../../../assets/tree/tree-01.png";
import Tree2 from "../../../../assets/tree/tree-02.png";
import Building from "../../../../assets/tree/building.png";
import styles from "./MenuTree.module.css"
import {IonIcon} from "@ionic/react";
import {alertCircleOutline ,arrowForwardCircle} from "ionicons/icons";

export default ({topic,detail,image1,image2,onClick}) => {
    return (
        <div className={styles.container} onClick={onClick}>

            <div className={styles.contentImage}>
                <img className={styles.tree01} src={image1 || Tree1} alt={"tree-01"}/>
                <img className={styles.tree02} src={image2 || Tree2} alt={"tree-02"}/>
            </div>

            <div className={styles.content}>
                <div className={styles.topic}>{topic}</div>
                <div className={styles.detail}>{detail}</div>
            </div>

        </div>
    )
}
