import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
// @ts-ignore
import slot from "../../assets/slot/slot-image.png";
const Slot = ({
  spin,
  rewards,
  balance,
}: {
  spin: () => void;
  balance: number;
  rewards: any;
}) => {
  const [fruit1, setFruit1] = useState("🍒");
  const [fruit2, setFruit2] = useState("🍒");
  const [fruit3, setFruit3] = useState("🍒");
  const [rolling, setRolling] = useState(false);
  let slotRef = [useRef(null), useRef(null), useRef(null)];
  const fruits = [...rewards, ...rewards, ...rewards, ...rewards, ...rewards];

  const handleRoll = () => {
    makeSlotToFirst();
  };

  // to trigger roolling and maintain state
  const makeSlotToFirst = () => {
    // setFruit1(fruits[0]);
    slotRef.forEach((ref) => {
      triggerSlotRotationZero(ref.current);
    });

    setRolling(true);
  };
  useEffect(() => {
    if (rolling) {
      setTimeout(() => {
        roll();
      }, 150);
    }
  }, [rolling]);
  const roll = async () => {
    const response = await  spin();
    const indexPrize = rewards.findIndex(item => item._id === response.data.prize._id);
    let randomOption =
      rewards.length * 4 + indexPrize;
    // console.log(slotRef, "slotRef");
    slotRef.forEach((slot, i) => {
      // this will trigger rolling effect
      const selected = triggerSlotRotation(slot.current, randomOption, 4 + i);
      if (i + 1 == 1) setFruit1(selected);
      else if (i + 1 == 2) setFruit2(selected);
      else {
        setFruit3(selected);
        setTimeout(() => {
          setRolling(false);
        }, 3000);
      }
    });
  };

  // this will create a rolling effect and return random selected option
  //   @ts-ignore

  // const triggerSlotRotation = (ref) => {
  //   //   @ts-ignore
  //   function setTop(top) {
  //     ref.style.top = `${top}px`;
  //   }
  //   let options = ref.children;
  //   let randomOption = Math.floor(Math.random() * fruits.length);
  //   let choosenOption = options[randomOption];
  //   setTop(-choosenOption.offsetTop + 2);
  //   return fruits[randomOption];
  // };
  const triggerSlotRotationZero = (ref) => {
    function setTop(top) {
      ref.style.top = `${top}px`;
    }
    setTop(0);
  };
  const triggerSlotRotation = (ref, label, speed) => {
    function setTop(top) {
      ref.style.top = `${top}px`;
    }
    setTop(0);
    // Randomize speed for smoother animation
    // const speed = Math.floor(Math.random() * 10) + 5; // Random speed between 5 and 14 milliseconds
    // const speed = 10; // Random speed between 5 and 14 milliseconds

    console.log(speed, "speed");
    const options = ref.children;
    // let randomOption = Math.floor(Math.random() * fruits.length);
    let randomOption = label;

    let initialIndex = 0;
    const chosenOption = options[randomOption];
    const finalPosition = -chosenOption.offsetTop + 2;

    // Adjust randomOption to start from index 0
    randomOption = (randomOption + initialIndex) % fruits.length;

    let currentPosition = 0;
    let timer = setInterval(() => {
      setTop(currentPosition);
      currentPosition -= 5; // Move up 5 pixels each interval (adjust as needed for speed)
      if (currentPosition <= finalPosition) {
        clearInterval(timer);
        setTop(finalPosition);
      }
    }, speed);

    return fruits[randomOption];
  };

  return (
    <div
      id="slot-container"
      style={{ position: "relative", width: 440, height: 440 }}
      className="SlotMachine"
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 218,
            right: 136,
            zIndex: 0,
            height: 80,
            overflow: "hidden",
          }}
        >
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[0]}>
                {fruits.map((fruit, index) => (
                  <div key={String(fruit.id) + index}>
                    <span>
                      <img
                        style={{
                          objectFit: "cover",
                          width: 48,
                          marginRight: 4,
                          height: 60,
                        }}
                        src={fruit.imgUrl}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[1]}>
                {fruits.map((fruit, index) => (
                  <div key={String(fruit.id) + index}>
                    <span>
                      <img
                        style={{ objectFit: "cover", width: 48, height: 60 }}
                        src={fruit.imgUrl}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="slot">
            <section>
              <div className="container" ref={slotRef[2]}>
                {fruits.map((fruit, index) => (
                  <div key={String(fruit.id) + index}>
                    <span>
                      {/* {console.log("friut", fruit)} */}
                      <img
                        style={{
                          objectFit: "cover",
                          width: 48,
                          marginLeft: 8,
                          height: 60,
                        }}
                        src={fruit.imgUrl}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>

      <button
        style={{
          position: "absolute",
          zIndex: 99,
          bottom: 73.7,
          left: 118,
          backgroundColor: balance < 1 ? "gray" : "orange",
          fontSize: 16.3,
          fontWeight: "bold",
          color: "white",
          padding: "10px 18px",
          borderRadius: 8,
          width: 200,
        }}
        //   @ts-ignore
        onClick={handleRoll}
        // use 100 ikgo for a spin
        disabled={rolling || balance < 1}
      >
        {rolling ? "กำลังสุ่มรางวัล..." : "สุ่มรางวัล 1 iKGO"}
      </button>
      <img src={slot} style={{ zIndex: 99 }} />
    </div>
  );
};

export default Slot;
