import React, {useRef} from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import styles from './ModalBank.module.css'
import Bank from "../../../../assets/esan/bank.png";
import {informationCircleOutline,copyOutline} from "ionicons/icons";
import classNames from "classnames";
import * as copy from 'copy-to-clipboard';

export default ({isOpen,title,subTitle, close,onConfirm,onClose,...props}) => {

    const modal = useRef<HTMLIonModalElement>();

    const copyToClipboard = () => {
        copy('138-1-57625-2');
    }

    return (
        <div className={styles.container}>
            <IonModal
                id="modal-detail-tree"
                ref={modal}
                trigger="open-modal"
                isOpen={isOpen}
                initialBreakpoint={1}
                onDidDismiss={onClose}
            >
                <div style={{flex: 1}} onClick={onClose}/>

                <div className={styles.modalContent}>

                    <div className={styles.content}>

                        <img src={Bank} className={styles.bankImage} />

                        <div className={styles.infoContent}>
                            <div className={styles.infoTitle}>ชื่อบัญชี</div>
                            <div className={styles.infoName}>บจก. อินฟินิทแลนด์ โทเคน</div>
                        </div>


                        <div className={classNames([styles.infoContent,styles.infoContentNumber])} onClick={copyToClipboard}>
                            <div className={styles.infoTitle}>เลขที่บัญชี</div>
                            <div className={styles.infoName} style={{color:"#232323"}}>138-1-57625-2</div>
                            <div className={styles.copyContent}>
                                <div className={styles.infoTitle}>คัดลอก</div>
                                <IonIcon
                                    className={styles.copy}
                                    icon={copyOutline}
                                />
                            </div>
                        </div>

                    </div>

                </div>

                <div style={{flex: 1}} onClick={onClose}/>
            </IonModal>
        </div>
    )
}
