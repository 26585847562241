import React, {useRef} from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import styles from './ModalSuccess.module.css'
import Tree6 from "../../../../assets/tree/tree-06.png";
import Tree3 from "../../../../assets/tree/tree-03.png";
import Gallery from "../Gallery";
import Thumbnail from "../Thumbnail";
import Preview01 from "../../../../assets/tree/preview-01.jpeg";
import Preview02 from "../../../../assets/tree/preview-02.jpeg";
import Preview03 from "../../../../assets/tree/preview-03.png";
import Preview04 from "../../../../assets/tree/preview-04.jpeg";

export default ({isOpen, close,onConfirm,onClose,...props}) => {

    const modal = useRef<HTMLIonModalElement>();

    return (
        <div className={styles.container}>
            <IonModal
                id="modal-detail-tree"
                ref={modal}
                trigger="open-modal"
                isOpen={isOpen}
                initialBreakpoint={1}
                onDidDismiss={onClose}
            >
                <div style={{flex: 1}}/>

                <div className={styles.modalContent}>

                    <div className={styles.imageContent}>
                        <img className={styles.tree01} src={Tree6} alt={"tree-01"}/>
                        <img className={styles.tree02} src={Tree3} alt={"tree-01"}/>
                    </div>

                    <div className={styles.content}>

                        <div className={styles.topic}>ยินดีด้วย</div>
                        <div className={styles.detail}>ดำเนินรายการปลูกต้นไม้สำเร็จ</div>

                        <div className={styles.subDetail}>
                            สามารถตรวจสอบ NFT ได้ที่<br />
                            คลัง NFT ของท่าน
                        </div>

                        <button
                            className={styles.buttonSubmit}
                            onClick={onConfirm}
                        >ยืนยัน</button>

                    </div>

                </div>

                <div style={{flex: 1}}/>
            </IonModal>
        </div>
    )
}
