import axios from "axios";
import setting from "../setting.json";
export let urlHeader = "https://kgoapi.kgotoken.com";
// export let urlHeader = "https://kgoapi.kgotoken.com/";
console.log("urlHeader", urlHeader);
const axiosInstance = axios.create({
  baseURL: urlHeader,
});

export class ApiService {
  accessToken: string | null = "";
  constructor() {
    this.accessToken = localStorage.getItem("accessToken");
    axiosInstance.interceptors.request.use(function (config) {
      let accessToken = localStorage.getItem("accessToken");
      // @ts-ignore
      config.headers.Authorization = "Bearer " + accessToken;
      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        console.error("interceptors err", err);
        if (err.constructor.name == "Cancel") {
          throw new Error("Request Cancel");
        }
        return new Promise((resolve, reject) => {
          const originalReq = err.config;
          if (
            err.response != null &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            originalReq._retry = true;

            reject("token expire.");
            localStorage.clear();
            window.location.href = "/";

            // let res = axios.post('/refresh', {
            //     refreshToken: this.refreshToken
            // }).then(res => res.json()).then(res => {
            //     console.log(res);
            //     this.accessToken = res.token;
            //     localStorage.setItem('accessToken', res.token);
            //     return axios(originalReq);
            // }).catch(e => {
            //     console.error(e);
            //     if(window.location.href.indexOf('er-order') == -1 && window.location.href.indexOf('pdf-view') == -1 ) {
            //         reject('token expire.');
            //         localStorage.clear();
            //         window.location.href = '/';
            //     }
            // });
            // resolve(res);
          }
          reject(err.response);
        });
      }
    );
  }

  signOut() {
    this.accessToken = null;
    localStorage.clear();
    window.location.href = "/";
  }

  async getOtp(phoneNumber: string): Promise<any> {
    const url = `auth/otp/${phoneNumber}`;
    let result = null;
    await axiosInstance
      .get(url)
      .then((res) => {
        if (res.data) {
          result = res.data;
        }
      })
      .catch((error) => {
        console.error("getOtp", error);
        result = { success: false, message: "error", data: {} };
      });
    return result;
  }

  async getOtpTest(numberPhone: string): Promise<any> {
    let result = null;
    await axiosInstance
      .get("auth/otp-test/" + numberPhone)
      .then((res) => {
        if (res.data) {
          result = res.data;
        }
      })
      .catch((error) => {
        console.error("getOtp", error);
        result = { success: false, message: "error", data: {} };
      });
    return result;
  }

  async checkOtp(data: { phoneNumber: string; otp: string; ref: string }) {
    const url = `auth/otp/`;
    let result = null;
    await axiosInstance
      .post(url, data)
      .then((res) => {
        if (res.data) {
          result = res.data;
        }
      })
      .catch((error) => {
        console.error("checkOtp", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async checkAccessToken(accessToken: string) {
    const url = `wallet/balance`;
    let result = null;
    await axiosInstance
      .get(url, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getBalance", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getBalance(type: string) {
    const url = `wallet/balance`;
    let result = null;
    await axiosInstance
      .get(url)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getBalance", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getStoreBalance() {
    let result = null;
    await axiosInstance
      .get("store/balance")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getBalance", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getTransaction(address: string, limit: number, skip: number) {
    let result = null;
    await axiosInstance
      .get(
        "wallet/transaction/" + address + "?limit=" + limit + "&skip=" + skip
      )
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getTransaction", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async sendCoin(phoneNumber: string, amount: string) {
    let result = null;
    let url = "/wallet/transfer-phonenumber";
    await axiosInstance
      .post(url, {
        toPhoneNumber: phoneNumber,
        amount: amount,
      })
      .then((res) => {
        console.log("res", res);
        result = res.data;
      })
      .catch((error) => {
        console.error("sendCoin", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async useVoucher(data: object, isPublic: boolean) {
    let url = "/wallet/use-voucher";
    if (isPublic) {
      url = "/wallet/use-public-voucher";
    }
    let result = null;
    await axiosInstance
      .post(url, data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("useVoucher", error);
        result = { success: false, message: "error" };
      });

    return result;
  }

  async uploadQrCode(data: object) {
    let result = null;
    await axiosInstance
      .post("/wallet/upload-qrcode", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("registerStore", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getProfile(phoneNumber: string) {
    let result = null;
    await axiosInstance
      .get("/wallet/check-profile/" + phoneNumber)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getProfile", error.data);
        result = { success: false, message: error.data.message };
      });
    return result;
  }

  async updateProfile(data: any) {
    let result = null;
    await axiosInstance
      .patch("/wallet/profile", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getProfile", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getProvince() {
    let result = null;
    await axiosInstance
      .get("/const-data/provinces")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getProvince", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getAmphur(province: string) {
    let result = null;
    await axiosInstance
      .get("/const-data/province/" + province)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getAmphur", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getTambon(amphur: string) {
    let result = null;
    await axiosInstance
      .get("/const-data/amphur/" + amphur)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getTambon", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getZipcode(tambon: string) {
    let result = null;
    await axiosInstance
      .get("/const-data/tambon/" + tambon)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getTambon", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async applyReward(type: string) {
    let result = null;
    let data = {
      code: type,
    };
    await axiosInstance
      .post("/wallet/apply-reward", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("applyReward", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getBannerRecommend() {
    let result = null;
    await axiosInstance
      .get("/wallet/get-banner-recommend")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getBannerRecommend", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getBizList(search: any, dropDown: string, skip = 0) {
    let result = null;
    search = search ? { "profile.businessName": search } : {};
    search =
      dropDown == "recommend"
        ? Object.assign(search, { recommend: { $gt: 0 } })
        : search;
    const filter = { filter: search, skip: skip, limit: 100 };
    await axiosInstance
      .post("/wallet/business-list", filter)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getBizList", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getBizDetail(bixId: string) {
    let result = null;
    const filter = { filter: { id: bixId }, skip: 0, limit: 1 };
    await axiosInstance
      .post("/wallet/business-list", filter)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getBizList", error);
        result = { success: false, message: "error" };
      });
    return result;
  }

  async getNftItem() {
    let result = null;
    await axiosInstance
      .get("/wallet/check-kgoitem")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("getNftItem", error);
        result = { success: false, message: "error" };
      });
    return result;
  }
  // @ts-ignore
  async haveATree(data) {
    let result = null;

    await axiosInstance
      .post("/wallet/buy-tree", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("buy-tree", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getATree() {
    let result = null;

    await axiosInstance
      .get("/wallet/get-tree")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("get-tree", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }
  // @ts-ignore
  async buyRandomBox(amount) {
    let result = null;
    let data = {
      orderTemplateId: "random_1",
      amount: amount,
    };

    await axiosInstance
      .post("/wallet/place-buy-order", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("place-buy", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getRandomBox() {
    let result = null;

    await axiosInstance
      .get("/wallet/buy-order")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("place-buy", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }
  // @ts-ignore
  async uploadPayment({ id, file }) {
    let result = null;
    const data = new FormData();
    data.append("orderId", id);
    data.append("fileImage", file);

    await axiosInstance
      .post("/wallet/upload-payment", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("upload-payment", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }
  // @ts-ignore
  async openRandomBox(id) {
    let result = null;
    let data = {
      orderId: id,
    };

    await axiosInstance
      .post("/wallet/open-box", data)
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("open-box", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }

  async getRandomSeatLeft() {
    let result = null;

    await axiosInstance
      .get("/wallet/get-random-seat-left?rid=random_1")
      .then((res) => {
        result = res.data;
      })
      .catch((error) => {
        console.error("get-random-seat", error.data);
        result = { success: false, message: error.data.message };
      });

    return result;
  }
}
