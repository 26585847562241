import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonIcon,
  IonLoading,
  useIonAlert,
  IonProgressBar,
  IonCard,
  IonCardContent,
  IonGrid,
  IonText,
  IonModal,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import {
  arrowBackOutline,
  copyOutline,
  person,
  callOutline,
  mailOutline,
  informationCircleOutline,
  link,
} from "ionicons/icons";
import { UserProfile } from "../models/Data";
import kgoLogoMission from "../assets/kgo-mission.svg";
import kgoNft from "../assets/kgo-nft.png";

import { RouteComponentProps } from "react-router";

const Profile: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);
  const [profile, setProfile] = useState<UserProfile>(new UserProfile({}));
  const [profileImage, setProfileImage] = useState(
    process.env.PUBLIC_URL + "/assets/icon/user.png"
  );
  const [present] = useIonAlert();
  useEffect(() => {
    if (appContext.profile.walletAddress) {
      // console.log('profile', appContext.profile);
      setProfile(appContext.profile);
      if (appContext.profile.profile?.profileImage) {
        setProfileImage(appContext.profile.profile.profileImage);
      }
    } else {
      appContext.getProfileBalance().then((res: any) => {
        // console.log('res', res)
      });
    }
  }, [appContext?.profile]);

  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/main"
            />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard className={"kgo-card kgo-card-color"}>
                <IonCardContent>
                  <IonRow style={{ margin: "16px 0" }}>
                    <IonCol className={"ion-text-center"}>
                      {/*<IonIcon icon={personCircle} style={{fontSize:"45px",color:"#FFFFFF"}}></IonIcon>*/}
                      <div
                        style={{
                          display: "inline-block",
                          backgroundImage: `url(${profileImage})`,
                          backgroundSize: "cover",
                          width: 100,
                          height: 100,
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      ></div>
                    </IonCol>
                    <IonCol
                      style={{
                        flexGrow: 3,
                        color: "#FFFFFF",
                        marginTop: "10px",
                      }}
                    >
                      <IonText style={{ marginLeft: 12, fontSize: "1.1em" }}>
                        {profile.profile?.firstName
                          ? profile.profile?.firstName +
                            " " +
                            profile.profile?.lastName
                          : "-"}
                        <div style={{ fontSize: 16, marginLeft: 12 }}>
                          {profile.phoneNumber}
                        </div>
                      </IonText>
                    </IonCol>
                    <IonCol
                      size={"12"}
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#FFFFFF",
                      }}
                    >
                      Wallet Address
                    </IonCol>
                    <IonCol size={"12"}>
                      <IonText
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: "#FFFFFF",
                        }}
                      >
                        {appContext?.profile.walletAddress}
                        {/*{(appContext?.profile.walletAddress) ? appContext?.profile.walletAddress.substr(0, 15) + '...' + appContext?.profile.walletAddress.substr(-15) : ''}*/}
                      </IonText>
                    </IonCol>
                    <IonCol size={"12"} className={"ion-text-end"}>
                      <IonButton
                        color={"medium"}
                        size={"small"}
                        onClick={() =>
                          navigator.clipboard.writeText(profile.walletAddress)
                        }
                        style={{
                          // color: "#FFFFFF",
                          fontSize: 10,
                          // background: "#6D6C6C",
                          // borderRadius: 24,
                          // padding: "6px 10px",
                          // marginLeft: 10,
                          // cursor: "pointer"
                        }}
                      >
                        <IonIcon icon={copyOutline} /> คัดลอก
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard
                className={"kgo-card kgo-light"}
                onClick={(e) => {
                  history.push("/mission");
                }}
              >
                <IonCardContent style={{ paddingBottom: 0 }}>
                  <IonRow>
                    <IonCol size={"8"}>
                      <IonText
                        color={"dark"}
                        style={{ fontSize: 14, fontWeight: "bold" }}
                      >
                        ทำ Mission <div>เพื่อรับ KGO Token เพิ่ม </div>
                        {/*<div>เร็วๆ นี้</div>*/}
                      </IonText>
                    </IonCol>
                    <IonCol size={"4"}>
                      <img src={kgoLogoMission} style={{ width: 154 }} />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard
                className={"kgo-card kgo-light"}
                onClick={(e) => {
                  history.push("/nft");
                }}
              >
                <IonCardContent style={{ paddingBottom: 0 }}>
                  <IonRow>
                    <IonCol size={"8"} style={{ margin: "auto" }}>
                      <IonText
                        color={"dark"}
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          justifyContent: "middle",
                        }}
                      >
                        คลัง NFT
                      </IonText>
                    </IonCol>
                    <IonCol size={"4"} className={"ion-text-center"}>
                      <img src={kgoNft} style={{ width: 120 }} />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className={"kgo-row"}>
            <IonCol>
              <IonCard className={"kgo-card"}>
                <IonCardContent>
                  <IonRow>
                    <IonCol size={"12"} style={{ textAlign: "left" }}>
                      <IonButton
                        routerLink={"/edit-profile"}
                        color={"light"}
                        expand={"block"}
                      >
                        <span className="ion-text-left">
                          <IonIcon icon={person} /> แก้ไขข้อมูลส่วนตัว
                        </span>
                      </IonButton>
                    </IonCol>

                    {/*<IonCol size={"12"} style={{textAlign: "left"}}>*/}
                    {/*    <IonButton disabled={true} color={"light"} expand={"block"}>*/}
                    {/*        <span className="ion-text-left"><IonIcon icon={mailOutline}/> เปลี่ยนอีเมล์</span>*/}
                    {/*    </IonButton>*/}
                    {/*</IonCol>*/}

                    <IonCol size={"12"} style={{ textAlign: "left" }}>
                      <IonButton
                        routerLink={"/referral"}
                        color={"light"}
                        expand={"block"}
                      >
                        <span className="ion-text-left">
                          <IonIcon icon={link} /> แนะนำเพื่อน
                        </span>
                      </IonButton>
                    </IonCol>
                    <IonCol size={"12"} style={{ textAlign: "left" }}>
                      <IonButton
                        routerLink={"/info"}
                        color={"light"}
                        expand={"block"}
                      >
                        <span className="ion-text-left">
                          <IonIcon icon={informationCircleOutline} /> ติดต่อเรา
                        </span>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow className={"kgo-row"} style={{ marginTop: 44 }}>
            <IonCol size={"12"}>
              <IonButton
                expand="block"
                color={"danger"}
                onClick={(e) => {
                  present({
                    cssClass: "",
                    header: "ออกจากระบบ",
                    message: "ยืนยันการออกจากระบบ",
                    buttons: [
                      "ยกเลิก",
                      {
                        text: "ยืนยัน",
                        handler: (d) => appContext.apiService.signOut(),
                      },
                    ],
                    onDidDismiss: (e) => console.log("did dismiss"),
                  });
                }}
              >
                ออกจากระบบ
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default Profile;
