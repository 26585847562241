import {
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonModal, IonNote,
    IonPage,
    IonRow, IonSelect, IonSelectOption,
    IonText,
    IonTitle,
    IonToolbar, isPlatform, useIonAlert, useIonLoading
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {
    arrowBackOutline, informationCircleOutline, addOutline, trashBinOutline
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import '@splidejs/react-splide/css';
import Tree3 from "../../../../assets/tree/tree-03.png";
import styles from "./EvidenceScreen.module.css"
import {AppContext} from "../../../../context/state";
import BoxRandom from "../../../../assets/esan/sunbook.png";
import Leaf01 from "../../../../assets/esan/leaf-01.png";
import Leaf02 from "../../../../assets/esan/leaf-02.png";
import classNames from "classnames";
import ModalSuccess from "../../../Esan/components/ModalSuccess";
import OrderItem from "../../components/OrderItem";
import Resizer from "react-image-file-resizer";
import PreviewImage from "../../../Tree/components/PreviewImage/PreviewImage";
import Preview01 from "../../../../assets/tree/preview-01.jpeg";
import ModalBank from "../../components/ModalBank";
import {ApiService} from "../../../../services/api-service";

const Mission: React.FC<RouteComponentProps> = ({history,location}) => {
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();
    const [show_input, set_show_input] = useState(false)
    const [amount, set_amount] = useState(1)
    const [item, set_item] = useState(location.state?.item)
    const [error_message, set_error_message] = useState("")
    const [modal_success, set_modal_success] = useState(false)
    const [modal_bank, set_modal_bank] = useState(false)
    const [evidence, set_evidence] = useState(null)
    const [evidence_template, set_evidence_template] = useState(null)
    const appContext = useContext(AppContext)

    useEffect(() => {
        console.log(location.state)
        if(!location.state){
            history.push("/buy-random-box")
        }
    }, [])

    const submit = () => {
        if (!evidence_template) {
            presentAlert({
                cssClass: 'ionic-alert',
                header: 'แจ้งเตือน',
                message: 'กรุณาอัปโหลดหลักฐานการโอนเงินของท่าน',
                buttons: ['ตกลง'],
            })
        } else {
            presentAlert({
                header: 'แจ้งเตือน',
                subHeader: 'ยืนยันการส่งหลักฐาน',
                buttons: [
                    {
                        text: 'ยกเลิก',
                        role: 'cancel',
                    },
                    {
                        text: 'ตกลง',
                        role: 'confirm',
                        handler: async () => {

                            present({
                                message: 'กำลังดำเนินรายการ',
                            })

                            try {

                                const tempApiService = new ApiService();
                                let result = await tempApiService.uploadPayment({id:item._id,file:evidence})

                                setTimeout(() => {
                                    dismiss()
                                    set_modal_success(true)
                                },500)

                            } catch (e) {
                                dismiss()
                                setTimeout(() => {
                                    presentAlert({
                                        header: 'แจ้งเตือน',
                                        subHeader: 'เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง',
                                        buttons: [
                                            {
                                                text: 'ตกลง',
                                                role: 'confirm',
                                            }
                                        ]
                                    })
                                },500)
                            }

                        },
                    },
                ],
            })
        }
    }

    const resizeFile = (file: any) => {
        return new Promise((resolve) => {
            return Resizer.imageFileResizer(
                file,
                300,
                300,
                "JPEG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });
    };

    return (
        <IonPage>

            {/*Header*/}
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>
                        อีสานเขียว
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            {/*Content*/}

            <IonContent>

                <div className={styles.container}>


                    <div className="app-container">

                        <div className={styles.contentGallery}>
                            <div className={styles.topicHeader}>กล่องสุ่ม</div>
                            <div className={styles.topicHeader}>น้าซัน <span
                                className={styles.highlight}>อีสานเขียว</span></div>

                            <img className={styles.boxRandom} src={BoxRandom} alt={"tree-01"}/>

                            <div className={styles.amountContainer}>

                                <div className={styles.amountBoxContent}>
                                    <div className={styles.amountBox}>{item.amount}</div>
                                    <div className={styles.amountUnit}>กล่อง</div>
                                </div>

                                <div className={styles.amountBoxContent}>
                                    <div className={styles.amountBox}>{item.totalPrice}</div>
                                    <div className={styles.amountUnit}>บาท</div>
                                </div>

                            </div>

                        </div>

                        <div className={styles.content}>

                            <div className={styles.contentInformation} onClick={() => set_modal_bank(true)}>
                                <div>ช่องทางการชำระเงิน</div>
                                <IonIcon
                                    className={styles.iconInfo}
                                    icon={informationCircleOutline}
                                    onClick={() => set_show_input(false)}
                                />
                            </div>

                            <div className={styles.break}/>

                            <div className={styles.contentOrder}>

                                <div>หลักฐานการโอนเงิน</div>

                                {
                                    !!evidence_template ?
                                        <div className={styles.imagePreview}>

                                            <PreviewImage src={evidence_template}/>

                                            <div className={styles.removeImageContainer}>
                                                <div className={styles.removeImage}>
                                                    <IonIcon
                                                        className={styles.iconRemove}
                                                        icon={trashBinOutline}
                                                        onClick={(e: any) => {
                                                            set_evidence_template(null)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={styles.addImage}>
                                            <IonIcon
                                                className={styles.iconAdd}
                                                icon={addOutline}
                                                onClick={(e: any) => {
                                                    // @ts-ignore
                                                    document.querySelector('#file').click();
                                                }}
                                            />

                                            <input id="file" hidden type="file" multiple={false}
                                                   onChange={async (e) => {
                                                       if (e.target.files) {
                                                           const file = e.target.files[0];
                                                           if (file.type.indexOf("image") < 0) {
                                                               // @ts-ignore
                                                               e.target.value = null;
                                                               presentAlert({
                                                                   cssClass: 'ionic-alert',
                                                                   header: 'แจ้งเตือน',
                                                                   message: 'อ่านได้เฉพาะไฟล์ภาพ เท่านั้น',
                                                                   buttons: ['ตกลง'],
                                                               }).catch()
                                                           } else {
                                                               let fImage = await resizeFile(file);
                                                               console.log('fImage', fImage);
                                                               set_evidence_template(URL.createObjectURL(file))
                                                               set_evidence(file);
                                                           }
                                                       }
                                                   }}/>

                                        </div>

                                }

                                <IonItem className={classNames([`ion-invalid`, styles.inputDetail])}>
                                    <IonLabel position="stacked">รายละเอียด</IonLabel>
                                    <IonInput
                                        className={""}
                                        type={"text"}
                                        value={""}
                                        placeholder="รายละเอียดเพิ่มเติม"
                                        onIonChange={e => {
                                        }}
                                    />
                                    <IonNote slot="error"></IonNote>
                                </IonItem>

                            </div>

                            <button
                                className={styles.buttonSubmit}
                                disabled={false}
                                onClick={submit}
                            >ส่งเรื่อง
                            </button>

                        </div>

                    </div>

                </div>

                <ModalSuccess
                    title={"ส่งหลักฐานการโอนเงินสำเร็จ"}
                    subTitle={"กรุณารอเจ้าหน้าที่ดำเนินการ"}
                    isOpen={modal_success}
                    onConfirm={() => {
                        set_modal_success(false)
                    }}
                    onClose={() => {
                        history.goBack()
                    }}
                />

                <ModalBank
                    isOpen={modal_bank}
                    onClose={() => {
                        console.log("close")
                        set_modal_bank(false)
                    }}
                />

            </IonContent>

        </IonPage>
    );
};

export default Mission;
