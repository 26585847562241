import React from 'react';
import styles from "./PreviewImage.module.css";
import classNames from "classnames";
import ErrorTree from "../../../../assets/tree/error-tree.png";

export default ({src, alt, height, rounder}) => {
    return (
        <div className={styles.container} style={{height: height || 300, borderRadius: rounder && 10}}>

            <img
                src={src}
                className={styles.imagePreview}
                alt={alt}
                onError={({ currentTarget }) => {
                    currentTarget.src=ErrorTree;
                }}
            />

            <img
                src={src}
                className={styles.imagePreviewBackdrop}
                alt={alt}
                onError={({ currentTarget }) => {
                    currentTarget.src=ErrorTree;
                }}
            />

        </div>
    )
}
