import React, {useCallback, useMemo} from 'react';
import styles from "./OrderItem.module.css"
import RandomBox from "../../../../assets/esan/box.png"
import {closeOutline, arrowForwardOutline,giftOutline} from "ionicons/icons";
import {IonIcon, useIonAlert} from "@ionic/react";
import classNames from "classnames";
import {DateTime} from "luxon";

export default ({type,onClick,item,onOpen}) => {
    const [presentAlert] = useIonAlert();
    const get_type = useMemo(() => {
        switch (type) {
            case "wait_for_payment":
                return "รอชำระ"
            case "success":
                return "เปิดกล่องสุ่ม"
            case "already_use":
                return "เปิดกล่องสุ่มแล้ว"
            case "in_process":
                return "รอตรวจสอบ"
            case "reject":
            case "cancel":
                return "ไม่ผ่าน"
        }
    }, [type]);

    const _onClick = () => {
        if(type === "in_process" || type ===  "already_use"){

        }else if(type === "success" ){
            onOpen(item)
        }else{
            onClick()
        }
    }

    return (
        <div className={classNames([styles.container,{[styles.active]:(type === "success" || type === "in_process" || type === "already_use")}])} onClick={_onClick}>

            <div className={styles.orderNumber}>เลขที่คำสสั่งซื้อ: {item._id}</div>

            <div className={styles.content}>

                <div>
                    <div className={styles.amountBoxContent}>
                        <img src={RandomBox} className={styles.boxImage}/>
                        <div className={styles.amountBox}>
                            <div className={styles.amount}>{item.amount}</div>
                            <div className={styles.amountUnit}>กล่อง</div>
                        </div>
                    </div>
                </div>

                <div className={styles.statusContainer}>

                    <div className={classNames([
                        styles.statusContent,
                        {
                            [styles.success]:type === "success",
                            [styles.error]:type === "reject" || type === "cancel",
                        }
                    ])}>
                        {get_type}
                    </div>

                    {
                        (type === "reject" || type === "cancel") &&
                        <div className={styles.detail}>
                            {item.detail}
                        </div>
                    }

                </div>

                <IonIcon
                    className={classNames([styles.nextBtn,{[styles.nextBtnHidden]: (type === "in_process" || type === "already_use") }])}
                    icon={type === "success" ? giftOutline : arrowForwardOutline}
                />

            </div>

        </div>
    )
}
