import {
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonLoading, IonModal,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar, isPlatform, useIonAlert
} from '@ionic/react';
import React, {useContext, useEffect, useState} from "react";
import {
    arrowBackOutline,
    backspace,
    checkmark,
    checkmarkCircle,
    checkmarkSharp,
    ribbonOutline,
    thumbsUpSharp
} from "ionicons/icons";
import mission1 from "../../assets/icon-identity-mission.svg";
import mission2 from "../../assets/cafe.png";
import mission3 from "../../assets/catagoryAll.png";
import mission4 from "../../assets/icon-line-mission.png";
import {RouteComponentProps} from "react-router";
import {AppContext} from "../../context/state";
import dollar from "../../assets/coin_kgo1.svg";
import styles from "./RayongMission.module.css"
import classNames from "classnames";

const Mission: React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)

    const [showLoading, setShowLoading] = useState(false);
    const [rewardFacebook, setRewardFacebook] = useState(false);
    const [rewardLine, setRewardLine] = useState(false);
    const [rewardTwitter, setRewardTwitter] = useState(false);
    const [rewardEmail, setRewardEmail] = useState(false);

    useEffect(() => {
        getProfile();
    }, []);

    function getProfile() {
        // console.log('getBalance');
        if (appContext.apiService) {
            appContext.getProfileBalance();
        } else {
            console.log('false');
        }
    }

    useEffect(() => {
        if (appContext.profile && appContext.profile.rewards) {
            // console.log('reward', appContext.profile.rewards.facebook);
            if (appContext.profile.rewards.email) {
                // console.log('email', appContext.profile.rewards.email.rewardAt);
                setRewardEmail(true);
            }
            if (appContext.profile.rewards.line) {
                // console.log('line', appContext.profile.rewards.line.rewardAt);
                setRewardLine(true);
            }

            if (appContext.profile?.rewards.facebook) {
                // console.log('facebook', appContext.profile.rewards.facebook.rewardAt);
                setRewardFacebook(true);
            }

            if (appContext.profile?.rewards.twitter) {
                // console.log('twitter', appContext.profile.rewards.twitter.rewardAt);
                setRewardTwitter(true);
            }
        }
    }, [appContext?.profile]);

    async function likeFacebook() {
        if (rewardFacebook) {
            // TODO ANIMATION BOOM
            // return false;
        }
        await setApplyReward('facebook').then((response: any) => {
            // if (response.success) {
            if (isPlatform('ios') && isPlatform('mobile')) {
                window.open('fb://profile?id=105196832059252')
            } else if (isPlatform('android') && isPlatform('mobile')) {
                window.open('fb://page/105196832059252')
            } else {
                window.open('https://www.facebook.com/KGOtoken', '_blank');
            }
            // }
        });
    }

    async function AddLine() {
        if (rewardLine) {
            // TODO ANIMATION BOOM
            // return false;
        }
        await setApplyReward('line').then((response: any) => {
            window.open('https://line.me/R/ti/p/@920qmmzo', '_blank');
        });
    }

    async function likeTwitter() {
        if (rewardTwitter) {
            return false;
        }
        await setApplyReward('twitter').then((response: any) => {
            if (response.success) {
                window.open('https://twitter.com/MetaverseSiam', '_blank');
            }
        });
    }

    async function setApplyReward(type: string) {
        setShowLoading(true);
        let res = null;
        await appContext.apiService.applyReward(type).then((response: any) => {
            // console.log('response', response)
            res = response;
            appContext.getProfileBalance().then((res: any) => {
            });
            setShowLoading(false);

        });
        return res;
    }

    const rayong_mission = [
        {
            id: "id1234",
            name: "ภารกิจที่ 1",
            detail: "ตามหาร้านค้า ปลาหมึกยายมีหมึกสด",
            value: 100,
            status: true,
            image: mission1
        },
        {
            id: "id1234",
            name: "ภารกิจที่ 2",
            detail: "ตามหาร้านค้า ไข่เจียวมะนาว",
            value: 200,
            status: false,
            image: mission2
        },
        {
            id: "id1234",
            name: "ภารกิจที่ 3",
            detail: "ตามหาร้านค้า น้ำปั่นสายฝน",
            value: 50,
            status: false,
            image: mission3
        },
        {
            id: "id1234",
            name: "ภารกิจที่ 4",
            detail: "ตามหาร้านค้า ก๋วยเตี๋ยวเฮียโต้",
            value: 75,
            status: false,
            image: mission1
        }
    ]

    return (
        <IonPage>

            {/*Header*/}
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/rayong-festival'/>
                    </IonButtons>
                    <IonTitle>
                        RAYONG MISSION
                        <div style={{fontSize:12,marginTop:-5}}>ภารกิจพิชิดระยอง</div>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            {/*Content*/}
            <IonContent className={'bg-secondary'}>
                <div className="app-container">
                    {/*<h3 style={{marginTop: 42, marginLeft: 32}}>*/}
                    {/*    Mission ง่ายๆ รับ KGO เพิ่มไปเลย*/}
                    {/*</h3>*/}

                    <IonRow>
                        {
                            rayong_mission.map((item, index) => {
                                return (
                                    <IonCol size={"12"}>
                                        <IonCard
                                            className={classNames('kgo-card2 ', styles["rayong-card"], {
                                                'kgo-card-color-disabled': item.status,
                                                'kgo-card2 kgo-card-color2': !item.status,
                                                [styles["rayong-card-first"]]: index === 0
                                            })}
                                            routerLink={!item.status ? "/scan" : undefined}
                                        >
                                            <IonCardContent className={"mission-card-content"} style={{position: 'relative'}}>

                                                {/*Icon check mission */}
                                                {
                                                    item.status && (
                                                        <span
                                                            className={'mission-success'}
                                                            style={item.status ? {opacity: 1} : {}}
                                                        >
                                                            <IonIcon icon={checkmarkSharp}/>
                                                        </span>
                                                    )
                                                }

                                                <IonRow>

                                                    {/*Detail mission*/}
                                                    <IonCol size={"12"}>

                                                        <div
                                                            className={"mission-text"}
                                                            style={{
                                                                marginTop: 9,
                                                                marginBottom: 9,
                                                                position: 'relative',
                                                                zIndex: 10
                                                            }}
                                                        >
                                                            {/*Mission name*/}
                                                            <div className={styles["mission-name"]}>{item.name}</div>

                                                            {/*Mission Detail*/}
                                                            <div
                                                                className={styles["mission-detail"]}>{item.detail}</div>

                                                            {/*Mission value*/}
                                                            <div className={styles["mission-value"]}>

                                                                <img src={dollar}
                                                                     className={styles["mission-value-image"]}/>

                                                                <div>
                                                                    <div>รับไปเลย</div>
                                                                    <IonText
                                                                        style={{color: '#FFA412', fontWeight: 400}}>
                                                                        + {item.value}
                                                                    </IonText> KGO
                                                                </div>

                                                            </div>

                                                            {/*Tag Status*/}
                                                            {/*{*/}
                                                            {/*    item.status ?*/}
                                                            {/*        // Status Done*/}
                                                            {/*        <div style={{marginTop: 8}}>*/}
                                                            {/*            <span*/}
                                                            {/*                className={styles["status-mission-success"]}>*/}
                                                            {/*                 <span>สำเร็จแล้ว</span>*/}
                                                            {/*            </span>*/}
                                                            {/*        </div>*/}
                                                            {/*        :*/}
                                                            {/*        // Status Waiting*/}
                                                            {/*        <div style={{marginTop: 8}}>*/}
                                                            {/*            <span className={styles["status-mission"]}>*/}
                                                            {/*                 <span>ทำภารกิจ</span>*/}
                                                            {/*            </span>*/}
                                                            {/*        </div>*/}
                                                            {/*}*/}

                                                        </div>

                                                    </IonCol>

                                                    {/*Image mission*/}
                                                    <div className={styles["mission-image"]}>
                                                        <img src={item.image}/>
                                                    </div>

                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                )
                            })
                        }
                    </IonRow>

                    <IonLoading
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Loading...'}
                        duration={5000}
                    />
                </div>
            </IonContent>

        </IonPage>
    );
};

export default Mission;
