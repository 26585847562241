import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonProgressBar,
    useIonAlert,
    IonLoading,
    IonCol,
    IonCard,
    IonCardContent,
    IonRow,
    IonText,
    IonIcon, IonBackButton
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, { useContext, useEffect, useState} from "react";
import {arrowBackOutline, arrowForwardOutline, scan} from "ionicons/icons";
import kgoLogoWhite from "../assets/kgo-white.svg";
import {RouteComponentProps, useParams} from "react-router";

interface ParamTypes {
    toPhoneNumber: string
}

const SendToken: React.FC<RouteComponentProps> = ({history}) => {
    const { toPhoneNumber } = useParams<ParamTypes>();
    const appContext = useContext(AppContext)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [amount, setAmount] = useState('')
    const [runningProcess, setRunningProcess] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const [kgoBalance, setKgoBalance] = useState('')
    const [showLoading, setShowLoading] = useState(false);
    const [latestSend, setLatestSend] = useState('');
    const [profileDest, setProfileDest] =  useState<{ name: string, walletAddress: string }>({name: '', walletAddress: ''});

    const [present] = useIonAlert();
    useEffect(() => {
        console.log('toPhoneNumber', toPhoneNumber)
        if(appContext.profile?.phoneNumber){
            if (appContext.profile.balance.length > 0) {
                for (const val of appContext.profile.balance) {
                    if (val.symbol == 'KGO') {
                        setKgoBalance(val.balance);
                    }
                }
            }
        }else{
            appContext.getProfileBalance();
        }

        if(toPhoneNumber){
            setPhoneNumber(toPhoneNumber);
        }
    }, [appContext?.profile]);

    useEffect( () => {
        const latestPhoneNumber = localStorage.getItem('latestSend');
        setLatestSend(latestPhoneNumber ?? '');
    }, [])
    useEffect(() =>{

        if(phoneNumber !== '' && amount !== '' && parseFloat(amount) >= 0.01){
            setDisableBtn(false);
        }else{
            setDisableBtn(true);
        }
    }, [phoneNumber, amount]);

    async function sendCoin() {
        setShowLoading(true);
        setLatestSend(phoneNumber);
        localStorage.setItem('latestSend', phoneNumber);
        await appContext.apiService.sendCoin(phoneNumber,amount).then((response:any) => {
            if(response.success){
                // present({
                //     cssClass: 'ionic-alert',
                //     header: 'แจ้งเตือน',
                //     message: 'ส่งเหรียญสำเร็จ',
                //     buttons: ['Ok',],
                // })
            } else {
                present({
                    cssClass: 'ionic-alert',
                    header: 'Alert',
                    message: response.error,
                    buttons: ['Ok',],
                })
            }

        }).catch((e) => {
            console.log(e);
        }).finally(() =>{
            setShowLoading(false);
            history.push('/main');
        });
    }

    async function checkProfileAddress(){
        console.log(phoneNumber ,'compare', appContext.profile.phoneNumber)
        if(phoneNumber == appContext.profile.phoneNumber){
            present({
                cssClass: 'ionic-alert',
                header: 'แจ้งเตือน',
                message: 'กรุณาระบุปลายทางใหม่',
                buttons: ['Ok',],
            })
            return;
        }
        console.log(kgoBalance)
        console.log(amount)
        if(parseFloat(kgoBalance) < parseFloat(amount) ){
            present({
                cssClass: 'ionic-alert',
                header: 'แจ้งเตือน',
                message: 'ยอด KGO ไม่เพียงพอ',
                buttons: ['Ok',],
            })
            return;
        }
        await appContext.apiService.getProfile(phoneNumber).then((response:any) => {
            console.log('response', response.data)
            if(response.success){
                //init confirm
                setProfileDest(response.data);
            }else{
                //back page
                present({
                    cssClass: 'ionic-alert',
                    header: 'แจ้งเตือน',
                    message: 'ไม่พบข้อมูลปลายทางโปรดตรวจสอบ',
                    buttons: ['Ok',],
                })
            }
        });
        return;
    }
    function numberWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar"}>
                        <IonButtons slot="start">
                            <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                           defaultHref='/main'/>
                        </IonButtons>
                        <IonTitle>{profileDest?.walletAddress ? 'ยืนยันทำรายการ' : 'ส่งเหรียญ'}</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    {(!profileDest?.walletAddress) ?
                        (
                            <div>
                                <IonRow className={"kgo-row"}>
                                    <IonCol>
                                        <IonCard className={"kgo-card kgo-card-img"}>
                                            <IonCardContent>
                                                <IonRow style={{display: 'flex', alignItems:'center'}}>
                                                    <img src={kgoLogoWhite} style={{width: 16.8,marginRight:5}}/>
                                                    <IonText style={{fontSize:14, color:"#FFFFFF"}}> {appContext.profile.profile?.firstName ? appContext.profile.profile?.firstName : appContext.profile.phoneNumber}</IonText>

                                                    <IonText style={{fontSize: 12, marginLeft: 'auto'}}>
                                                        <div style={{background: "rgba(255, 255, 255, 0.42)", borderRadius: 24, paddingLeft:10,paddingRight:10}}>
                                                            <div style={{color: "#414143"}}>{(appContext?.profile.walletAddress) ? appContext?.profile.walletAddress.substr(0, 4)+'...'+appContext?.profile.walletAddress.substr(-4) : ''}</div>
                                                        </div>
                                                    </IonText>
                                                </IonRow>

                                                <IonRow className="ion-text-center" style={{margin:"24px 0 20px"}}>
                                                    <IonCol size='12'>
                                                        <IonText color={"light"} style={{fontSize:36, fontWeight:600}}>{(kgoBalance) ? numberWithCommas(parseFloat(kgoBalance).toFixed(2)) : '0'} <IonText style={{fontSize:16, fontWeight:400, verticalAlign:"middle"}} color={"light"}>KGO</IonText>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                                <IonRow className={"kgo-row"}>
                                    <IonCol>
                                        <IonCard className={"kgo-card kgo-light"}>
                                            <IonCardContent>
                                                <IonRow style={{padding:"27px 0"}}>
                                                    <IonCol size={"12"}>
                                                        <IonItem className={"kgo-input-light"}>
                                                            <IonInput inputmode="text" style={{fontSize:16}}
                                                                      placeholder="เบอร์โทรศัพท์"
                                                                      value={phoneNumber} minlength={10}
                                                                      onIonChange={e => {
                                                                          setPhoneNumber(e.detail.value!);
                                                                      }}/>
                                                            <IonButton slot={"end"} className={"kgo-btn-dark"} onClick={(e) => {
                                                                history.push('/scan');
                                                            }}><IonIcon icon={scan} /></IonButton>
                                                        </IonItem>
                                                    </IonCol>
                                                    <IonCol size={"12"} style={{marginTop:17}}>
                                                        <IonItem className={"kgo-input-light"}>
                                                            <IonInput type="number" placeholder="ขั้นต่ำ 0.01" value={amount} onIonChange={e => {
                                                                setAmount(e.detail.value!);
                                                            }} style={{fontSize:16}} >
                                                            </IonInput>
                                                            <IonButton slot={"end"} className={"kgo-btn-dark"} onClick={(e) => {
                                                                setAmount(kgoBalance);
                                                            }}>MAX</IonButton>
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCardContent>
                                        </IonCard>
                                    </IonCol>
                                </IonRow>
                            </div>
                        ):
                        (<IonRow className={"kgo-row"}>
                            <IonCol>
                                <IonCard className={"kgo-card kgo-light"} >
                                    <IonCardContent style={{color:"#000000"}}>
                                        <IonRow className={"kgo-send-detail"}>
                                            <IonCol style={{textAlign: "center",fontSize:18,fontWeight:600}}> Send Point</IonCol>
                                        </IonRow>
                                        <IonRow className={"kgo-send-detail"}>
                                            <IonCol size={"6"}>
                                                จาก
                                            </IonCol>
                                            <IonCol size={"6"} style={{textAlign: "right", fontSize: 14}}>
                                                ถึง
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className={"kgo-send-detail"}>
                                            <IonCol size={"5"} style={{display:"grid"}}>
                                                <IonText style={{fontWeight:600}}>
                                                    {appContext.profile.profile?.firstName ? appContext.profile.profile?.firstName : appContext.profile.phoneNumber}
                                                </IonText>
                                                <IonText style={{color:"rgb(0,0,0,0.5)",marginLeft:15,fontSize:10,fontWeight:400}}>
                                                    { appContext.profile?.walletAddress.substr(0, 4)+'...'+appContext.profile?.walletAddress.substr(-4) }
                                                </IonText>
                                            </IonCol>
                                            <IonCol size={"2"} style={{textAlign: "center",fontSize:25}}><IonIcon icon={arrowForwardOutline}/></IonCol>
                                            <IonCol size={"5"} style={{display:"grid",textAlign: "right"}} >
                                                <IonText>
                                                    {profileDest?.name}
                                                </IonText>
                                                <IonText style={{color:"rgb(0,0,0,0.5)",marginRight:15,fontSize:10,fontWeight:400}}>
                                                    { profileDest?.walletAddress.substr(0, 4)+'...'+profileDest?.walletAddress.substr(-4) }
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className={"kgo-send-detail"} style={{borderTop: "1pt solid rgba(0, 0, 0, 0.1)"}}>
                                            <IonCol size={"6"}><IonText style={{color:"#00000080"}}>จำนวน</IonText></IonCol>
                                            <IonCol size={"6"} style={{textAlign: "right"}}>
                                                <IonText>{(amount) ? parseFloat(amount).toString() : '0'}</IonText> <IonText style={{color:"#00000080"}}>KGO</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>)
                    }

                    <IonRow className={"kgo-row"}>
                        <IonCol style={{padding:"28px 0"}}>
                            {(!profileDest?.walletAddress) ? (
                                <IonButton style={{fontSize:18,fontWeight:500}}
                                           disabled={disableBtn} className={"kgo-btn"} expand={"block"} strong={true} onClick={(e: any)=>{ checkProfileAddress().catch(console.error); }}>ตรวจสอบข้อมูล
                                </IonButton>
                            ): (
                                <IonButton style={{fontSize:18,fontWeight:500}}
                                           className={"kgo-btn"} expand={"block"} strong={true} onClick={(e: any)=>{ sendCoin().catch(console.error); }}>ยืนยัน
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol style={{color: 'grey', padding:"8px 0"}}>
                            <p className={'ion-text-center'} onClick={ ()=>{
                                setPhoneNumber(latestSend)
                            }}> รายการล่าสุด - {latestSend} </p>
                        </IonCol>
                    </IonRow>
                </div>
                { runningProcess ? <IonProgressBar type="indeterminate"/> : null }
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Loading...'}
                />
            </IonContent>
        </IonPage>
    );
};

export default SendToken;
