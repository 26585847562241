import {
    IonContent,
    IonPage,
    IonRow, IonCol, IonText, IonRouterLink, IonIcon, IonCard, IonCardContent
} from '@ionic/react';
import React, {useEffect, useState} from "react";
import {arrowForwardOutline} from "ionicons/icons";
import ImageGallery from "react-image-gallery";
import TmpImage from "../assets/bi_image-alt.png";

interface Props {
    closeModal: Function;
    isOpen: boolean;
    data: any;
}

export default function BizDetailGallery(props: Props) {

    useEffect(() => {
        console.log('props.isOpen', props.isOpen);
        if (props.isOpen) {
            console.log('props.data', props.data)
        }
    }, [props]);

    function closeModel() {
        props.closeModal(false);
    }

    return (
        <IonPage>
            <IonContent>
                <h4 className={'ion-text-center'} style={{color:'black'}}>รูปภาพเพิ่มเติม</h4>

                <IonCard>
                    <IonRow className="ion-text-center" style={{margin:"24px 0 20px",color: "#FFFFFF"}}>
                        <IonCol size='12'>
                            <div style={{margin: "10px 10px"}}>
                                {props.data.length == 0 && (
                                    <h4>ไม่พบข้อมูล</h4>
                                )}
                                {props.data.length > 0 && (
                                    <ImageGallery
                                        slideDuration={500}
                                        slideInterval={4000}
                                        items={props.data}
                                        showPlayButton={false}
                                        showNav={false}
                                        autoPlay={true}
                                    />
                                )}
                            </div>
                        </IonCol>
                    </IonRow>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};
