import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { fetchBanner } from "../../services/banner/index";
import { useHistory } from "react-router";

function BannerSlide() {
  const [bannerData, setBannerData] = useState();
  const history = useHistory();
  // @ts-ignore
  const openDepa = (name, url) => {
    if (name === "ปรลัย") {
      history.push("/gameredemption");
    } else {
      window.open(url, "_blank");
    }
  };

  const getBanner = async () => {
    const data = await fetchBanner();
    setBannerData(data);
  };

  useEffect(() => {
    getBanner();
  }, []);
  console.log("bannerData", bannerData);
  return (
    <Carousel
      showArrows={false}
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      onClickItem={(item) =>
        // @ts-ignore
        openDepa(bannerData[item].title, bannerData[item].url)
      }
    >
      {/* @ts-ignore */}
      {bannerData?.map((image, index) => (
        <div
          key={index}
          style={{
            borderRadius: 8,
          }}
        >
          <img
            src={image.imageUrl}
            alt={`Banner ${index + 1}`}
            style={{
              borderRadius: 8,
              width: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      ))}
    </Carousel>
  );
}

export default BannerSlide;
