import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
  IonProgressBar,
  useIonAlert,
  IonLoading,
  IonCol,
  IonRow,
  IonText,
  IonIcon,
  IonList,
  IonLabel,
  IonDatetime,
  IonItemDivider,
  IonSelect,
  IonSelectOption,
  IonBackButton,
} from "@ionic/react";
import { AppContext } from "../context/state";
import React, { useContext, useEffect, useState } from "react";
import {
  arrowBackOutline,
  pencilOutline,
  logoFacebook,
  logoTwitter,
  planetOutline,
  mail,
} from "ionicons/icons";
import { UserProfile } from "../models/Data";
import { DateTime } from "luxon";
import { RouteComponentProps } from "react-router";
import Resizer from "react-image-file-resizer";

const EditProfile: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const [runningProcess, setRunningProcess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [facebook, setFacebook] = useState("");
  const [email, setEmail] = useState("");
  const [twitter, setTwitter] = useState("");
  const [homepage, setHomepage] = useState("");
  const [fileUpload, setFileUpload] = useState<File>();
  const [profileImage, setProfileImage] = useState(
    process.env.PUBLIC_URL + "/assets/icon/user.png"
  );
  const [hideDatePicker, setHideDatePicker] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [provinceID, setProvinceID] = useState("");
  const [provinceName, setProvinceName] = useState("");
  const [amphurID, setAmphurID] = useState("");
  const [amphurName, setAmphurName] = useState("");
  const [tambonID, setTambonID] = useState("");
  const [tambonName, setTambonName] = useState("");
  const [dropdownProvince, setDropdownProvince] = useState([]);
  const [dropdownAmphur, setDropdownAmphur] = useState([]);
  const [dropdownTambon, setDropdownTambon] = useState([]);
  const [disAmphur, setDisAmphur] = useState(true);
  const [disTambon, setDisTambon] = useState(true);

  const [present] = useIonAlert();

  useEffect(() => {
    (async function () {
      if (appContext.profile.phoneNumber) {
        console.log("appContext.profile", appContext.profile);
        setPhoneNumber(appContext.profile.phoneNumber);
        const profile = appContext.profile.profile;
        setFirstName(profile?.firstName);
        setLastName(profile?.lastName);
        setBirthdate(profile?.birthdate);
        if (profile?.profileImage) setProfileImage(profile?.profileImage);

        setAddress(profile?.address);
        await loadProvince().then();
        setProvinceID(profile.provinceId);
        setProvinceName(profile.provinceName);

        setAmphurID(profile.amphurId);
        if (profile.amphurId != "") {
          setDisAmphur(false);
          loadAmphur(profile.provinceId).then();
        }
        setAmphurName(profile.amphurName);
        setTambonID(profile.districtId);
        if (profile.districtId != "") {
          setDisTambon(false);
          loadTambon(profile.amphurId).then();
        }
        setTambonName(profile.districtName);

        setZipcode(profile?.zipcode);
        setFacebook(profile?.facebook);
        setEmail(profile?.email);
        setTwitter(profile?.twitter);
        setHomepage(profile?.homepage);
      }
    })();
  }, [appContext.profile]);

  async function updateProfile(data: FormData) {
    setShowLoading(true);
    console.log("data", data);
    await appContext.apiService
      .updateProfile(data)
      .then((response: any) => {
        if (response.success) {
          const user = response?.data;
          const nUser = new UserProfile({
            phoneNumber: user.phoneNumber,
            address: user.address,
            balance: user.balance,
            QrCodeUrl: user.qrcode,
            profile: user.profile,
            statusStore: user.statusStore,
          });
          console.log("nUser", nUser);
          appContext.setProfile(nUser);

          present({
            cssClass: "ionic-alert",
            header: "แจ้งเตือน",
            message: "บันทึกข้อมูลสำเร็จ",
            buttons: ["Ok"],
          });
        } else {
          present({
            cssClass: "ionic-alert",
            header: "Alert",
            message: "Error Someing Wrong",
            buttons: ["Ok"],
          });
        }
      })
      .catch((e) => {
        console.log(e);
        present({
          cssClass: "ionic-alert",
          header: "Alert",
          message: "Error Someing Wrong",
          buttons: ["Ok"],
        });
      })
      .finally(() => {
        setShowLoading(false);
        history.push("/profile");
      });
  }

  async function loadProvince() {
    if (appContext?.apiService) {
      appContext.apiService.getProvince().then((response: any) => {
        // console.log('getProvince', response)
        setDropdownProvince(response);
      });
    }
  }

  async function loadAmphur(province: string) {
    console.log("pro", province);
    if (appContext?.apiService) {
      appContext.apiService.getAmphur(province).then((response: any) => {
        // console.log('getAmphur', response)
        setDropdownAmphur(response);
      });
    }
  }

  async function loadTambon(amphur: string) {
    if (appContext?.apiService) {
      appContext.apiService.getTambon(amphur).then((response: any) => {
        // console.log('getTambon', response)
        setDropdownTambon(response);
      });
    }
  }

  async function loadZipcode(tambon: string) {
    if (appContext?.apiService) {
      appContext.apiService.getZipcode(tambon).then((response: any) => {
        // console.log('getZipcode', response)
        setZipcode(response[0].zipcode);
      });
    }
  }

  const resizeFile = (file: any) => {
    return new Promise((resolve) => {
      return Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  };
  return (
    <IonPage>
      <IonHeader>
        {/*<div className="app-container">*/}
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/profile"
            />
          </IonButtons>
          <IonTitle>แก้ไขข้อมูลส่วนตัว</IonTitle>
        </IonToolbar>
        {/*</div>*/}
      </IonHeader>
      <IonContent>
        <div className="app-container">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              //set formData
              const data = new FormData();
              if (fileUpload) {
                data.append("profileImage", fileUpload);
              }
              data.append("firstName", firstName ?? "");
              data.append("lastName", lastName ?? "");
              data.append("birthdate", birthdate ?? "");
              data.append("facebook", facebook ?? "");
              data.append("email", email ?? "");
              data.append("twitter", twitter ?? "");
              data.append("homepage", homepage ?? "");
              data.append("phoneNumber", phoneNumber ?? "");
              data.append("address", address ?? "");
              data.append("provinceId", provinceID ?? "");
              data.append("provinceName", provinceName ?? "");
              data.append("amphurId", amphurID ?? "");
              data.append("amphurName", amphurName ?? "");
              data.append("districtId", tambonID ?? "");
              data.append("districtName", tambonName ?? "");
              data.append("zipcode", zipcode ?? "");
              updateProfile(data).catch();
            }}
            id={"register-store-form"}
          >
            <IonRow className={"kgo-row ion-text-center kgo-m"}>
              <IonCol className={"ion-text-center"}>
                <div
                  style={{
                    margin: "auto",
                    width: " 7em",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${profileImage})`,
                      backgroundSize: "cover",
                      width: 100,
                      height: 100,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                  <IonText
                    className={"kgo-btn-profile-image"}
                    onClick={(e: any) => {
                      // @ts-ignore
                      document.querySelector("#file").click();
                    }}
                  >
                    <div
                      style={{
                        background: "#FFA412",
                        borderRadius: "50%",
                        paddingTop: 3,
                        paddingLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <input
                        id="file"
                        hidden
                        type="file"
                        multiple={false}
                        onChange={async (e) => {
                          if (e.target.files) {
                            const file = e.target.files[0];
                            console.log("file", file);
                            if (file.type.indexOf("image") < 0) {
                              // @ts-ignore
                              e.target.value = null;
                              present({
                                cssClass: "ionic-alert",
                                header: "แจ้งเตือน",
                                message: "อ่านได้เฉพาะไฟล์ภาพ เท่านั้น",
                                buttons: ["Ok"],
                              }).catch();
                            } else {
                              let fImage = await resizeFile(file);
                              console.log("fImage", fImage);
                              setProfileImage(URL.createObjectURL(file));
                              setFileUpload(file);
                            }
                          }
                        }}
                      />
                      <IonIcon
                        icon={pencilOutline}
                        style={{ color: "#FFFFFF", fontSize: 15 }}
                      />
                    </div>
                  </IonText>
                </div>
              </IonCol>
            </IonRow>
            <IonList style={{ fontSize: 14, fontWeight: 600 }}>
              <IonItem>
                <IonLabel position="stacked">ชื่อ</IonLabel>
                <IonInput
                  className={""}
                  type={"text"}
                  value={firstName}
                  placeholder="ชื่อ"
                  onIonChange={(e) => {
                    setFirstName(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">นามสกุล</IonLabel>
                <IonInput
                  className={""}
                  type={"text"}
                  value={lastName}
                  placeholder="นามสกุล"
                  onIonChange={(e) => {
                    setLastName(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">วันเกิด</IonLabel>
                <IonInput
                  className={""}
                  type={"text"}
                  hidden={!hideDatePicker}
                  value={
                    birthdate
                      ? DateTime.fromISO(birthdate)
                          .setLocale("th")
                          .toFormat("d MMMM yyyy")
                      : ""
                  }
                  placeholder="ระบุวันเกิด"
                  onClick={(e) => {
                    setHideDatePicker(false);
                  }}
                />
                <IonDatetime
                  hidden={hideDatePicker}
                  presentation={"date"}
                  locale={"th-TH"}
                  value={birthdate}
                  // max={"2010-01-01"}
                  onIonChange={(e) => {
                    setBirthdate(e.detail.value!);
                    setHideDatePicker(true);
                  }}
                >
                  <div slot="title">ระบุวันเกิด</div>
                </IonDatetime>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" className={""}>
                  ที่อยู่
                </IonLabel>
                <IonInput
                  type={"text"}
                  value={address}
                  placeholder="ระบุที่อยู่"
                  onIonChange={(e) => {
                    setAddress(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" className={""}>
                  จังหวัด{" "}
                </IonLabel>
                <IonSelect
                  value={provinceID}
                  okText="ตกลง"
                  cancelText="ปิด"
                  placeholder={"กรุณาเลือกจังหวัด"}
                  onIonChange={(e) => {
                    if (e.detail.value != "") {
                      const name = dropdownProvince.find(
                        (r: any) => r.provinceID == e.detail.value
                      );
                      setProvinceID(e.detail.value);
                      // @ts-ignore
                      setProvinceName(name ? name.provinceName : "");
                      loadAmphur(e.detail.value).then();
                      setDisAmphur(false);
                    } else {
                      setProvinceID("");
                      setProvinceName("");
                    }
                    setAmphurID("");
                    setAmphurName("");
                    setTambonID("");
                    setTambonName("");
                    setZipcode("");
                  }}
                  interface="popover"
                >
                  {dropdownProvince.map((data: any, key) => (
                    <IonSelectOption key={key} value={data?.provinceID}>
                      {" "}
                      {data?.provinceName}
                    </IonSelectOption>
                  ))}
                  {provinceID != "" && dropdownProvince.length == 0 ? (
                    <IonSelectOption key={0} value={provinceID}>
                      {" "}
                      {provinceName}
                    </IonSelectOption>
                  ) : null}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" className={""}>
                  อำเภอ{" "}
                </IonLabel>
                <IonSelect
                  value={amphurID}
                  okText="ตกลง"
                  cancelText="ปิด"
                  disabled={disAmphur}
                  placeholder={"กรุณาเลือกอำเภอ"}
                  onIonChange={(e) => {
                    if (e.detail.value != "") {
                      const name = dropdownAmphur.find(
                        (r: any) => r.amphurID == e.detail.value
                      );
                      // @ts-ignore
                      setAmphurName(name ? name.amphurName : "");
                      setAmphurID(e.detail.value);
                      loadTambon(e.detail.value).then();
                      setDisTambon(false);
                    } else {
                      setAmphurID("");
                      setAmphurName("");
                    }
                    setTambonID("");
                    setTambonName("");
                    setZipcode("");
                  }}
                  interface="popover"
                >
                  {amphurID != "" && dropdownAmphur.length == 0 ? (
                    <IonSelectOption key={0} value={amphurID}>
                      {" "}
                      {amphurName}
                    </IonSelectOption>
                  ) : null}
                  {dropdownAmphur.map((data: any, key) => (
                    <IonSelectOption key={key} value={data?.amphurID}>
                      {" "}
                      {data?.amphurName}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" className={""}>
                  ตำบล{" "}
                </IonLabel>
                <IonSelect
                  value={tambonID}
                  okText="ตกลง"
                  cancelText="ปิด"
                  disabled={disTambon}
                  placeholder={"กรุณาเลือกตำบล"}
                  onIonChange={(e) => {
                    const name = dropdownTambon.find(
                      (r: any) => r.districtID == e.detail.value
                    );
                    setTambonID(e.detail.value);
                    // @ts-ignore
                    setTambonName(name ? name.districtName : "");
                    loadZipcode(e.detail.value).then();
                    setZipcode("");
                  }}
                  interface="popover"
                >
                  {tambonID != "" && dropdownTambon.length == 0 ? (
                    <IonSelectOption key={0} value={tambonID}>
                      {" "}
                      {tambonName}
                    </IonSelectOption>
                  ) : null}
                  {dropdownTambon.map((data: any, key) => (
                    <IonSelectOption key={key} value={data?.districtID}>
                      {" "}
                      {data?.districtName}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked" className={""}>
                  รหัสไปรษณีย์{" "}
                </IonLabel>
                <IonInput
                  type={"text"}
                  maxlength={10}
                  readonly
                  value={zipcode}
                  placeholder="กรุณาเลือกจังหวัด,อำเภอ,ตำบล"
                  onIonChange={(e) => {
                    setZipcode(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItemDivider style={{ marginTop: 30 }}>Social</IonItemDivider>
              <IonItem>
                <IonLabel position="fixed" style={{ flex: "0 0 150px" }}>
                  <IonIcon icon={mail} /> Email
                </IonLabel>
                <IonInput
                  type={"text"}
                  value={email}
                  placeholder="ระบุ"
                  onIonChange={(e) => {
                    setEmail(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="fixed" style={{ flex: "0 0 150px" }}>
                  <IonIcon icon={logoFacebook} /> facebook.com/
                </IonLabel>
                <IonInput
                  type={"text"}
                  value={facebook}
                  placeholder="ระบุ"
                  onIonChange={(e) => {
                    setFacebook(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="fixed" style={{ flex: "0 0 150px" }}>
                  <IonIcon icon={logoTwitter} /> twitter.com/
                </IonLabel>
                <IonInput
                  type={"text"}
                  value={twitter}
                  placeholder="ระบุ"
                  onIonChange={(e) => {
                    setTwitter(e.detail.value!);
                  }}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="fixed" style={{ flex: "0 0 150px" }}>
                  <IonIcon icon={planetOutline} /> Homepage
                </IonLabel>
                <IonInput
                  type={"text"}
                  value={homepage}
                  placeholder="ระบุ"
                  onIonChange={(e) => {
                    setHomepage(e.detail.value!);
                  }}
                />
              </IonItem>
            </IonList>

            <IonRow className={"kgo-row ion-text-center kgo-m"}>
              <IonCol>
                <IonButton
                  className={"kgo-btn"}
                  expand={"block"}
                  type={"submit"}
                >
                  บันทึก
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </div>
        {runningProcess ? <IonProgressBar type="indeterminate" /> : null}
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Loading..."}
        />
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
