import React from 'react';
import styles from "./MainPreviewImage.module.css";
import Tree1 from "../../../../assets/tree/tree-01.png";
import Tree2 from "../../../../assets/tree/tree-02.png";

export default ({src,alt}) => {
    return (
        <div className={styles.container}>

            <div className={styles.contentImage}>
                <img className={styles.tree01} src={Tree1} alt={"tree-01"}/>
                <img className={styles.tree02} src={Tree2} alt={"tree-02"}/>

                <div className={styles.circle01}/>
                <div className={styles.circle02}/>
                <div className={styles.circle03}/>
            </div>

            <div className={styles.content}>
                <h3>กิจกรรม</h3>
                <div className={styles.contentDetail}>รวมใจปลูกต้นไม้กับ KGO</div>
                <div className={styles.contentSubDetail}>เพียงนำ KGO มาแลก</div>
            </div>

        </div>
    )
}
