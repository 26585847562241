import React from "react";

const AlertModal = ({
  data,
  isOpen,
  singleBtn,
  setIsOpen,
  onClickHandle,
}: {
  data: any;
  isOpen: boolean;
  singleBtn?: boolean;
  setIsOpen: () => void;
  onClickHandle: () => {};
}) => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        width: "100%",
        bottom: 0,
        height: "100%",
        top: 0,
        left: 0,
        display: isOpen ? "block" : "none",
      }}
    >
      <div
        style={{
          height: "auto",
          width: "80%",
          padding: 32,
          borderRadius: 16,
          backgroundColor: "white",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          top: 320,
          left: 44,
        }}
      >
        <p style={{ alignSelf: "center", color: "black" }}>
          ยินดีด้วย! คุณได้รับ
        </p>
        <p style={{ alignSelf: "center", color: "black" }}>{data?.title}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "center",
          }}
        >
          <p style={{ marginTop: 8, fontSize: 14, color: "gray" }}>
            {data?.description}
          </p>
          {data?.isCheckbox && (
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <input type="checkbox" />
              <p style={{ margin: 0 }}>
                ของรางวัลนี้ต้องมีการจัดส่ง ต้องชำระค่าจัดส่งเอง
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 16,
            gap: 32,
          }}
        >
          {!singleBtn && (
            <button
              style={{
                padding: "16px 8px",
                width: 180,
                borderWidth: 1,
                borderRadius: 8,
                borderColor: "rgba(0, 0, 0, 0.36)",
              }}
              onClick={setIsOpen}
            >
              <p style={{ margin: 0, color: "white" }}>ย้อนกลับ</p>
            </button>
          )}

          <button
            style={{
              padding: "16px 8px",
              width: 180,
              borderRadius: 8,
              backgroundColor: "#FD9E18",
            }}
            onClick={singleBtn ? setIsOpen : onClickHandle}
          >
            <p style={{ margin: 0, fontSize: 14, color: "white" }}>
              {data?.confirmBtn ?? "ยืนยัน"}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
