import React, {useContext, useEffect, useRef, useState} from 'react';
import {IonInput, IonItem, IonLabel, IonModal, IonNote, IonSelect, IonSelectOption} from "@ionic/react";
import styles from './ModalAddress.module.css'
import {useForm, Controller, ValidationValueMessage, FieldValues, UseFormReturn, Control} from "react-hook-form";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {AppContext} from "../../../../context/state";

export default ({isOpen, close, submitData, data}) => {

    const appContext = useContext(AppContext)
    const [dropdown_province, set_dropdown_province] = useState([])
    const [dropdown_amphur, set_dropdown_amphur] = useState([])
    const [dropdown_tambon, set_dropdown_tambon] = useState([])

    const {register, control, watch, handleSubmit, clearErrors, setValue, formState: {errors}}: Control = useForm({
        mode: "onChange",
        defaultValues: {
            full_name: '',
            address_house: '',
            province_id: "",
            province: "",
            amphur_id: "",
            amphur: "",
            tambon_id: "",
            tambon: "",
            zipcode: "",
            phone_number: ""
        }
    });
    const modal = useRef<HTMLIonModalElement>();

    useEffect(() => {
        loadProvince()
    }, [])

    useEffect(() => {

        if (!isOpen) {
            clearErrors()
        }
        setDefaultValue(data)

    }, [isOpen])

    useEffect(() => {
        if (appContext.profile?.profile) {
            setDefaultValue()
        }
    }, [appContext])

    const setDefaultValue = async (data) => {
        let {
            address,
            amphurId,
            amphurName,
            districtId,
            districtName,
            firstName,
            lastName,
            phoneNumber,
            provinceId,
            provinceName,
            zipcode
        } = appContext.profile?.profile

        if (data) {
            address = data.address_house
            amphurId = data.amphur_id
            amphurName = data.amphur
            districtId = data.tambon_id
            districtName = data.tambon
            firstName = data.full_name
            lastName = data.last_name
            phoneNumber = data.phone_number
            provinceId = data.province_id
            provinceName = data.province
            zipcode = data.zipcode
        }

        let full_name = data ? data.full_name : `${firstName || ""} ${lastName || ""}`

        await loadAmphur(provinceId)
        await loadTambon(amphurId)

        setValue("full_name", full_name, {shouldValidate: true})
        setValue("address_house", `${address || ""}`, {shouldValidate: true})
        setValue("province_id", provinceId || "", {shouldValidate: true})
        setValue("province", provinceName || "", {shouldValidate: true})
        setValue("amphur_id", amphurId || "", {shouldValidate: true})
        setValue("amphur", amphurName || "", {shouldValidate: true})
        setValue("tambon_id", districtId || "", {shouldValidate: true})
        setValue("tambon", districtName || "", {shouldValidate: true})
        setValue("zipcode", zipcode || "", {shouldValidate: true})
        setValue("phone_number", phoneNumber || "", {shouldValidate: true})

    }

    async function loadProvince() {
        if (appContext?.apiService) {
            appContext.apiService.getProvince().then((response: any) => {
                set_dropdown_province(response);
            });
        }
    }

    async function loadAmphur(province: string) {
        if (appContext?.apiService) {
            appContext.apiService.getAmphur(province).then((response: any) => {
                // console.log('getAmphur', response)
                set_dropdown_amphur(response);
            });
        }
    }

    async function loadTambon(amphur: string) {
        if (appContext?.apiService) {
            appContext.apiService.getTambon(amphur).then((response: any) => {
                // console.log('getTambon', response)
                set_dropdown_tambon(response);
            });
        }
    }

    async function loadZipcode(tambon: string) {
        if (appContext?.apiService) {
            appContext.apiService.getZipcode(tambon).then((response: any) => {
                // console.log('getZipcode', response)
                setValue('zipcode', response[0].zipcode, {shouldValidate: true})
            });
        }
    }

    const onSubmit = (data) => {
        submitData(data)
    }

    return (
        <div className={styles.container}>
            <IonModal
                id="modal-detail-tree"
                ref={modal}
                trigger="open-modal"
                isOpen={isOpen}
                initialBreakpoint={1}
                onDidDismiss={()=>{
                    close()
                }}
            >
                <div className={styles.modalContent}>

                    <div className={styles.header}>ตั้งค่าที่อยู่จัดส่ง</div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className={styles.formContent}>

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณากรอกเบอร์โทรศัพท์"
                                    }
                                } as RegisterOptions}
                                name="phone_number"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked">เบอร์โทรศัพท์</IonLabel>
                                        <IonInput
                                            className={""}
                                            type={"text"}
                                            value={value || ""}
                                            placeholder="เบอร์โทรศัพท์"
                                            onIonChange={e => {
                                                onChange(e.detail.value!);
                                            }}
                                        />
                                        <IonNote slot="error">{errors.phone_number?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณากรอกชื่อ-นามสกุล"
                                    }
                                } as RegisterOptions}
                                name="full_name"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked">ชื่อ-นามสกุล</IonLabel>
                                        <IonInput
                                            className={""}
                                            type={"text"}
                                            value={value || ""}
                                            placeholder="ชื่อ-นามสกุล"
                                            onIonChange={e => {
                                                onChange(e.detail.value!);
                                            }}
                                        />
                                        <IonNote slot="error">{errors.full_name?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณากรอกข้อมูลที่อยู่"
                                    }
                                } as RegisterOptions}
                                name="address_house"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked">ที่อยู่</IonLabel>
                                        <IonInput
                                            className={""}
                                            type={"text"}
                                            value={value || ""}
                                            placeholder="ระบุที่อยู่"
                                            onIonChange={e => {
                                                onChange(e.detail.value!);
                                            }}
                                        />
                                        <IonNote slot="error">{errors.address_house?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณาเลือกจังหวัด"
                                    }
                                } as RegisterOptions}
                                name="province_id"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked" className={""}>จังหวัด </IonLabel>
                                        <IonSelect
                                            value={value}
                                            id={"prototypetype"}
                                            okText="ตกลง"
                                            cancelText="ปิด"
                                            placeholder={'กรุณาเลือกจังหวัด'}
                                            onIonChange={(e) => {
                                                if (e.detail.value != '') {
                                                    const option = dropdown_province.find((r: any) => r.provinceID == e.detail.value);
                                                    let name = option.provinceName.trim()
                                                    setValue('province', name, {shouldValidate: true})
                                                    onChange(e.detail.value)
                                                    loadAmphur(e.detail.value).then();

                                                    setValue('amphur', "", {shouldValidate: true})
                                                    setValue('amphur_id', "", {shouldValidate: true})

                                                    setValue('tambon', "", {shouldValidate: true})
                                                    setValue('tambon_id', "", {shouldValidate: true})

                                                    setValue('zipcode', "", {shouldValidate: true})

                                                }
                                            }}
                                        >
                                            {
                                                dropdown_province.map((data: any, key) => (
                                                    <IonSelectOption
                                                        key={key}
                                                        value={data?.provinceID}>
                                                        {data?.provinceName}
                                                    </IonSelectOption>
                                                ))
                                            }
                                        </IonSelect>
                                        <IonNote slot="error">{errors.province_id?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณาเลือกอำเภอ"
                                    }
                                } as RegisterOptions}
                                name="amphur_id"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked" className={""}>อำเภอ</IonLabel>
                                        <IonSelect
                                            okText="ตกลง"
                                            value={value}
                                            cancelText="ปิด"
                                            disabled={!watch("province")}
                                            placeholder={'กรุณาเลือกอำเภอ'}
                                            onIonChange={(e) => {
                                                if (e.detail.value != '') {
                                                    onChange(e.detail.value)
                                                    const option = dropdown_amphur.find((r: any) => r.amphurID == e.detail.value);
                                                    let name = option.amphurName.trim()
                                                    setValue('amphur', name, {shouldValidate: true})
                                                    loadTambon(e.detail.value).then();

                                                    setValue('tambon', "", {shouldValidate: true})
                                                    setValue('tambon_id', "", {shouldValidate: true})

                                                    setValue('zipcode', "", {shouldValidate: true})
                                                }
                                            }}
                                        >
                                            {dropdown_amphur.map((data: any, key) => (
                                                <IonSelectOption
                                                    key={key}
                                                    value={data?.amphurID}
                                                > {data?.amphurName}</IonSelectOption>
                                            ))}
                                        </IonSelect>
                                        <IonNote slot="error">{errors.amphur_id?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณาเลือกตำบล"
                                    }
                                } as RegisterOptions}
                                name="tambon_id"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked" className={""}>ตำบล</IonLabel>
                                        <IonSelect
                                            value={value}
                                            okText="ตกลง"
                                            cancelText="ปิด"
                                            disabled={!watch("amphur")}
                                            placeholder={'กรุณาเลือกตำบล'}
                                            onIonChange={(e) => {
                                                if (e.detail.value != '') {
                                                    const option = dropdown_tambon.find((r: any) => r.districtID == e.detail.value);
                                                    let name = option.districtName.trim()
                                                    setValue('tambon', name, {shouldValidate: true})
                                                    onChange(e.detail.value)
                                                    loadZipcode(e.detail.value).then();
                                                }
                                            }}
                                        >
                                            {dropdown_tambon.map((data: any, key) => (
                                                <IonSelectOption
                                                    key={key}
                                                    value={data?.districtID}
                                                >{data?.districtName}</IonSelectOption>
                                            ))}
                                        </IonSelect>
                                        <IonNote slot="error">{errors.tambon_id?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "กรุณาเลือกรหัสไปรษณีย์"
                                    }
                                } as RegisterOptions}
                                name="zipcode"
                                render={({field: {onChange, onBlur, value, ref}, fieldState}) => (
                                    <IonItem className={`ion-invalid`}>
                                        <IonLabel position="stacked">รหัสไปรษณีย์</IonLabel>
                                        <IonInput
                                            disabled={true}
                                            className={""}
                                            type={"text"}
                                            value={value || ""}
                                            placeholder="กรุณาเลือกรหัสไปรษณีย์"
                                        />
                                        <IonNote slot="error">{errors.zipcode?.message}</IonNote>
                                    </IonItem>
                                )}
                            />

                        </div>

                        <div className={styles.buttonContent}>

                            <button type="submit" className={styles.confirmButton}>
                                ยืนยัน
                            </button>

                            <button type="button" onClick={close}>
                                ยกเลิก
                            </button>

                        </div>

                    </form>

                </div>
            </IonModal>
        </div>
    )
}
