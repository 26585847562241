const API = "https://kgoapi.kgotoken.com/";
// http://159.89.211.110

export const fetchBanner = async () => {
  try {
    const response = await fetch(`${API}banner`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
