import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCol,
    IonCard,
    IonCardContent,
    IonRow,
    IonText,
    IonIcon, useIonAlert, IonPopover, IonBackButton
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useRef, useState} from "react";
import {arrowBackOutline, copyOutline, downloadOutline, shareSocialOutline} from "ionicons/icons";
import {
    FacebookIcon,
    FacebookShareButton, LineIcon,
    LineShareButton, TelegramIcon,
    TelegramShareButton, TwitterIcon,
    TwitterShareButton,
} from "react-share";
import kgoLogo from "../assets/kgo-logo.svg";

const Referral: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null)
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();

    const [phoneNumber, setPhoneNumber] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [popShare, setPopShare] = useState(false)

    useEffect(() => {
        if(appContext?.profile.phoneNumber){
            setPhoneNumber(appContext?.profile.phoneNumber)
        }else{
            appContext.getProfileBalance();
        }
    },[appContext?.profile])

    async function share(type : string){
        if (navigator.share) {
            let text = 'เปิดกระเป๋า Kgo Wallet';
            let url = 'https://app.kgotoken.com/?referral='+phoneNumber;
            if(type == 'biz'){
                text = 'เปิดกระเป๋าร้านค้า Kgo Wallet';
                url = 'https://business.kgotoken.com/?referral='+phoneNumber;
            }
            const shareData = {
                title: 'InfinitLand',
                text: text,
                url: url,
            };
            await navigator.share(shareData);
        }else{
            setPopShare(true);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar"}>
                        <IonButtons slot="start">
                            <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                           defaultHref='/main'/>
                        </IonButtons>
                        <IonTitle>แนะนำเพื่อน</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-card-color"} >
                                <IonCardContent style={{color:"#FFFFFF"}}>
                                    <IonRow className="ion-text-center">
                                        <IonCol size={"12"}>
                                            <img src={kgoLogo} style={{width: 70}}/>
                                        </IonCol>
                                        <IonCol size={"12"}>
                                            <IonText style={{fontSize:18, fontWeight:500}}>KGO Wallet</IonText>
                                        </IonCol>

                                        <IonCol style={{marginTop:30}}>
                                            <IonText style={{fontSize:18, fontWeight:500}}>
                                                แนะนำเพื่อนเปิดกระเป๋า KGO
                                                <div>รับ 100 KGO</div>
                                            </IonText>
                                        </IonCol>
                                        <IonCol size={"12"} style={{marginTop:0,marginBottom:15}}>
                                            <IonText color={"dark"} style={{fontWeight:500}}>
                                                {'app.kgotoken.com/?referral='+phoneNumber}
                                            </IonText>
                                        </IonCol>

                                        <IonCol size={"12"}>
                                            <IonButton className={"kgo-btn-light"} style={{marginRight:16}} onClick={(e: any)=>{ navigator.clipboard.writeText('https://app.kgotoken.com/?referral='+phoneNumber) }}>
                                                <IonIcon icon={copyOutline} /> คัดลอก
                                            </IonButton>
                                            <IonButton className={"kgo-btn-light"} disabled={isDisabled} onClick={()=>share('app').catch(console.error)}>
                                                <IonIcon icon={shareSocialOutline} /> แชร์&nbsp;&nbsp;
                                            </IonButton>
                                            <IonPopover isOpen={popShare} alignment={"start"} side={"top"} >
                                                <IonContent className={"ion-text-center"} >
                                                    <FacebookShareButton style={{margin:"10px 5px"}} url={'https://app.kgotoken.com/?referral='+phoneNumber} quote={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'} hashtag={"#KGO"}>
                                                        <FacebookIcon size={32}  borderRadius={8}/>
                                                    </FacebookShareButton>
                                                    <TwitterShareButton style={{margin:"10px 5px"}} url={'https://app.kgotoken.com/?referral='+phoneNumber} title={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'} hashtags={["KGO"]}>
                                                        <TwitterIcon size={32} borderRadius={8}/>
                                                    </TwitterShareButton>
                                                    <TelegramShareButton style={{margin:"10px 5px"}} url={'https://app.kgotoken.com/?referral='+phoneNumber}>
                                                        <TelegramIcon size={32} borderRadius={8}/>
                                                    </TelegramShareButton>
                                                    <LineShareButton style={{margin:"10px 5px"}} url={'https://app.kgotoken.com/?referral='+phoneNumber} title={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'}>
                                                        <LineIcon borderRadius={8} size={32} />
                                                    </LineShareButton>
                                                </IonContent>
                                            </IonPopover>
                                        </IonCol>

                                        <IonCol style={{marginTop:30}}>
                                            <IonText style={{fontSize:18, fontWeight:500}}>แนะนำเพื่อนเปิดกระเป๋าร้านค้า KGO <div>รับ 300 KGO</div></IonText>
                                        </IonCol>
                                        <IonCol size={"12"} style={{marginTop:0,marginBottom:15}}>
                                            <IonText color={"dark"} style={{fontWeight:500,fontSize:14}}>
                                                {'business.kgotoken.com/?referral='+phoneNumber}
                                            </IonText>
                                        </IonCol>

                                        <IonCol size={"12"}>
                                            <IonButton className={"kgo-btn-light"} style={{marginRight:16}} onClick={(e: any)=>{ navigator.clipboard.writeText('https://business.kgotoken.com/?referral='+phoneNumber) }}>
                                                <IonIcon icon={copyOutline} /> คัดลอก
                                            </IonButton>
                                            <IonButton className={"kgo-btn-light"} disabled={isDisabled} onClick={()=>share('biz').catch(console.error)}>
                                                <IonIcon icon={shareSocialOutline} /> แชร์&nbsp;&nbsp;
                                            </IonButton>
                                            <IonPopover isOpen={popShare} alignment={"start"} side={"top"} >
                                                <IonContent className={"ion-text-center"} >
                                                    <FacebookShareButton style={{margin:"10px 5px"}} url={'https://business.kgotoken.com/?referral='+phoneNumber} quote={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'} hashtag={"#KGO"}>
                                                        <FacebookIcon size={32}  borderRadius={8}/>
                                                    </FacebookShareButton>
                                                    <TwitterShareButton style={{margin:"10px 5px"}} url={'https://business.kgotoken.com/?referral='+phoneNumber} title={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'} hashtags={["KGO"]}>
                                                        <TwitterIcon size={32} borderRadius={8}/>
                                                    </TwitterShareButton>
                                                    <TelegramShareButton style={{margin:"10px 5px"}} url={'https://business.kgotoken.com/?referral='+phoneNumber}>
                                                        <TelegramIcon size={32} borderRadius={8}/>
                                                    </TelegramShareButton>
                                                    <LineShareButton style={{margin:"10px 5px"}} url={'https://business.kgotoken.com/?referral='+phoneNumber} title={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'}>
                                                        <LineIcon borderRadius={8} size={32} />
                                                    </LineShareButton>
                                                </IonContent>
                                            </IonPopover>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Referral;
