import {
    IonAccordion,
    IonAccordionGroup,
    IonBackButton,
    IonButton, IonButtons, IonCard, IonCardContent,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonModal,
    IonPage,
    IonRow, IonSelect, IonSelectOption, IonSkeletonText,
    IonText, IonThumbnail,
    IonTitle,
    IonToolbar, isPlatform, useIonAlert, useIonLoading
} from '@ionic/react';
import React, {useContext, useEffect, useRef, useState} from "react";
import {
    arrowBackOutline, arrowUpCircle, addCircleOutline, removeCircleOutline, closeOutline
} from "ionicons/icons";
import {RouteComponentProps} from "react-router";
import '@splidejs/react-splide/css';
import Tree3 from "../../../../assets/tree/tree-03.png";
import styles from "./BuyRandomBoxScreen.module.css"
import {UserProfile} from "../../../../models/Data";
import {ApiService} from "../../../../services/api-service";
import {AppContext} from "../../../../context/state";
import BoxRandom from "../../../../assets/esan/sunbook.png";
import Leaf01 from "../../../../assets/esan/leaf-01.png";
import Leaf02 from "../../../../assets/esan/leaf-02.png";
import classNames from "classnames";
import ModalSuccess from "../../../Esan/components/ModalSuccess";
import OrderItem from "../../components/OrderItem";
import {DateTime} from "luxon";

const Mission: React.FC<RouteComponentProps> = ({history}) => {
    const [presentAlert] = useIonAlert();
    const [present, dismiss] = useIonLoading();
    const [order_list, set_order_list] = useState([])
    const [amount, set_amount] = useState(1)
    const [seat_left, set_seat_left] = useState("")
    const [radombox_seat_left, set_radombox_seat_left] = useState(0)
    const [loaded, set_loaded] = useState(false)
    const [modal_success, set_modal_success] = useState({
        title:"",
        subTitle:""
    })


    const fetchData = async () => {
        set_loaded(false)
        const tempApiService = new ApiService();
        let result = await tempApiService.getRandomBox()
        let result_seat_left = await tempApiService.getRandomSeatLeft()

        let _seat_left = Number((result_seat_left.total - result_seat_left.sold).toFixed(2)).toLocaleString('en')
        set_seat_left(_seat_left)
        set_radombox_seat_left(result_seat_left.total - result_seat_left.sold)

        set_order_list(result)
        set_loaded(true)
    }

    useEffect(()=>{
        fetchData()
    },[])

    const minus = () => {
        let _amount = amount - 1
        if (_amount > 0) {
            set_amount(_amount)
        }
    }

    const plus = () => {
        if (amount < radombox_seat_left){
            set_amount(amount + 1)
        }
    }

    const submit = () => {
        const nativeEl = accordionGroup.current;

        if (nativeEl.value === 'second') {
            presentAlert({
                header: 'แจ้งเตือน',
                subHeader: 'ยืนยันการสั่งซื้อกล่องสุ่มน้าซัน',
                buttons: [
                    {
                        text: 'ยกเลิก',
                        role: 'cancel',
                    },
                    {
                        text: 'ตกลง',
                        role: 'confirm',
                        handler: async () => {

                            const nativeEl = accordionGroup.current;
                            nativeEl.value = undefined;

                            present({
                                message: 'กำลังดำเนินรายการ',
                            })

                            try {
                                const tempApiService = new ApiService();
                                let result = await tempApiService.buyRandomBox(amount)

                                setTimeout(() => {
                                    set_amount(1)
                                    fetchData()
                                    dismiss()
                                    set_modal_success({
                                        title:"ทำรายการสั่งซื้อสำเร็จ",
                                        subTitle:"กรุณาชำระเงิน"
                                    })
                                },500)

                            } catch (e) {
                                dismiss()
                                presentAlert({
                                    header: 'แจ้งเตือน',
                                    subHeader: 'เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง',
                                    buttons: [
                                        {
                                            text: 'ตกลง',
                                            role: 'confirm',
                                        }
                                    ]
                                })
                            }

                        },
                    },
                ],
            })
        } else {
            nativeEl.value = 'second'
        }
    }

    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

    const openRandomBox = async (item) => {

        let date_exp = DateTime.local(2022, 11, 26, 22, 0)
        let date_now = DateTime.now()

        if(date_exp >= date_now){
            presentAlert({
                header: 'แจ้งเตือน',
                subHeader: 'สามารถเปิดกล่องสุ่มได้ หลังวันที่',
                message:"26 พฤศจิกายน 2565 เวลา 22.00 น.",
                buttons: [
                    {
                        text: 'ตกลง',
                        role: 'confirm',
                    }
                ]
            })
        }else{
            presentAlert({
                header: 'แจ้งเตือน',
                subHeader: 'ยืนยันการเปิดกล่องสุ่มน้าซัน',
                buttons: [
                    {
                        text: 'ยกเลิก',
                        role: 'cancel',
                    },
                    {
                        text: 'ตกลง',
                        role: 'confirm',
                        handler: async () => {

                            present({
                                message: 'กำลังดำเนินรายการ',
                            })

                            try{

                                const tempApiService = new ApiService();
                                let result = await tempApiService.openRandomBox(item._id)

                                setTimeout(() => {
                                    fetchData()
                                    dismiss()
                                    set_modal_success({
                                        title:"เปิดกล่องสุ่มสำเร็จ",
                                        subTitle:"สามารถตรวจสอบ NFT ได้ที่\nคลัง NFT ของท่าน"
                                    })
                                },500)
                            }catch (e) {
                                setTimeout(() => {
                                    dismiss()
                                    presentAlert({
                                        header: 'แจ้งเตือน',
                                        subHeader: 'เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง',
                                        buttons: [
                                            {
                                                text: 'ตกลง',
                                                role: 'confirm',
                                            }
                                        ]
                                    })
                                },5000)
                            }
                        },
                    },
                ],
            })
        }
    }

    return (
        <IonPage>

            {/*Header*/}
            <IonHeader>
                <IonToolbar className={"kgo-bar"}>
                    <IonButtons slot="start">
                        <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                       defaultHref='/main'/>
                    </IonButtons>
                    <IonTitle>
                        อีสานเขียว
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            {/*Content*/}

            <IonContent className={'bg-secondary'}>

                <div className={styles.container}>


                    <div className="app-container">

                        <div className={styles.contentGallery}>
                            <div className={styles.topicHeader}>กล่องสุ่ม</div>
                            <div className={styles.topicHeader}>น้าซัน <span
                                className={styles.highlight}>อีสานเขียว</span></div>

                            <img className={styles.boxRandom} src={BoxRandom} alt={"tree-01"}/>

                            <div className={styles.countRandomBoxContent}>

                                <div className={styles.countRandomBox}>
                                    <div className={styles.countRandomBoxTitle}>จำนวนกล่องสุ่ม</div>
                                    <div className={styles.countRandomBoxAmount}>{seat_left}</div>
                                </div>

                            </div>
                        </div>

                        <div className={styles.content}>

                            <img className={styles.tree02} src={Leaf02} alt={"tree-02"}/>
                            <img className={styles.tree01} src={Leaf01} alt={"tree-01"}/>

                            <IonAccordionGroup id={"but-content"} ref={accordionGroup}>
                                <IonAccordion value="second">
                                    <div className={styles.contentInput} slot="content">

                                        <IonIcon
                                            className={styles.closeBtn}
                                            icon={closeOutline}
                                            onClick={() => {
                                                const nativeEl = accordionGroup.current;
                                                nativeEl.value = undefined;
                                            }}
                                        />

                                        <div className={styles.topic}>จำนวนกล่องสุ่ม</div>

                                        <div className={styles.amountContent}>

                                            <IonIcon
                                                className={styles.remove}
                                                icon={removeCircleOutline}
                                                onClick={minus}
                                            />

                                            <div className={styles.amount}>
                                                {amount}
                                            </div>

                                            <IonIcon
                                                className={styles.add}
                                                icon={addCircleOutline}
                                                onClick={plus}
                                            />

                                        </div>

                                        <div className={styles.detail}>ราคาต่อกล่อง 420 บาท</div>

                                        <div className={styles.allPrice}>
                                            {(amount * 420).toFixed(2)}
                                            <div className={styles.unit}>บาท</div>
                                        </div>

                                    </div>
                                </IonAccordion>
                            </IonAccordionGroup>

                            <button
                                className={styles.buttonSubmit}
                                disabled={false}
                                onClick={submit}
                            >สั่งซื้อ
                            </button>

                            <div className={styles.break}/>

                            <div className={styles.contentOrder}>

                                <div className={styles.topicOrder}>รายการสั่งซื้อ</div>

                                {!loaded ?
                                    [1, 1, 1, 1, 1].map((item, index) => {
                                        return (
                                            <IonList key={`${index}-${Math.random()}`}>
                                                <IonListHeader>
                                                    <IonSkeletonText animated={true} style={{'width': '80px'}}></IonSkeletonText>
                                                </IonListHeader>
                                                <IonItem>
                                                    <IonThumbnail slot="start">
                                                        <IonSkeletonText animated={true}></IonSkeletonText>
                                                    </IonThumbnail>
                                                    <IonLabel>
                                                        <h3>
                                                            <IonSkeletonText animated={true}
                                                                             style={{'width': '80%'}}></IonSkeletonText>
                                                        </h3>
                                                        <p>
                                                            <IonSkeletonText animated={true}
                                                                             style={{'width': '60%'}}></IonSkeletonText>
                                                        </p>
                                                        <p>
                                                            <IonSkeletonText animated={true}
                                                                             style={{'width': '30%'}}></IonSkeletonText>
                                                        </p>
                                                    </IonLabel>
                                                </IonItem>
                                            </IonList>
                                        )
                                    })
                                    :
                                    order_list.map((item) => {
                                        return <OrderItem
                                            key={Math.random()}
                                            type={item.status}
                                            item={item}
                                            onClick={() => {
                                                history.push('/evidence-random-box',{item})
                                            }}
                                            onOpen={openRandomBox}
                                        />
                                    })
                                }

                            </div>

                        </div>

                    </div>

                </div>

                <ModalSuccess
                    title={modal_success?.title}
                    subTitle={modal_success?.subTitle}
                    isOpen={!!modal_success?.title}
                    onConfirm={() => {
                        set_modal_success({
                            title:"",
                            subTitle:""
                        })
                    }}
                    onClose={() => {
                        set_modal_success({
                            title:"",
                            subTitle:""
                        })
                    }}
                />

            </IonContent>

        </IonPage>
    );
};

export default Mission;
