import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { arrowBackOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../context/state";
import ModalPreviewNft from "./Esan/components/ModalPreviewNft";

const MyNft: React.FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [modalPreviewItem, setModalPreviewItem] = useState<object>();

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    console.log(appContext?.profile?.kgoItems);
  }, [appContext]);

  function getProfile() {
    if (appContext.apiService) {
      appContext.getProfileBalance();
    } else {
      console.log("false");
    }
  }

  useEffect(() => {
    if (appContext.profile) {
      appContext.apiService.getNftItem().then((response: any) => {
        if (response?.success) {
          console.log("data", response.data);
          let data = response.data ? parseInt(response.data) : 0;
          if (data > 0) {
            setShowImage(true);
          }
        }
      });
    }
  }, [appContext?.profile]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={"kgo-bar"}>
          <IonButtons slot="start">
            <IonBackButton
              color={"light"}
              icon={arrowBackOutline}
              text={""}
              defaultHref="/profile"
            />
          </IonButtons>
          <IonTitle>คลัง NFT</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={"bg-secondary"}>
        <div className="app-container">
          {!showImage ? (
            <IonRow>
              <IonCol size={"12"} className={"ion-text-center"}>
                <h2>ไม่พบข้อมูล</h2>
              </IonCol>
            </IonRow>
          ) : null}
          {/*{*/}
          {/*    appContext.profile.kgoItems.map((item) => {*/}
          {/*        return <IonRow>*/}
          {/*            <IonCol size={'12'}>*/}
          {/*                <IonCard onClick={()=>{*/}
          {/*                    set_modal_preview_nft(item)*/}
          {/*                }}>*/}
          {/*                    <IonCardHeader>NFTNumber: {item}</IonCardHeader>*/}
          {/*                    <IonCardContent className={'ion-no-padding ion-no-margin'}>*/}
          {/*                        <img*/}
          {/*                            src={'https://kgotoken-app-public-storage.s3.ap-southeast-1.amazonaws.com/kgo-items/' + item + '.jpg'}/>*/}
          {/*                    </IonCardContent>*/}
          {/*                </IonCard>*/}
          {/*            </IonCol>*/}
          {/*        </IonRow>*/}
          {/*    })*/}
          {/*}*/}
          {appContext.profile.kgoItems.map((item, ik) => {
            return (
              <IonRow key={ik}>
                <IonCol size={"12"}>
                  <IonCard>
                    <IonCardHeader>NFTNumber: {item.itemType}</IonCardHeader>
                    <IonCardContent className={"ion-no-padding ion-no-margin"}>
                      {item.hash != "" ? (
                        <IonCardSubtitle>
                          <div style={{ display: "flex" }}>
                            <span>
                              {" "}
                              {item.status == "active"
                                ? "กดที่ภาพเพื่อแลกของรางวัล"
                                : "ไม่สามารถใช้ได้"}
                            </span>
                            <a
                              style={{ marginLeft: "auto" }}
                              target={"_blank"}
                              href={
                                "https://jfinscan.com/tx/" +
                                item.hash +
                                "/internal-transactions"
                              }
                            >
                              view transaction
                            </a>
                          </div>
                        </IonCardSubtitle>
                      ) : null}
                      <div></div>
                      <img
                        onClick={() => {
                          if (item.status === "legacy") {
                            setModalPreviewItem(item);
                          }
                        }}
                        alt={"NFT KGO Item"}
                        src={
                          "https://kgotoken-app-public-storage.s3.ap-southeast-1.amazonaws.com/kgo-items/" +
                          item.itemType +
                          ".jpg"
                        }
                      />
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            );
          })}

          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />

          <ModalPreviewNft
            item={modalPreviewItem}
            onClose={() => {
              setModalPreviewItem(undefined);
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MyNft;
