import { Route, Switch } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  setupIonicReact,
  useIonAlert,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ToastContainer } from "react-toastify";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "react-toastify/dist/ReactToastify.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/style.css";
import "./theme/own-carousel.css";
import TermService from "./pages/TermService";
import OtpVerify from "./pages/OtpVerify";
import React, { useEffect, useState } from "react";
import MobileVerify from "./pages/MobileVerify";
import { AppContext } from "./context/state";
import { ApiService } from "./services/api-service";
import Main from "./pages/Main";
import Setting from "./pages/Setting";
import Logo from "./pages/Logo";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import NotFound from "./pages/NotFound";
import { UserProfile } from "./models/Data";
import Transaction from "./pages/Transaction";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import db from "./services/firebase";
import { mailOpenOutline } from "ionicons/icons";
import PinCode from "./pages/PinCode";
import Profile from "./pages/Profile";
import BanPage from "./pages/BanPage";
import Mission from "./pages/Mission";
import { DateTime } from "luxon";
import ReceiveToken from "./pages/ReceiveToken";
import SendToken from "./pages/SendToken";
import CameraScanner from "./pages/CameraScanner";
import EditProfile from "./pages/EditProfile";
import KgoInfo from "./pages/KgoInfo";
import BizList from "./pages/BizList";
import BizDetail from "./pages/BizDetail";
import Referral from "./pages/Referral";
import MyNft from "./pages/MyNft";
import RayongFestival from "./pages/RayongFestival";
import RayongMission from "./pages/RayongMisson/RayongMission";
import BizListRayong from "./pages/BizListRayong";
import LoginByPass from "./pages/LoginByPass";
import HaveATreeScreen from "./pages/Tree/pages/HaveATreeScreen";
import SelectHaveATreeScreen from "./pages/Tree/pages/SelectHaveATreeScreen/SelectHaveATreeScreen";
import HaveATreeHistoryScreen from "./pages/Tree/pages/HaveATreeHistoryScreen";
import BuyRandomBoxScreen from "./pages/Esan/pages/BuyRandomBoxScreen";
import EvidenceScreen from "./pages/Esan/pages/EvidenceScreen";
import LuckyDraw from "./pages/LuckyDraw";
import ExchangeiKGO from "./pages/ExchangeiKGO";
import GameRedeem from "./pages/GameRedeem";
import MyPrize from "./pages/MyPrize";
import PasswordLogin from "./pages/PasswordLogin";
import PasswordCreate from "./pages/PasswordCreate";
import PrizeSingle from "./pages/prize/[id]";
import RedirectPage from "./pages/RedirectUrl";
import MyPrizeHistory from "./pages/prize/history";

setupIonicReact();

const App: React.FC = () => {
  const [apiService, setApiService] = useState<ApiService>();
  const [otpRefData, setOtpRefData] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [profile, setProfile] = useState<UserProfile>(new UserProfile({}));
  const [notify, setNotify] = useState([{}]);
  const [showNotify, setShowNotify] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState("");
  const [registerStore, setRegisterStore] = useState<UserProfile>(
    new UserProfile({})
  );
  const [present] = useIonAlert();

  useEffect(() => {
    const init = async () => {
      if (apiService == null) {
        const tempApiService = new ApiService();
        setApiService(tempApiService);
      }
    };
    init()
      .then((r) => {})
      .catch((e) => {
        console.error(e);
        console.error("init error");
      });
  }, []);

  useEffect(() => {
    let checkNewAddress = "";
    if (walletAddress) {
      console.log("onSnapshot firestore");
      const q = query(
        collection(db, "message"),
        where("address", "==", walletAddress),
        orderBy("timestamp", "desc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const dataNotify: any[] = [];
        querySnapshot.forEach((doc) => {
          dataNotify.push(doc.data());
        });
        if (dataNotify.length > 0 && walletAddress === checkNewAddress) {
          const typeText =
            dataNotify[0].type == "receive" ? "รับเหรียญ" : "ส่งเหรียญ";
          const dateText = DateTime.fromMillis(dataNotify[0].timestamp)
            .setLocale("th")
            .toFormat("d MMM yy T");
          const text = `<div><strong>${typeText}</strong></div> <div>${dateText}</div> <div>${dataNotify[0].text}</div>`;
          setNotifyMessage(dataNotify[0].text);
          setShowNotify(true);
        }
        checkNewAddress = walletAddress;
        setNotify(dataNotify);
      });
    }
  }, [walletAddress]);

  const requireLogin = (to: any, from: any, next: any) => {
    if (to.meta.auth) {
      let accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        next();
      } else {
        next.redirect("/mobile-verify");
      }
    } else {
      next();
    }
  };

  const getProfileBalance = async (type = "user") => {
    if (apiService)
      return apiService
        .getBalance(type)
        .then((response: any) => {
          // console.log('res', response);
          if (response?.success) {
            const user = response?.data.user;
            setWalletAddress(user.walletAddress);
            let nProfile = null;
            if (user.profile) {
              nProfile = Object.assign(user.profile);
            }
            const nUser = new UserProfile({
              phoneNumber: user.phoneNumber,
              balance: user.balance,
              QrCodeUrl: user.qrcode,
              profile: nProfile,
              statusStore: user.statusStore,
              status: user.status,
              type: user.type,
              voucher: user.voucher,
              walletAddress: user.walletAddress,
              rewards: user.rewards,
              kgoItems: user.kgoItems,
            });
            // console.log('App nUser', nUser);
            setProfile(nUser);
            return true;
          } else {
            present({
              cssClass: "ionic-alert",
              header: "Alert",
              message: "ไม่สามารถใช้งานได้ กรุณาติดต่อ Admin",
              buttons: [
                {
                  text: "Ok",
                  handler: (d) => {
                    localStorage.clear();
                    window.location.href = "/";
                  },
                },
              ],
            });
            return false;
          }
        })
        .catch((e) => {
          console.log(e.message);
          present({
            cssClass: "ionic-alert",
            header: "Alert",
            message: "ไม่สามารถใช้งานได้ กรุณาติดต่อ Admin",
            buttons: [
              {
                text: "Ok",
                handler: (d) => {
                  localStorage.clear();
                  window.location.href = "/";
                },
              },
            ],
          });
          return false;
        });
  };

  return (
    <AppContext.Provider
      value={{
        // @ts-ignore
        apiService,
        otpRefData,
        setOtpRefData,
        phoneNumber,
        setPhoneNumber,
        profile,
        setProfile,
        notify,
        setWalletAddress: setWalletAddress,
        walletAddress: walletAddress,
        getProfileBalance,
        registerStore,
        setRegisterStore,
      }}
    >
      <IonApp>
        <IonReactRouter>
          <ToastContainer />
          {/* @ts-ignore */}
          <IonRouterOutlet>
            {/* @ts-ignore */}
            <GuardProvider guards={[requireLogin]}>
              <Switch>
                <GuardedRoute path="/" exact component={Logo} />
                <GuardedRoute
                  path="/term-service"
                  exact
                  component={TermService}
                />
                <GuardedRoute
                  path="/mobile-verify"
                  exact
                  component={MobileVerify}
                />
                <GuardedRoute path="/login" exact component={PasswordLogin} />
                <GuardedRoute
                  path="/setpassword"
                  exact
                  component={PasswordCreate}
                />
                <GuardedRoute path="/otp-verify" exact component={OtpVerify} />
                <GuardedRoute
                  path="/login-bypass"
                  exact
                  component={LoginByPass}
                />
                <GuardedRoute path="/pin" exact component={PinCode} />

                <GuardedRoute
                  path="/main"
                  exact
                  component={Main}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/setting"
                  exact
                  component={Setting}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/transaction"
                  exact
                  component={Transaction}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/luckydraw"
                  exact
                  component={LuckyDraw}
                  meta={{ auth: true }}
                />

                <GuardedRoute
                  path="/profile"
                  exact
                  component={Profile}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/mission"
                  exact
                  component={Mission}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/receive"
                  exact
                  component={ReceiveToken}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/send/:toPhoneNumber?"
                  exact
                  component={SendToken}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/scan"
                  exact
                  component={CameraScanner}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/edit-profile"
                  exact
                  component={EditProfile}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/info"
                  exact
                  component={KgoInfo}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/nft"
                  exact
                  component={MyNft}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/redirect-url"
                  exact
                  component={RedirectPage}
                />
                <GuardedRoute
                  path="/ban"
                  exact
                  component={BanPage}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/biz-list"
                  exact
                  component={BizList}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/biz-detail/:bizId"
                  exact
                  component={BizDetail}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/referral"
                  exact
                  component={Referral}
                  meta={{ auth: true }}
                />

                <GuardedRoute
                  path="/rayong-festival"
                  exact
                  component={RayongFestival}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/rayong-mission"
                  exact
                  component={RayongMission}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/rayong-biz-list"
                  exact
                  component={BizListRayong}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/exchangeikgo"
                  exact
                  component={ExchangeiKGO}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/myprize"
                  exact
                  component={MyPrize}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/prize/history"
                  exact
                  component={MyPrizeHistory}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/prize/:prizeId?"
                  exact
                  component={PrizeSingle}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/gameredemption"
                  exact
                  component={GameRedeem}
                  meta={{ auth: true }}
                />

                <GuardedRoute
                  path="/have-a-tree"
                  exact
                  component={HaveATreeScreen}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/select-have-a-tree"
                  exact
                  component={SelectHaveATreeScreen}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/have-a-tree-history"
                  exact
                  component={HaveATreeHistoryScreen}
                  meta={{ auth: true }}
                />

                <GuardedRoute
                  path="/buy-random-box"
                  exact
                  component={BuyRandomBoxScreen}
                  meta={{ auth: true }}
                />
                <GuardedRoute
                  path="/evidence-random-box"
                  exact
                  component={EvidenceScreen}
                  meta={{ auth: true }}
                />

                <GuardedRoute path="*" exact component={NotFound} />
              </Switch>
            </GuardProvider>
          </IonRouterOutlet>
        </IonReactRouter>
        <IonToast
          isOpen={showNotify}
          onDidDismiss={() => setShowNotify(false)}
          message={notifyMessage}
          icon={mailOpenOutline}
          position="top"
          duration={4000}
          color={"warning"}
        />
      </IonApp>
    </AppContext.Provider>
  );
};

export default App;
