export class UserProfile {
    public balance: Array<token>;
    public phoneNumber: '';
    public QrCodeUrl: '';
    public profile: profile;
    public type: '';
    public statusStore: string;
    public status: string;
    public voucher: Array<VoucherModel>
    public kgoItems: Array<{ itemType: string, status: string, id: string, hash: string }>
    public walletAddress: string;
    public rewards: {  email: { rewardAt: null },facebook: { rewardAt: null }, line: { rewardAt: null }, twitter: { rewardAt: null } };

    constructor(info: any) {
        this.balance = info.balance;
        this.phoneNumber = info.phoneNumber;
        this.QrCodeUrl = info.QrCodeUrl;
        this.profile = info.profile;
        this.type = info.type;
        this.statusStore = info.statusStore;
        this.status = info.status;
        this.voucher = info.voucher;
        this.walletAddress = info.walletAddress;
        this.rewards = info.rewards;
        this.kgoItems = info.kgoItems;
    }
}

export class profile {
    public showName: string
    public profileImage: string;
    public firstName: string;
    public lastName: string;
    public birthdate: string;
    public twitter: string;
    public facebook: string;
    public homepage: string

    //store
    public name: string;
    public type: string;
    public ownerName: string;
    public storePhoneNumber: string;
    public email: string;

    public address: string;
    public zipcode: string;
    public provinceId: string;
    public provinceName: string;
    public amphurId: string;
    public amphurName: string;
    public districtId: string
    public districtName: string;

    public latitude: string;
    public longitude: string;

    public fileUpload: { image: string, document: string };

    constructor(info: any) {
        this.showName = info.showName;
        this.profileImage = info.profileImage;
        this.firstName = info.firstName;
        this.lastName = info.lastName;
        this.birthdate = info.birthdate;
        this.twitter = info.twitter;
        this.facebook = info.facebook;
        this.homepage = info.homepage;

        //store
        this.name = info.name;
        this.type = info.type;
        this.ownerName = info.ownerName;
        this.storePhoneNumber = info.storePhoneNumber;
        this.email = info.email;

        this.address = info.address;
        this.zipcode = info.zipcode;
        this.provinceId = info.provinceId;
        this.provinceName = info.provinceName;
        this.amphurId = info.amphurId;
        this.amphurName = info.amphurName;
        this.districtId = info.districtId;
        this.districtName = info.districtName;
        this.latitude = info.latitude;
        this.longitude = info.longitude;

        this.fileUpload = info.fileUpload;
    }
}

export class token {
    public symbol: string;
    public balance: string;

    constructor(info: any) {
        this.symbol = info.symbol;
        this.balance = info.balance;
    }
}


export class userOtp {
    public expireIn: string;
    public otp: string;
    public phoneNumber: string;
    public ref: string;
    public status: string;

    constructor(info: any) {
        this.expireIn = info.expireIn;
        this.otp = info.otp;
        this.phoneNumber = info.phoneNumber;
        this.ref = info.ref;
        this.status = info.status;
    }
}

export class VoucherModel {
    public id: number;
    public name: string;
    public type: string;
    public amount: string;
    public detail: string;
    public status: string;
    public availableAmount: string;
    public useAmount: string;

    constructor(info: any) {
        this.id = info.id;
        this.name = info.name;
        this.type = info.type;
        this.amount = info.amount;
        this.detail = info.detail
        this.status = info.status;
        this.availableAmount = info.availableAmount;
        this.useAmount = info.useAmount;
    }
}
