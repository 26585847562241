import {
    IonButton,
    IonContent,
    IonPage,
    IonProgressBar,
    useIonAlert,
    IonLoading, IonCol, IonRow, IonToolbar, IonButtons, IonBackButton, IonTitle, IonHeader,
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useState} from "react";
import jsQR from "jsqr";
import kgoIconImage from "../assets/kgo-icon-image.svg";
import QrReader from 'react-qr-reader'
import {RouteComponentProps} from "react-router";
import {arrowBackOutline} from "ionicons/icons";

const CameraScanner :React.FC<RouteComponentProps> = ({history}) => {
    const appContext = useContext(AppContext)
    const [isScanSuccess, setIsScanSuccess] = useState(false);

    const [runningProcess, setRunningProcess] = useState(false)
    const [showLoading, setShowLoading] = useState(false);

    const [present] = useIonAlert();

    async function actionCode(data : any) {
        if(isScanSuccess){
            return;
        }
        try {
            data = JSON.parse(data)
            console.log('data', data)
            switch (data.type) {
                case 'sendToken':
                    history.push('/send/'+data.data.phoneNumber);
                    break;
                case 'voucher':
                    const voucher = data.data;
                    let headerConfirm = `ต้องการใช้ KGO`
                    let messageConfirm = `<div class="ion-text-center"><strong>จำนวน ${voucher.amount} KGO</strong> <div>เพื่อเป็นส่วนลด <strong>${voucher.name}</strong></div> <div>จากร้าน <strong>${voucher.businessName}</strong></div></div>`
                    if(voucher.type == 'send'){
                        headerConfirm = `ยืนยันรับ KGO`
                        messageConfirm = `<div class="ion-text-center"><strong>จำนวน ${voucher.amount} KGO</strong> <div>โปรโมชั่น <strong>${voucher.name}</strong></div> <div>จากร้าน <strong>${voucher.businessName}</strong></div></div>`;
                    }
                    const isPublic = voucher.isPublic ? voucher.isPublic : false;
                    await present({
                        cssClass: 'my-css',
                        header: headerConfirm,
                        message:  messageConfirm,
                        buttons: [
                            {
                                text: 'ยกเลิก', handler: async (d) => {
                                    setIsScanSuccess(false)
                                }
                             },
                            { text: 'Ok', handler: async (d) => {
                                    setShowLoading(true);
                                    await appContext.apiService.useVoucher(data.data, isPublic).then((response: any) => {
                                        if(response.success){

                                        }else{
                                            console.log(response.error)
                                            setTimeout(()=>{
                                                present({
                                                    cssClass: 'ionic-alert',
                                                    header: 'Alert',
                                                    message: response.error,
                                                    buttons: ['Ok',],
                                                })
                                            },500);
                                        }
                                    }).catch((e) => {
                                        console.log(e);
                                    });
                                    history.push('/main');
                                } },
                        ],
                        onDidDismiss: (e) => {
                            console.log('did dismiss');
                        },
                    })
                    break;
                default:
                    await present({
                        cssClass: 'ionic-alert',
                        header: 'เกิดข้อผิดพลาด',
                        message: 'เนื้อหาของ QrCode ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง',
                        buttons: ['Ok',],
                    })
            }
        } catch (e: any) {
            console.log(e.message);
            await present({
                cssClass: 'ionic-alert',
                header: 'เกิดข้อผิดพลาด',
                message: 'เนื้อหาของ QrCode ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง',
                buttons: ['Ok',],
            })
            //close camera
        }
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar"}>
                        <IonButtons slot="start">
                            <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                           defaultHref='/main'/>
                        </IonButtons>
                        <IonTitle>Scan QrCode</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container ion-text-center">
                    <IonRow className={"kgo-row"} style={{marginTop:25}}>
                        <IonCol className={"kgo-camera"}>
                            <QrReader
                                delay={300}
                                onError={(error: any) => {
                                    if (error.name === "NotAllowedError") {
                                        present({
                                            cssClass: 'ionic-alert',
                                            header: 'อนุญาติกล้องถ่ายรูป',
                                            message: 'กรุณากดอนุญาติกล้องถ่ายรูป',
                                            buttons: ['Ok',],
                                        }).catch()
                                        // Handle messaging in our app after the user chooses to not allow the camera permissions
                                    }
                                }}
                                onScan={(result : any) => {
                                    if (result) {
                                        console.log(result);
                                        setIsScanSuccess(true)
                                        actionCode(result).catch();
                                    }
                                    else console.log('found')
                                }}
                                style={{ width: '100%' }}
                                showViewFinder={true}
                            />


                            <IonButton className={"kgo-btn-browse kgo-color"} onClick={(e: any) => {
                                // @ts-ignore
                                document.querySelector('#file').click()
                            }} style={{height:75}}>
                                <input id="file" hidden type="file" multiple={false} onChange={(e) => {
                                    if (e.target.files) {
                                        const file = e.target.files[0];
                                        // console.log('file', file)
                                        if(file.type.indexOf("image") < 0){
                                            // @ts-ignore
                                            e.target.value = null;

                                            present({
                                                cssClass: 'ionic-alert',
                                                header: 'แจ้งเตือน',
                                                message: 'อ่านได้เฉพาะไฟล์ภาพ QrCode เท่านั้น',
                                                buttons: ['Ok',],
                                            }).catch()
                                        }else{
                                            const URL = window.URL;
                                            const url = URL.createObjectURL(e.target.files[0]);
                                            const img = new Image;
                                            img.src = url;
                                            img.onload =  async function() {
                                                const canvas = document.getElementById("qr-code") as HTMLCanvasElement;
                                                const ctx = canvas.getContext("2d");
                                                if(ctx){
                                                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                                                    ctx.drawImage(img, 0, 0, 250, 250);
                                                    const imageData = ctx.getImageData(0, 0, 250, 250);
                                                    const code = await jsQR(imageData.data, imageData.width, imageData.height);
                                                    if (code) {
                                                        // console.log("Found QR code", code);
                                                        actionCode(code.data).catch(console.log);
                                                    }else{
                                                        present({
                                                            cssClass: 'ionic-alert',
                                                            header: 'แจ้งเตือน',
                                                            message: 'อ่านได้เฉพาะไฟล์ภาพ QrCode เท่านั้น',
                                                            buttons: ['Ok',],
                                                        }).catch()
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }}/>
                                {/*<IonIcon icon={imageOutline}/>*/}
                                <img src={kgoIconImage} style={{width: 40}}/>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className={"kgo-row"} style={{marginTop:25}}>
                        <IonCol size={"12"} className={"ion-text-center"}>
                            <canvas width={250} height={250} id={"qr-code"}></canvas>
                        </IonCol>
                    </IonRow>
                </div>
                {runningProcess ? <IonProgressBar type="indeterminate"/> : null}
                <IonLoading
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Loading...'}
                />
            </IonContent>
        </IonPage>
    );
};

export default CameraScanner;
