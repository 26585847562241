import React, { useEffect } from 'react';
import { Redirect, useParams , useLocation} from 'react-router-dom';
import {
    IonCol,
    IonContent,
    IonHeader,
    IonPage,
    IonRow,
} from '@ionic/react';

const RedirectPage = () => {
    const params = useParams();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const path = queryParams.get('path');
    const code = queryParams.get('code');
    useEffect(() => {
        if (path) {
          window.location.href = path + "?code="+code; // Redirect to the specified URL
        }
        console.log(code, "code")
      }, [path]);


  if (!path) {
    return (
        <IonPage>
          <Redirect to="/NotFound" />
        </IonPage>
      );
  }

return (
    <IonPage>
        <IonHeader>
        </IonHeader>
        <IonContent>
            <div className="app-container">
                <IonRow className='ion-no-padding'>
                    <IonCol size="12" className="ion-text-center ion-justify-content-center" style={{marginTop:100}}>
                        <h1>Redirecting...</h1>
                    </IonCol>
                </IonRow>
            </div>
        </IonContent>
    </IonPage>
);
};

export default RedirectPage;
