import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCol,
    IonCard,
    IonCardContent,
    IonRow,
    IonText,
    IonIcon, useIonAlert, IonPopover, IonBackButton
} from '@ionic/react';
import {AppContext} from "../context/state";
import React, {useContext, useEffect, useRef, useState} from "react";
import {arrowBackOutline, copyOutline, downloadOutline, shareSocialOutline} from "ionicons/icons";
import kgoLogo from "../assets/kgo-logo.svg";
import {
    FacebookIcon,
    FacebookShareButton, LineIcon,
    LineShareButton, TelegramIcon,
    TelegramShareButton, TwitterIcon,
    TwitterShareButton,
} from "react-share";
import { toPng } from 'html-to-image';

var QrCode = require('qrcode.react');

const ReceiveToken: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null)
    const appContext = useContext(AppContext)
    const [present] = useIonAlert();

    const [phoneNumber, setPhoneNumber] = useState('')
    const [walletAddress, setWalletAddress] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)
    const [popShare, setPopShare] = useState(false)

    useEffect(() => {
        if(appContext?.profile.phoneNumber){
            setPhoneNumber(appContext?.profile.phoneNumber)
            setWalletAddress(appContext.walletAddress);
            if(!appContext?.profile.QrCodeUrl){
                uploadQrCode().catch();
                setIsDisabled(true);
            }else{
                setIsDisabled(false);
            }
        }else{
            appContext.getProfileBalance();
        }
    },[appContext?.profile])

    async function downloadQR() {
        const dataUrl = await getDataUrl();
        if(dataUrl){
            const link = document.createElement('a')
            link.download = 'my-image-name.png'
            link.href = dataUrl
            link.click()
            document.body.removeChild(link);
        }
    }

    async function getDataUrl(){
        if (ref.current){
            return toPng(ref.current, {cacheBust: true,})
        }
    }

    async function uploadQrCode(){
        const dataUrl = await getDataUrl();
        if(dataUrl){
            const blob = await (await fetch(dataUrl)).blob();
            const data = new FormData();
            data.append('qrcode', blob)
            await appContext.apiService.uploadQrCode(data).then((response:any) => {
                console.log('response', response.data?.qrcode);
                if(response.success){
                    appContext.setProfile((prev: any) => ({
                        ...prev,
                        QrCodeUrl: response.data?.qrcode
                    }))
                    setIsDisabled(true);
                }else{
                    present({
                        cssClass: 'ionic-alert',
                        header: 'แจ้งเตือน',
                        message: response.message,
                        buttons: ['Ok',],
                    }).catch()
                }
            });
        }
    }

    async function share(){
        if (navigator.share) {
            const sourceCanvas = document.getElementById("qr-code-receive") as HTMLCanvasElement;
            const canvas = document.getElementById("qr-code-receive-share") as HTMLCanvasElement;
            const ctx = canvas.getContext("2d");
            if(ctx) {
                await ctx.clearRect(0, 0, canvas.width, canvas.height);
                await ctx.drawImage(sourceCanvas, 0, 0, 276, 276);
            }

            const dataUrl = await getDataUrl();
            if(!dataUrl) return;
            const blob = await (await fetch(dataUrl)).blob();
            const filesArray = [
                new File(
                    [blob],
                    'my-receive-token.png',
                    {
                        type: blob.type,
                        lastModified: new Date().getTime()
                    }
                )
            ];
            const shareData = {
                files: filesArray,
                // title: 'Siam Metaverse',
                text: 'QrCode รับเหรียญ',
                // url: 'https://app.kgotoken.com/',
            };
            await navigator.share(shareData);
        }else{
            setPopShare(true);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                {/*<div className="app-container">*/}
                    <IonToolbar className={"kgo-bar"}>
                        <IonButtons slot="start">
                            <IonBackButton color={"light"} icon={arrowBackOutline} text={""}
                                           defaultHref='/main'/>
                        </IonButtons>
                        <IonTitle>รับเหรียญ</IonTitle>
                    </IonToolbar>
                {/*</div>*/}
            </IonHeader>
            <IonContent>
                <div className="app-container">
                    <IonRow className={"kgo-row"}>
                        <IonCol>
                            <IonCard className={"kgo-card kgo-card-color"} >
                                <IonCardContent style={{color:"#FFFFFF"}}>
                                    <IonRow className="ion-text-center">
                                        <IonCol size={"12"}>
                                            <img src={kgoLogo} style={{width: 70}}/>
                                        </IonCol>
                                        <IonCol size={"12"} className={"kgo-m"}>
                                            <div style={{fontSize:18, fontWeight:500}}>KGO Wallet</div>
                                        </IonCol>
                                        <IonCol size={"12"} className={"kgo-m"}>
                                            <IonText style={{fontSize:24, fontWeight:500}}>QrCode รับเหรียญ</IonText>
                                        </IonCol>
                                        <IonCol size={"12"} className={"kgo-m"}>
                                            <QrCode
                                                id={"qr-code-receive"}
                                                size={276}
                                                value={JSON.stringify({type:'sendToken', walletAddress:{
                                                        chain: 'xchain',
                                                        rpc: 'https://rpc.xchain.asia/',
                                                        chainId:'35',
                                                        address: walletAddress,
                                                    }, data:{phoneNumber}})}
                                                renderAs={"canvas"}
                                                bgColor={"#ffffff"}
                                                includeMargin={true}
                                                imageSettings={{
                                                    src: process.env.PUBLIC_URL + '/assets/icon/KGO-Logo.png',
                                                    x: null,
                                                    y: null,
                                                    height: 70,
                                                    // width: 56,
                                                    excavate: true,
                                                }}
                                            />
                                        </IonCol>
                                        <IonCol offset={"2"} size={"8"} style={{marginTop:0,marginBottom:15}}>
                                            <IonButton color={"light"} style={{fontSize:16,fontWeight:500,height:50,textTransform: "capitalize"}} className={"kgo-btn-light"} expand={"block"} onClick={(e: any)=>{ navigator.clipboard.writeText(walletAddress) }}>
                                                {walletAddress.substr(0, 4)+'...'+walletAddress.substr(-4) }
                                                <IonText style={{color:"#FFFFFF",fontSize:9,background: "#6D6C6C", borderRadius: 24, padding:"6px 10px",margin:10}}>
                                                    <IonIcon icon={copyOutline} /> คัดลอก
                                                </IonText>
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow className={"kgo-row"} style={{position:"absolute", top: 0,width: 512,zIndex:-1}}>
                        <IonCol size={"8"}>
                            <div ref={ref}>
                                <IonCard className={"kgo-card kgo-card-color"} >
                                    <IonCardContent style={{color:"#FFFFFF"}}>
                                        <IonRow className="ion-text-center">
                                            <IonCol size={"12"}>
                                                <img src={kgoLogo} style={{width: 70}}/>
                                            </IonCol>
                                            <IonCol size={"12"}>
                                                <div style={{fontSize:18, fontWeight:500}}>KGO Wallet</div>
                                            </IonCol>
                                            <IonCol size={"12"}>
                                                <IonText style={{fontSize:18, fontWeight:500}}>QrCode รับเหรียญ</IonText>
                                            </IonCol>
                                            <IonCol size={"12"}>
                                                <canvas height={276} width={276} id={"qr-code-receive-share"} />
                                            </IonCol>
                                            <IonCol offset={"2"} size={"8"} style={{marginTop:0,marginBottom:15}}>
                                                <IonText style={{fontSize:15,fontWeight:600,height:50,textTransform: "capitalize"}}>
                                                    {phoneNumber}
                                                    <div>{walletAddress.substr(0, 8)+'...'+walletAddress.substr(-8)}</div>
                                                </IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonRow className={"kgo-row ion-text-center kgo-m"}>
                        <IonCol size={"12"}>
                            <IonButton className={"kgo-btn"} style={{marginRight:16}} onClick={(e: any)=>{ downloadQR() }}>
                                <IonIcon icon={downloadOutline} /> บันทึก
                            </IonButton>
                            <IonButton className={"kgo-btn"} disabled={isDisabled} onClick={()=>share().catch(console.error)}>
                                <IonIcon icon={shareSocialOutline} /> แชร์&nbsp;&nbsp;
                            </IonButton>
                            <IonPopover isOpen={popShare} alignment={"start"} side={"top"} >
                                <IonContent className={"ion-text-center"} >
                                    <FacebookShareButton style={{margin:"10px 5px"}} url={appContext?.profile.QrCodeUrl} quote={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'} hashtag={"#KGO"}>
                                        <FacebookIcon size={32}  borderRadius={8}/>
                                    </FacebookShareButton>
                                    <TwitterShareButton style={{margin:"10px 5px"}} url={appContext?.profile.QrCodeUrl} title={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'} hashtags={["KGO"]}>
                                        <TwitterIcon size={32} borderRadius={8}/>
                                    </TwitterShareButton>
                                    <TelegramShareButton style={{margin:"10px 5px"}} url={appContext?.profile.QrCodeUrl}>
                                        <TelegramIcon size={32} borderRadius={8}/>
                                    </TelegramShareButton>
                                    <LineShareButton style={{margin:"10px 5px"}} url={appContext?.profile.QrCodeUrl} title={'เชื่อม "โลกเสมือน" กับ "โลกความจริง" เข้าด้วยกันด้วยเหรียญ KGO'}>
                                        <LineIcon borderRadius={8} size={32} />
                                    </LineShareButton>
                                </IonContent>
                            </IonPopover>
                        </IonCol>
                    </IonRow>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ReceiveToken;
