import React, {useRef} from 'react';
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader, IonIcon,
    IonModal,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import styles from './ModalPreviewNft.module.css'
import Qrcode from "../../../../assets/esan/qrcode.png";
import PreviewImage from "../../../Tree/components/PreviewImage";
import {informationCircleOutline} from "ionicons/icons";
var QrCode = require('qrcode.react');

export default (props: any) => {

    const modal = useRef<HTMLIonModalElement>();

    return (
        <div className={styles.container}>
            <IonModal
                id="modal-detail-tree"
                // ref={modal}
                trigger="open-modal"
                isOpen={props.item != null}
                initialBreakpoint={1}
                onDidDismiss={props.onClose}
            >
                <div style={{flex: 1}} onClick={props.onClose}/>

                {
                    props.item != null ?
                        <div className={styles.modalContent}>

                            <div className={styles.headerContent}>
                                <div className={styles.headerTitle}>Na SUN</div>
                                <div className={styles.nftNumberId}>NFTNumber: {props.item.itemType}</div>
                            </div>

                            <div className={styles.content}>

                                <div className={styles.nftImage}>
                                    <PreviewImage
                                        src={'https://kgotoken-app-public-storage.s3.ap-southeast-1.amazonaws.com/kgo-items/' + props.item.itemType + '.jpg'}/>
                                </div>

                                {
                                    true ?  <div className={  styles.detailNft}>
                                        {props.item._id}
                                    </div> : null
                                }


                                <div className={styles.qrcodeContent}>

                                    <div>
                                        <div className={styles.qrcodeTopicContent}>

                                            <IonIcon
                                                style={{fontSize: '40px', color: '#F17B34'}}
                                                icon={informationCircleOutline}
                                            />

                                            <div className={styles.qrcodeTextContent}>
                                                <div className={styles.qrcodeTopic}>QR Code</div>
                                                <div>สำหรับแลกของรางวัล</div>
                                            </div>
                                        </div>

                                        <div className={styles.qrcodeEventText}>
                                            <div>สามารถแลกของรางวัลได้ที่งาน</div>
                                            <div><b>E San Music Festival Thailand 10</b></div>
                                            <div>ระหว่างวันที่ 26-28 พ.ย. 2565</div>
                                        </div>
                                    </div>

                                    <div className={styles.qrcodeImageContent}>
                                        <QrCode
                                            id={"qr-code-receive"}
                                            size={150}
                                            value={props.item._id || "ca"}
                                            renderAs={"canvas"}
                                            bgColor={"#ffffff"}
                                            includeMargin={true}
                                        />

                                        {
                                            props.item?.useAt != null &&
                                            <div className={styles.receivedGift}>รับของรางวัลแล้ว</div>
                                        }

                                        <div className={styles.qrcodePlantext}>{props.item._id}</div>
                                    </div>

                                </div>

                            </div>

                        </div> : null
                }


                <div style={{flex: 1}} onClick={props.onClose}/>
            </IonModal>
        </div>
    )
}
