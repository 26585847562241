import React, {useRef} from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import styles from './ModalSuccess.module.css'
import Sun from "../../../../assets/esan/sun.png";

export default ({isOpen,title,subTitle, close,onConfirm,onClose,...props}) => {

    const modal = useRef<HTMLIonModalElement>();

    return (
        <div className={styles.container}>
            <IonModal
                id="modal-detail-tree"
                ref={modal}
                trigger="open-modal"
                isOpen={isOpen}
                initialBreakpoint={1}
                onDidDismiss={onClose}
            >
                <div style={{flex: 1}}/>

                <div className={styles.modalContent}>

                    <div className={styles.imageContent}>
                        <img className={styles.tree01} src={Sun} alt={"tree-01"}/>
                    </div>

                    <div className={styles.content}>

                        <div className={styles.topic}>ยินดีด้วย</div>
                        <div className={styles.detail}>{title}</div>

                        <div className={styles.subDetail}>
                            {subTitle}
                        </div>

                        <button
                            className={styles.buttonSubmit}
                            onClick={onConfirm}
                        >ยืนยัน</button>

                    </div>

                </div>

                <div style={{flex: 1}}/>
            </IonModal>
        </div>
    )
}
